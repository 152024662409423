import { Button, Paper, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import WorkstationEditor from "components/Workstations/WorkstationEditor";
import UserEditor from "components/Admin/Users/UserEditor";


export default function AccountEditor (props: any) {
  const user_id = localStorage.getItem('user_id');
  return (
    <div id="Account">
      <UserEditor account_user_id={user_id} setLoading={props.setLoading} is_my_account={true}/>
    </div>

    
  );
};



