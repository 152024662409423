import {BrowserRouter as Router , Route, Routes} from 'react-router-dom'
import ProductList from 'components/Admin/Products/ProductList';
import ProductEditor from 'components/Admin/Products/ProductEditor';
import UserList from 'components/Admin/Users/UserList';
import UserEditor from 'components/Admin/Users/UserEditor';
import Login from 'components/Admin/Login/Login';
import Dashboard from 'components/Admin/Dashboard/Dashboard';
import Cookies from 'universal-cookie';
import AccountEditor from 'components/Admin/Account/AccountEditor';

import CategorieslList from 'components/Admin/Categories/CategoryList';
import CategorieslEditor from 'components/Admin/Categories/CategoryEditor';
import Home from 'components/Admin/Home/Home';
import AdminList from 'components/Admin/Administrators/AdminList';
import AdminEditor from 'components/Admin/Administrators/AdminEditor';
import CompaniesList from 'components/Admin/Companies/CompaniesList';
import CompaniesEditor from 'components/Admin/Companies/CompaniesEditor';
import WorkstationList from 'components/Workstations/WorkstationList';
import WorkstationEditor from 'components/Workstations/WorkstationEditor';
import AdminWorkstationList from 'components/Workstations/Admin/AdminWorkstationList';
import WorkstationsOrders from 'components/Orders/WorkstationsOrders';
import FormsList from 'components/Forms/FormsList';
import FormEditor from 'components/Forms/FormEditor';
import TicketsList from 'components/Tickets/TicketsList';
import TicketsEditor from 'components/Tickets/TicketsEditor';
const cookies = new Cookies();

export default function AppRoutesAdmin(props: any) {
  
  const {setLoading} = props;

  return (
    <Routes>
      {/* HOME */}
      {/* <Route path='/' element={<Home setLoading={setLoading}/>} /> */}
      <Route path='/' element={<AdminWorkstationList setLoading={setLoading} isAdmin={true} />} />

      {/* ADMINS */}
      <Route path="/administrators" element={<AdminList setLoading={setLoading}/>} />
      <Route path="/administrators/add" element={<AdminEditor setLoading={setLoading}/>} />
      <Route path="/administrators/edit/:user_id" element={<AdminEditor setLoading={setLoading}/>} />

      {/* COMPANIES */}
      <Route path="/companies" element={<CompaniesList setLoading={setLoading}/>} />
      <Route path="/companies/add" element={<CompaniesEditor setLoading={setLoading}/>} />
      <Route path="/companies/edit/:company_id" element={<CompaniesEditor setLoading={setLoading}/>} />

      {/* PRODUCTS */}
      <Route path="/inventory" element={<ProductList setLoading={setLoading}/>} />
      <Route path="/inventory/add" element={<ProductEditor setLoading={setLoading}/>} />
      <Route path="/inventory/edit/:product_id" element={<ProductEditor setLoading={setLoading}/>} />
      <Route path="/inventory/group/:products_group_id" element={<ProductEditor setLoading={setLoading}/>} />

      <Route path="/categories" element={<CategorieslList setLoading={setLoading}/>} />
      <Route path="/categories/add" element={<CategorieslEditor setLoading={setLoading}/>} />
      <Route path="/categories/edit/:category_id" element={<CategorieslEditor setLoading={setLoading}/>} />

      {/* FORMS */}
      <Route path="/forms" element={<FormsList setLoading={setLoading} isAdmin={true}/>} />
      <Route path="/forms/:form_id/questions" element={<FormEditor setLoading={setLoading} isAdmin={true}/>} />


      {/* USERS */}
      <Route path="/users" element={<UserList setLoading={setLoading}/>} />
      <Route path="/users/add" element={<UserEditor setLoading={setLoading}/>} />
      <Route path="/users/edit/:user_id" element={<UserEditor setLoading={setLoading}/>} />

      {/* WORKSTATIONS */}
      <Route path="/workstations" element={<AdminWorkstationList setLoading={setLoading} isAdmin={true} />} />
      <Route path="/workstations/add" element={<WorkstationEditor setLoading={setLoading} isAdmin={true} />} />
      <Route path="/workstations/edit/:workstation_id" element={<WorkstationEditor setLoading={setLoading} isAdmin={true}  />} />

      
      {/* TICKETS */}
      <Route path="/tickets" element={<TicketsList setLoading={setLoading} isAdmin={true}/> } />
      <Route path="/tickets/add" element={<TicketsEditor setLoading={setLoading} isAdmin={true}/> } />
      <Route path="/tickets/edit/:ticket_id" element={<TicketsEditor setLoading={setLoading} isAdmin={true}/> } />
      

      {/* ORDERS */}
      <Route path="/orders" element={<WorkstationsOrders setLoading={setLoading}/>} />
      

      {/* MY ACCOUNT */}
      <Route path="/account" element={<AccountEditor setLoading={setLoading}/>} />
      <Route path="/admin" element={<Dashboard setLoading={setLoading}/>} />
    </Routes>
  );
}
