import { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogContentText, IconButton, TextField } from '@mui/material';




import SendIcon from '@mui/icons-material/Send';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar, withSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';

const defaultWorkstation = [{
    name: {
        error: false,
        value: '',
    },
    lastname: {
        error: false,
        value: '',
    },
    email: {
        error: false,
        value: '',
    },
}]



export default withSnackbar(
    function AddWorkstation(props: any) {

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    useEffect(() => {
      props.setLoading(false);
    }, []);


    const [workstationsList, setWorkstationsList] = useState<any []>(defaultWorkstation);

    const checkForm = () => {
        //CHECK IF ALL FIELDS ARE FILLED AND ADD ERROR MESSAGE IF NOT TO EACH TEXTFIELD
        
        let errors = false;
        workstationsList.forEach((workstation, index) => {
            if(workstation.name.value === ''){
                const values = [...workstationsList];
                values[index].name.error = true;
                setWorkstationsList(values);
                errors = true;
            }
            if(workstation.lastname.value === ''){
                const values = [...workstationsList];
                values[index].lastname.error = true;
                setWorkstationsList(values);
                errors = true;
            }
            if(workstation.email.value === ''){
                const values = [...workstationsList];
                values[index].email.error = true;
                setWorkstationsList(values);
                errors = true;
            }

            if(workstation.email.value !== '' && !workstation.email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                const values = [...workstationsList];
                values[index].email.error = true;
                setWorkstationsList(values);
                errors = true;
            }


        }
        )
        return errors;
    }
  
    const [alertOpen, setAlertOpen] = useState(false);

    // SEND INVITATIONS TO WORKSTATIONS LIST
    const sendInvitations = () => {
        props.setOpen(false);
        props.send(workstationsList)
    }


  return (
    <>
        <Dialog
            id={props.id}
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            
            <DialogContent  className="forced_box_editor">
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Nuevos puestos</h2>
                    <DialogContentText>
                        Por favor, ingresa los datos de los nuevos puestos.
                    </DialogContentText>
                </div>



                <form className="form">
                    {workstationsList.map((workstation, index) => {
                    return(
                        <div className="input_row" key={index}>
                            <TextField
                                label="Nombre"
                                variant="outlined"
                                value={workstation.name.value}
                                
                                onChange={(e) => {
                                    // REMOVE NUMBERS OR SPECIAL CHARACTERS
                                    const values = [...workstationsList];
                                    values[index].name.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                                    setWorkstationsList(values);
                                    workstation.name.error = false;
                                }}
                                error = {workstation.name.error}
                            />
                            <TextField
                                label="Apellido"
                                variant="outlined"
                                value={workstation.lastname.value}
                                onChange={(e) => {
                                    const values = [...workstationsList];
                                    values[index].lastname.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                                    setWorkstationsList(values);
                                    workstation.lastname.error = false;
                                }}

                                error = {workstation.lastname.error}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                type="email"
                                value={workstation.email.value}
                                onChange={(e) => {
                                    const values = [...workstationsList];
                                    values[index].email.value = e.target.value;
                                    setWorkstationsList(values);
                                    workstation.email.error = false;
                                }}
                                // validate email format    
                                error = {workstation.email.error || (workstation.email.value !== '' && !workstation.email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))}
                                helperText = {workstation.email.value !== '' && !workstation.email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 'Email inválido' : ''}
                            />
                            {/* DELETE ROW BUTTON WITH ICON */}
                            <div className='icon_button_container'>
                                <IconButton
                                    aria-label="delete"
                                    disabled={workstationsList.length === 1}
                                    onClick={() => {
                                        const values = [...workstationsList];
                                        values.splice(index, 1);
                                        setWorkstationsList(values);
                                    }}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </div>
                            

                            
                        </div>
                    )})}

                    <Button
                        sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
                        onClick={() => {
                            setWorkstationsList([...workstationsList, {
                                name: {
                                    error: false,
                                    value: '',
                                },
                                lastname: {
                                    error: false,
                                    value: '',
                                },
                                email: {
                                    error: false,
                                    value: '',
                                },
                            }])
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                    >
                        Agregar otro puesto
                    </Button>
                </form>



                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => props.setOpen(false)}
                    >
                        Cancelar
                    </Button>

                    <Button 
                        variant="contained" 
                        endIcon={<SendIcon />}
                        onClick={
                            () => {
                                if(!checkForm())
                                {
                                    setAlertOpen(true);
                                }
                                else{
                                    enqueueSnackbar('Errores en el formulario', {variant: 'error'});
                                }
                            }
                        }
                        >
                        Enviar invitación
                    </Button>
                </div>
            </DialogContent>

        </Dialog>


        {/* ALERT DIALOG */}
        <AlertDialog 
            open={alertOpen} 
            setOpen={setAlertOpen} 
            send={sendInvitations} 
            subtitle="¿Estás seguro que deseas enviar las invitaciones?"/>
        
    </>
  );
});

