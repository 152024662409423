import AccountEditor from 'components/Account/AccountEditor';
import AmazonAccountsEditor from 'components/Kits/Editor';
import AmazonAccountsList from 'components/Kits/List';
import Home from 'components/Home/Home';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom'
import Cookies from 'universal-cookie';
import KitsList from 'components/Kits/List';
import KitsEditor from 'components/Kits/Editor';
import ScheduleView from 'components/Tasks/ScheduleView';
import WorkstationList from 'components/Workstations/WorkstationList';
import WorkstationEditor from 'components/Workstations/WorkstationEditor';
import UserWorkstationList from 'components/Workstations/User/UserWorkstationList';
import LoginAdmin from 'components/Admin/Login/Login';
import AppRoutesAdmin from 'AppRoutesAdmin';
import FormsList from 'components/Forms/FormsList';
import FormQuestions from 'components/Forms/FormEditor';
import TicketsList from 'components/Tickets/TicketsList';
import TicketsEditor from 'components/Tickets/TicketsEditor';

const cookies = new Cookies();



export default function AppRoutes(props:any) {

  const role_id = cookies.get('role_id');
  const {setLoading} = props;
  
  
  return (
    <>
    {role_id == 1 && (
      <Routes>
            <Route path='/' element={<Home setLoading={setLoading}/>} />

            {/* KITS */}
            <Route path="/kits" element={<KitsList setLoading={setLoading}/>} />
            <Route path="/kits/add" element={<KitsEditor setLoading={setLoading}/>} />
            <Route path="/kits/edit/:kit_id" element={<KitsEditor setLoading={setLoading}/>} />

            {/** FORMS*/}
            <Route path="/forms" element={<FormsList setLoading={setLoading}/>} />
            <Route path="/forms/:form_id/questions" element={<FormQuestions setLoading={setLoading}/>} />
            
            {/* WORKSTATIONS */}
            <Route path="/workstations" element={<WorkstationList setLoading={setLoading}/>} />
            <Route path="/workstations/add" element={<WorkstationEditor setLoading={setLoading}/>} />
            <Route path="/workstations/edit/:workstation_id" element={<WorkstationEditor setLoading={setLoading} isCompany={true}  />} />

            {/* SCHEDULE */}
            <Route path="/calendario" element={<ScheduleView setLoading={setLoading}/>} />



            {/* USERS MODULES */}
            <Route path="/myworkstations" element={<UserWorkstationList setLoading={setLoading}/>} />
            <Route path="/myworkstations/edit/:workstation_id" element={<WorkstationEditor setLoading={setLoading}/>} />

            {/* TICKETS */}
            <Route path="/tickets" element={<TicketsList setLoading={setLoading}/> } />
            <Route path="/tickets/add" element={<TicketsEditor setLoading={setLoading}/> } />
            <Route path="/tickets/edit/:ticket_id" element={<TicketsEditor setLoading={setLoading}/> } />



            {/* MY ACCOUNT */}
            <Route path="/account" element={<AccountEditor setLoading={setLoading}/>} />
            
        </Routes>
      )}

      {role_id == 100 && (
        <AppRoutesAdmin setLoading={setLoading}/>
      )}

      </>

  );
}
