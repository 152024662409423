import { Fragment, useState } from "react";
import {Button, Link, Menu, MenuItem} from '@mui/material';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export default function AccountMenu(){

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        cookies.remove('ltkn');
        cookies.remove('user_id');
        cookies.remove('user_name');
        cookies.remove('user_steps');
        localStorage.clear();
        window.location.href = '/';
    }


    return (
        <Fragment>
            <Button
                id="menu_button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <AppAvatar 
                    name={localStorage.getItem('user_name')} 
                    image={localStorage.getItem('user_image')}
                    size={40}
                />
            </Button>
            
            <Menu
                id="MainDropMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem >
                <ListItemIcon>
                <img src={ic_menu}></img>
                </ListItemIcon>
                <Link href="/">Dashboard</Link>
                </MenuItem> */}


                <MenuItem><Link href="/account">Mi perfil</Link></MenuItem>
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            </Menu>
        </Fragment>
    );
};



