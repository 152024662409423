import React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Link,
    Button
} from '@mui/material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

const cookies = new Cookies();

export default function FormLogin( props: any ) {

    const {
        logo,
        setLoading,
        setState,
        email,
        setEmail,
        loadingButton,
    } = props;
    
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*================================================
    SIGN IN (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        axios
            .get(ProjectConfig.api_url, {
            params: {
                request: "sign_in_admin",
                email: data.get('email'),
                password: data.get('password'),
            },
            })
            .then((response) => {
            console.log(response.data);
            
            if(response.data.status === "success"){
                
                enqueueSnackbar(response.data.message, { variant: 'success' });
                const user_data = response.data.user_data;
                console.log(user_data);

                /*================================================
                SET COOKIES
                ================================================*/
                cookies.set('ltkn', user_data.ltkn, { path: '/', maxAge: (86400 * 365) });
                cookies.set('user_id', user_data.user_id, { path: '/', maxAge: (86400 * 365) });
                cookies.set('user_name', user_data.user_name, { path: '/', maxAge: (86400 * 365) });
                cookies.set('role_id', user_data.role_id, { path: '/', maxAge: (86400 * 365) });
                /*================================================
                ================================================*/

                localStorage.setItem('user_id', user_data.user_id);
                localStorage.setItem('user_name', user_data.user_name);
                localStorage.setItem('user_image', user_data.image);
                localStorage.setItem('role_id', user_data.role_id);

                window.location.href = "/";
                
            }
            else
            {
                enqueueSnackbar(response.data.message, { variant: 'error' });
                
            }
            setLoading(false);
            
            })
            .catch((err) => {
            console.log(err.data);
            });

        
    };
    /*================================================
    ================================================*/

    return(
        <Box id='loginBox'>
            <img className='loginLogo' src={logo}></img>

            <p>Administrador</p>
            <form onSubmit={handleSubmit} className='form'>
            <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="text"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <LoadingButton
                size="small"
                type="submit"
                loading={loadingButton}
                fullWidth
                variant="contained"
                sx={{ fontSize: '13px', padding: '10px 0px'}}
            >
                Iniciar sesión
            </LoadingButton>

            <Link
                component="button"
                variant="body2"
                onClick={() => {
                    setState('forgotPass')
                }}
            >
                ¿Olvidaste tu contraseña?
            </Link>
            </form>
        </Box>
    );
};