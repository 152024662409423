import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ic_menu from 'icons/ic_menu.svg';
import LogoCasoBox from 'icons/LogoCasoBox.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import AppAvatar from 'elements/AppAvatar';
import { Link } from 'react-router-dom';
import MenuAdmin from 'MenuAdmin';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, 
  LinearProgress, 
  Typography,
  linearProgressClasses,
  styled
} from '@mui/material';
import {
  ExpandMore,
  ChairAlt,
  ListAlt,
  PersonPin,
  CalendarMonth,
  HeadsetMic,
  DirectionsWalk,
} from '@mui/icons-material';
import MenuButton from 'elements/MenuButton';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import BarChartIcon from '@mui/icons-material/BarChart';
import ic_confetti from 'icons/ic_confetti.gif';
const cookies = new Cookies();

const styleMenu = {
  buttons: {
    textDecoration: 'none',
    padding: '10px 20px',
    width: '100%',
    justifyContent: 'start',
    color: '#637381',
  },
  
  button_labels: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    textTransform: 'uppercase',
  },
  button_imgs: {
  },
  accordion: {
    padding: '0px 20px',
    bgcolor: 'transparent',
    color: '#ffffff',
    boxShadow: 'none',
    '&:hover': {backgroundColor: 'transparent'},
  },
  accordion_buttons: {
    display: 'flex',
    color: '#222',
    fontSize: '14px',
    width: '100%',
    justifyContent: 'start',
    columnGap: '10px',
    alignItems: 'center',
  },
  accordion_img_company: {
    width: '30px',
    height: '30px',
    margin: '0',
  },
  accordion_imgs: {
    width: '30px',
    height: '30px',
    padding: '4px',
    margin: '0',
  },
}



export default function MainMenu( props: any ) {
  const { isIntour } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openFirstSteps, setOpenFirstSteps] = useState<boolean>(false);

  useEffect(() => {
    if (openFirstSteps) {
      props.setOpenFirstSteps(openFirstSteps);
      setOpenFirstSteps(false);
    }
  }, [openFirstSteps]);

  const handleClickLink = (href: string) => {
    window.location.href = href;
  }

  const [list, setList] = useState<any[]>([]);

  useEffect (() => {
    getCompanies();
  }, []);

  const getCompanies = () => {
    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn')
        },
        params: {
          request: 'companies_list',
          params: {
            full_list: true,
          }
        }
      })
        .then((response) => {
          setList(response.data.list)

          //ADD TO EACH ITEM LIST THE open AND anchorEl STATE
          response.data.list.map((item: any, index: number) => {
            item.open = false;
            item.anchorEl = null;
          })

          cookies.set('default_company_id', response.data.list[0].company_id)

           
        })
        .catch((err) => {
          console.log(err.data);
        })
  }
      
  const handleClickCompany = (event: React.MouseEvent<HTMLButtonElement> | any, index: number) => {
    list[index].anchorEl = event.currentTarget;
    list[index].open = true;
    setList([...list]);
  };

  const handleCloseCompany = (index: number) => {
    list[index].anchorEl = null;
    list[index].open = false;
    setList([...list]);
  };

  const handleClickItemCompany = (company_id:any) => {
    cookies.set('company_id', company_id, { path: '/', maxAge: (86400 * 365) });
  };

  const role_id = cookies.get('role_id');

  const [completedSteps, setCompletedSteps] = useState<number>(1);
  const [totalSteps, setTotalSteps] = useState<number>(1);

  useEffect(() => {
    const user_steps = cookies.get('user_steps');
    
    if (user_steps) {
      let completed = 0;
      if (user_steps !== 'null') {
        user_steps.map((item: any) => {
          if (item.status) {
            completed++;
          }
        })
        setTotalSteps(user_steps.length);
        setCompletedSteps(completed);
      } else {
        setTotalSteps(1);
        setCompletedSteps(1);
      }
    }
  }, [cookies.get('user_steps')])


  const progress = (completedSteps*100)/totalSteps;

  const getProgressText = () => {
    if (progress === 100) {
      return '';
    } else {
      return `${completedSteps}/${totalSteps}`;
    }
  }

  return (
    <div className='menu_button_container'>

      {role_id < 100? (
        <>
          {isIntour &&
          <div id="first_steps_menu_container">
            <div id="first-steps-header">
             

              <div id="progressbar-container">

                <img src={ic_confetti} alt="confetti" className="ic_confetti" style={{display: progress === 100? 'block': 'none'}} />

                <Button
                  id="first-steps-button"
                  
                  fullWidth
                  aria-expanded={openFirstSteps ? 'true' : undefined}
                  onClick={() => {setOpenFirstSteps(!openFirstSteps)}}
                  
                  color='inherit'
                  style={{textTransform: 'none'}}
                >
                  <div style={{width: '100%'}}>
                  
                    <h2 className='menu_title'>Ir al tutorial</h2>
                  
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                      <LinearProgress
                        variant='determinate'
                        value={progress}
                        sx={{
                          '--LinearProgress-radius': '0px',
                          '--LinearProgress-progressThickness': '24px',
                          boxShadow: 'sm',
                          borderColor: 'transparent',
                          backgroundColor: '#e0e0e0',
                          position: 'relative',
                          padding: '10px',
                          borderRadius: '5px',
                          '&::after': {
                            content: `'${getProgressText()}'`,
                            position: 'absolute',
                            top: '50%',
                            right: '0',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '10px',
                            color: progress === 100? '#ffffff': '#000000',
                          },
                        }}
                        
                      />
                      <ElectricBoltIcon color='primary' sx={{marginLeft: '10px'}} />
                    </div>
                  </div>
                </Button>
                
              </div>
            </div>

              
              
          </div>
          }

          {list.length > 0 && (
            <div id='menu_companies_container' className=' no-gap'>
              
                {list.map((item, index) => {

                  
                  
                  return (
                    <div key={index}>

                      <h2 className='menu_title'>
                       {item.company_name}
                      </h2>

                      <MenuButton company_id={item.company_id} label="Dashboard" to="/" startIcon={<BarChartIcon />} onClickCallback={() => {handleClickItemCompany(item.company_id)}} />

                      <MenuButton company_id={item.company_id} label="Kits" to="/kits" startIcon={<ChairAlt />} onClickCallback={() => {handleClickItemCompany(item.company_id)}} />
                      
                      <MenuButton company_id={item.company_id} label="Puestos" to="/workstations" startIcon={<PersonPin />} onClickCallback={() => {handleClickItemCompany(item.company_id)}} />

                      <MenuButton company_id={item.company_id} label="Formularios" to="/forms" startIcon={<ListAlt />} onClickCallback={() => {handleClickItemCompany(item.company_id)}} />

                      <MenuButton company_id={item.company_id} label="Calendario" to="/calendario" startIcon={<CalendarMonth />} onClickCallback={() => {handleClickItemCompany(item.company_id)}} />
                    </div>
                  )
                })}

          
          </div>
          )}

          <div>

            <h2 className='menu_title'>
              Usuario
            </h2>

            <MenuButton label="Mis puestos" to="/myworkstations" startIcon={<PersonPin />} />

            <MenuButton label="Soporte" to="/tickets" startIcon={<HeadsetMic />}/>
            
          </div>
        </>
      ):(
        <MenuAdmin />
      )}


      
    </div>
  );
}
