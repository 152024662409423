import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import moment from "moment";
import AppAvatar from "elements/AppAvatar";
import AddWorkstation from "../Utils/AddWorkstation";
import Cookies from "universal-cookie";
import AlertDialog from "elements/AlertDialog";
import AddProductInvitation from "../Utils/AddProductInvitation";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import RegisterWorkstation from "../Utils/RegisterWorkstation";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import InventoryIcon from "@mui/icons-material/Inventory";
import ResendInvitation from "../Utils/ResendInvitation";
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import 'assets/css/main.css';

const cookies = new Cookies();

export default function UserWorkstationList(props: any) {
  const { setLoading } = props;

  const [hasInvitation, setHasInvitation] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [listPending, setListPending] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);

  const currentPage = parseInt(localStorage.getItem("page") || "1");

  const [page, setPage] = useState(currentPage);
  const [checkedList, setCheckedList] = useState<any[]>([]);

  const [openRegisterWorkstation, setOpenRegisterWorkstation] = useState(false);

  const [openInvitationResend, setOpenInvitationResend] = useState(false);
  /*=========================================================
  LOAD LIST            
  =========================================================*/

  //WHEN PAGE CHANGES
  useEffect(() => {
    loadList();
  }, [page]);

  //LOAD LIST
  const loadList = () => {
    setLoading(true);
    axios
      .get(ProjectConfig.api_url, {
        headers: {
          LTKN: cookies.get("ltkn"),
        },
        params: {
          request: "user_workstations_list",
          params: {
            page: page,
          },
        },
      })
      .then((response) => {
        console.log("res", response.data);
        setLoading(false);
        setList(response.data.list);
        setListPending(response.data.list_pending);
        setTotalPages(response.data.total_pages);
      })
      .catch((err) => {
        console.log(err.data);
      });
    localStorage.setItem("page", page.toString());
  };

  /*=========================================================*/

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const [itemToDelete, setItemToDelete] = useState(0);

  const deleteWorkstation = () => {
    const id = itemToDelete;

    axios
      .post(ProjectConfig.api_url, null, {
        headers: {
          LTKN: cookies.get("ltkn"),
        },
        params: {
          request: "delete_workstation",
          id: id,
        },
      })

      .then((response) => {
        console.log(response.data);
        loadList();
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*=========================================================*/

  const [workstation_id_selected, setWorkstation_id_selected] = useState(0);

  return (
    <>
      <RegisterWorkstation
        open={openRegisterWorkstation}
        setOpen={setOpenRegisterWorkstation}
        setLoading={setLoading}
        workstation_id={workstation_id_selected}
        loadList={loadList}
      />

      <ResendInvitation
        open={openInvitationResend}
        setOpen={setOpenInvitationResend}
        setLoading={setLoading}
        workstation_id={workstation_id_selected}
      />

      <div
        id="PuestosList"
        className="ModuleContainer"
        style={{ paddingBottom: checkedList.length > 0 ? "100px" : "0px" }}
      >
        <div className="ModuleWrapper">
          <div id="userWorkstationsHeader" className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Mis puestos</h2>
              <span className="h2_subtitle">Lista de cuentas vinculadas</span>
            </div>

            <div className="boxContainerActions"></div>
          </div>

          <div id="PuestosListIn">
            <div id="workstationList" className="listContainer">
              {list.length>0? list.map((item, i) => (
                <Fragment key={i}>
                  <Box className="boxWorkstation">
                    <div className="boxWorkstation_content">
                      <div className="workstationList_image">
                        <Avatar
                          variant="rounded"
                          style={{ backgroundColor: "#fff", color: "#ddd" }}
                          sx={{ width: "100%", height: 150 }}
                          src={item.company_logo ? item.company_logo : ""}
                        >
                          <MapsHomeWorkIcon
                            sx={{ width: "100%", height: "100%" }}
                          />
                        </Avatar>
                      </div>

                      <div className="flexTd">
                        <span className="flexTd_bold">
                          {item.address ? item.address : "Dirección pendiente"}
                        </span>
                        <span className="flexTd_normal">
                          {item.city_name ? item.city_name : ""}
                        </span>
                      </div>

                      <Chip
                        label={
                          item.status == 1
                            ? "Sin registrar"
                            : item.status == 2
                            ? "Activo"
                            : "Validar invitación"
                        }
                        variant="outlined"
                      />
                    </div>

                    {item.status === 2 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<OpenInNewIcon />}
                        component={Link}
                        to={`/myworkstations/edit/${item.workstation_id}`}
                        sx={{ borderRadius: 0, padding: "10px 10px" }}
                      >
                        Editar
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color={item.status == 1 ? "error" : "warning"}
                        size="small"
                        startIcon={
                          item.status == 1 ? (
                            <ShareLocationIcon />
                          ) : (
                            <InventoryIcon />
                          )
                        }
                        onClick={() => {
                          setWorkstation_id_selected(item.workstation_id);

                          if (item.status == 1) {
                            setOpenRegisterWorkstation(true);
                          } else {
                            setOpenInvitationResend(true);
                          }
                        }}
                        sx={{ borderRadius: 0, padding: "10px 10px" }}
                      >
                        {item.status == 1 ? "Registrar" : "Ver invitación"}
                      </Button>
                    )}
                  </Box>
                </Fragment>
              )): 
                <Box className="emptyList box100 box_editor">
                  <div className="emptyList_content">
                    <DomainDisabledIcon sx={{ fontSize: 100, color:"#ccc" }} />
                    <div>
                      <h2>No hay puestos vinculados a tu cuenta.</h2>
                      <p>Por favor comunicate con tu administrador de empresa. </p>
                    </div>
                  </div>

                  <div className="buttons">
                    <div className="flexH10">
                      ¿Necesitas ayuda?  
                      {/*mailto:comunidad@goshofi.com */}
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<HelpOutlineIcon />}
                        href={'mailto:comunidad@goshofi.com'}
                        sx={{ borderRadius: 0, padding: "10px 10px" }}
                      >
                        Contáctanos
                      </Button>
                      </div>
                  </div>


                </Box>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
