
// <MetricsRow 
// standfordProductivity={standfordProductivity} 
// trafficSavings={trafficSavings} 
// co2Savings={co2Savings} />

import React, { CSSProperties } from "react";

export default function MetricsRow(props: any) {

  const { standfordProductivity, trafficSavings, co2Savings } = props;

  const stylesOverride = {
    "--color3": "#4a9ea4",
    "--color8": "#69931a",
  } as CSSProperties

  return (
    <>
      <div className="graph_row">
        <div id="contenedor_dato_co2" className="indicador_tipo_1" style={stylesOverride}>
          <div>Has generado un impacto positivo para el medio ambiente de</div>
          <div id="fila_dato_co2" className="ind_1_fila_datos">
            <span id="dato_co2" className="ind_1_dato">
              {co2Savings}
            </span>
            <div id="icono_co2" className="ind_1_icono">
              <div id="icono_co2_1" className="ind_1_icono_txt_1">
                TONELADAS
              </div>
              <div id="icono_co2_2" className="ind_1_icono_txt_2">
                CO2
              </div>
            </div>
          </div>
          <div>que ayudan a tener una mejor calidad de aire.</div>
        </div>

        <div id="contenedor_dato_trafico" className="indicador_tipo_1">
          <div>Tus empleados han ahorrado un estimado de</div>
          <div id="fila_dato_trafico" className="ind_1_fila_datos">
            <span id="dato_trafico" className="ind_1_dato">
              {trafficSavings}
            </span>
            <div id="icono_trafico" className="ind_1_icono">
              <div id="icono_trafico_1" className="ind_1_icono_txt_1">
                HORAS
              </div>
              <div id="icono_trafico_2" className="ind_1_icono_txt_2">
                DE TRÁFICO
              </div>
            </div>
          </div>
          <div>que ayudan a tener una mejor calidad de aire.</div>
        </div>

        <div id="contenedor_dato_productividad" className="indicador_tipo_1" style={stylesOverride}>
          <div>Tu empresa ha obtenido una productividad incremental de </div>
          <div id="fila_dato_trafico" className="ind_1_fila_datos">
            <span id="dato_productividad" className="ind_1_dato">
              {standfordProductivity}
            </span>
            <div id="icono_productividad" className="ind_1_icono">
              <div id="icono_productividad_1" className="ind_1_icono_txt_1">
                HORAS
              </div>
              <div id="icono_productividad_2" className="ind_1_icono_txt_2">
                GANADAS
              </div>
            </div>
          </div>
          <div>
            asociada al trabajo en casa
          </div>
        </div>
      </div>
    </>
  );
}
