import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, Dialog, DialogContent, DialogContentText, Icon, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {ProjectConfig} from 'Global';
import Cookies from 'universal-cookie';
import ic_empty from 'icons/ic_empty.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StatusTimeline from 'components/Workstations/Utils/StatusTimeline';
import TimeLine from 'components/Workstations/Utils/Timeline';

const cookies = new Cookies();

export default function ScheduleDetails(props: any) {
  
  const {setLoading, open, setOpen, data} = props;
  const [alertOpen, setAlertOpen] = useState(false);

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  
  return (
    <>
    {Object.keys(data).length === 0 ? null :
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
          <DialogContent  className="forced_box_editor">

          <div className="dialog_header">
              <h2 className='dialog_h2'>{data.username}</h2>
              <DialogContentText>
              Estado del puesto de trabajo
              </DialogContentText>
          </div>
            

         <h2>{}</h2>
         <StatusTimeline status={data.status} />
         <TimeLine workstation_id={data.workstation_id} />
          

          <div className="buttons">
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
              >
                  Close
              </Button>
              
             

          </div>

          </DialogContent>
      </Dialog>
    }
    

{/* 
      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={cancelOffer}
        subtitle="Are you sure you want to cancel this offer?"
      /> */}
       
    </>
  );
};

