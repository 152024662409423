import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import MainMenu from './Menu'
import logo from './assets/img/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider, useSnackbar } from 'notistack';
import AppRoutes from 'AppRoutes';
import { BrowserRouter as Router , useLocation, useSearchParams} from 'react-router-dom'

import Login from 'components/Login/Login';
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import AccountMenu from 'components/Account/AccountMenu';
import LoginAdmin from 'components/Admin/Login/Login';
import LinkUserToWorkstation from 'elements/LinkUserToWorkstation';
import FirstSteps from 'elements/FirstSteps';

const cookies = new Cookies();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#0271e2',
      main: '#0271e2',
      dark: '#073768',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e69203',
      main: '#e69203',
      dark: '#cb8308',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
  

});


const Site = () => {
  const childRef = useRef();
  const userIsLogged = cookies.get('ltkn') !== undefined;
  /*=======================================
  =======================================*/


  /*=======================================
    TRY TO GET INVITATION PARAMS
  =======================================*/
  const [searchParams, setSearchParams] = useSearchParams();
  const invitation = searchParams.get("invitation")
  const email = searchParams.get("email")
  const workstation_code = searchParams.get("workstation_code")

  //IF INVITATION PARAMS EXISTS 
  if(invitation && email && workstation_code){
    console.log("invitation params", invitation, email, workstation_code);
    localStorage.setItem('invitation', invitation);
    localStorage.setItem('email', email);
    localStorage.setItem('workstation_code', workstation_code);
  }

  const [invitationParams, setInvitationParams] = useState({});


  const [loading, setLoading] = useState(false);

  const [openFirstSteps, setOpenFirstSteps] = useState(false);

  const location = useLocation();
  const isHome =location.pathname === "/"? "home" : "";

  const [isIntour, setIsIntour] = useState<boolean>(false);

  useEffect (() => {
    const userSteps = cookies.get('user_steps');
    if(userSteps && userSteps !== null){
      setIsIntour(true);
    }
  }, [cookies.get('user_steps')])

  useEffect(() => {
    const userSteps = cookies.get('user_steps');

    if(userSteps == 'null'){
      setIsIntour(false);
    }
  }, [location]);


    return (
    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3}>
        {
            //IF USER IS LOGGED AND INVITATION PARAMS EXISTS
          (userIsLogged && invitation && email && workstation_code) &&
          (
            <LinkUserToWorkstation 
              email={localStorage.getItem('email')}
              workstation_code={localStorage.getItem('workstation_code')}
              ltkn = {cookies.get('ltkn')}
              setInvitationParams={setInvitationParams}
            />
            
          )
          /*=======================================
          =======================================*/
        }

        {//  userIsLogged ? REDIRECT TO LOGIN
        !userIsLogged? (
          //URL IS /ADMIN
          location.pathname.includes("/admin") ? (
            <LoginAdmin setLoading={setLoading} />
          ) : (
            <Login setLoading={setLoading} />
          )
          
        ) : (
          <>
          <div id='structure'>
            <aside id="menu">
              <div id='logo'>
                <a href='/'>
                  <img src={logo}></img>
                  
                </a>
                
              </div>
              <MainMenu
                isIntour={isIntour}
                setOpenFirstSteps={setOpenFirstSteps}
              />
              
            </aside>
            <div id="main-container"> 
              <div id='top_bar'>
                  <div id='top_bar_left'></div>
                  <div id='top_bar_right'>
                    <AccountMenu />
                  </div>
              </div>
              <div id="main-content">
              <div id='loader' className={!loading? 'loaderHide' : ''}>
                <CircularProgress />
              </div>
                <AppRoutes setLoading={setLoading} />
              </div>
            </div>
          </div>
          
          {isIntour &&
          <FirstSteps
            setLoading={setLoading}
            openStepDialog={openFirstSteps}
            setOpenStepDialog={setOpenFirstSteps}
            setIsIntour={setIsIntour}
          />
          }
          </>
        )}
      </SnackbarProvider>
    </ThemeProvider>
    );
  
}

// ========================================
 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <Router>
      <Site />
    </Router>
);







