import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Typography } from "@mui/material"
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import AppAvatar from "elements/AppAvatar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ISwitch from "elements/ISwitch";
import Map from './Utils/Map';
import InvitationDetail from "./Utils/InvitationDetail";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useSnackbar } from "notistack";
import StatusTimeline from "./Utils/StatusTimeline";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AlertDialog from "elements/AlertDialog";
import Cookies from 'universal-cookie';
import TimeLine from "./Utils/Timeline";


const cookies = new Cookies();


const workstationDefault = {
  user: {
    user_id: 0,
    name: '',
    lastname: '',
    status: '',
    email: '',
    phone: '',
    image: '',
    user_height: '',
    shirt_size: '',

  },
  workstation: {
    workstation_id: 0,
    products: [],
    other_products: [],
    location: {
      address: '',
      latitude: 0,
      longitude: 0,
      city_id: '',
      city_name: '',
      
    },
    name: '',
    lastname: '',
    status: -1,
  },
  invitations: [],
}

 
 export default function WorkstationEditor(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const company_id = cookies.get('company_id');
  
  let {workstation_id } = useParams();

  const [data, setData] = useState(workstationDefault);

  const [invitations, setInvitations] = useState([]);

  const { setLoading, isCompany, isAdmin } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if(workstation_id){
      getWorksationDetails()
    }
  }, [workstation_id]);

  const getWorksationDetails = () => {
    axios.get(ProjectConfig.api_url,{
        headers: {
          'LTKN': cookies.get('ltkn')
        },
        params: {
          request: 'get_workstation_details',
          params: {
            workstation_id: workstation_id,
          }
        }
      })
        .then((response) => {
          console.log("1",response.data);
          setData(response.data);

        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // always executed
        });
  }


  const [openInvitation, setOpenInvitation] = useState(false);
  const [active_invitation, setActiveInvitation] = useState(0);

  const setCoordinates = (coordinates: any) => {
    console.log("coord",coordinates)
  }


  const [alertOpen, setAlertOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const pickupProduct = () => {

    axios.post(ProjectConfig.api_url, null, 
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'pickup_product',
          params: {
            workstation_product_id: activeItem,
          }
        }
      })
  
      .then((response) => {
        console.log(response.data);
        getWorksationDetails()
        
      })
      .catch((err) => {
        console.log(err.data);
      })
    }

  


  return (
    <>
      <InvitationDetail
        open={openInvitation}
        setOpen={setOpenInvitation}
        setLoading={setLoading}
        workstation_invitation={active_invitation}
        getWorksationDetails={getWorksationDetails}
      />

      <div id="Workstation">

        <div id='puesto_header'>
          <div id="puesto_header_left">
            <AppAvatar size={100} name="Juan Torres" image={"http://caso.localhost.com//files/External/users/2.jpg"}/>
              
              <div id='puesto_header_left_right'>
                <span className="puesto_name">{data.workstation.name} {data.workstation.lastname}</span>
                <span className="puesto_email">juantorres@greenars.com.co</span>
                <ISwitch label="Activo" checked={true} onChange={(e:any) => console.log(e.target.checked)}/>
              </div>
            </div>
            <div id='puesto_header_right'>
              {isAdmin || isCompany && (
              <StatusTimeline status={data.workstation.status} />
              )}
            </div>
          
        </div>


        {data.invitations.length > 0 && (
          <div id='workstation_invitations'>
            {data.invitations.map((invitation: any, index: number) => {

              // if invitation.status == 0 AND !isCompany not render 
              if(invitation.status != 0 && !isCompany && !isAdmin){
                return null;
              }

              return (
                <div className="workstation_invitation" key={index}>
                  <div className="workstation_invitation_left">
                    
                    <Inventory2OutlinedIcon  fontSize="large" sx={{color: '#ccc'}} />
                    <div className="workstation_invitation_info">
                      <span className="workstation_invitation_title">{invitation.invitation_title}</span>
                      <span className="workstation_invitation_description">{invitation.invitation_description}</span>
                    </div>
                  </div>
                  <div className="workstation_invitation_right">

                    {!isAdmin && !isCompany && (
                    <Button variant="contained" color="primary" size="small" onClick={
                      () => {
                        setActiveInvitation(invitation)
                        setOpenInvitation(true)
                      }
                    }
                    startIcon={<Inventory2OutlinedIcon />}
                    >Ver invitación</Button>
                    )}
                    
                    {isAdmin || isCompany && (
                      <Chip 
                        label={invitation.status == 1 ? "Aceptada" : "Pendiente"}
                        color={invitation.status == 1 ? "primary" : "error"} 
                        variant="outlined"
                        size="small" />
                    )}



                  </div>
                </div>
              )
            })}
          </div>
        )}
              


        <div id='puesto_section'>
          <div id='puesto_section_left'>
            <Box title="Productos" subtitle="Kit" className="puesto_section_box">
              

            {data.workstation.products.map((product_group: any, index: number) => {
              
              return (
              <Accordion sx={{ boxShadow: 'none' }} defaultExpanded disableGutters key={index}> 
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{color: '#ffffff'}} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{padding: '0px 16px', backgroundColor:product_group.status_color, color: '#ffffff', borderRadius: '5px'}}
                >
                  <Typography sx={{fontSize: '14px'}}>{product_group.status_name} ({product_group.total})</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding: '0px'}}>
                    <Fragment>
                      {product_group.list.map((product: any, index: number) => {
                        return (
                            <div className="workstation_product_row" key={index}>
                              <div className="workstation_product_left">
                                <AppAvatar image={product.product_image} name = {product.product_name} imgSize='contain'/>
                              <div className="workstation_product_right">
                                <div className="workstation_product_right_left">
                                  <span className="workstation_product_name"> 
                                    {product.product_name} 
                                  </span>
                                  <span className="workstation_product_price">{product.product_price}</span>
                                </div>
                                <div className="workstation_product_right_right">
                                  <Chip label={"x " + product.product_quantity} variant="outlined" size={"small"} sx={{fontSize: '10px'}}/>
                                </div>
                              </div>
                              </div>
                              {isCompany && product_group.status_name == "Instalado" &&
                              <Button
                                variant="text"
                                color="error"
                                size="small"
                                startIcon={<FileDownloadIcon />}
                                onClick={() => {
                                  setActiveItem(product.workstation_product_id)
                                  setAlertOpen(true)
                                }}
                              >
                                Retirar
                              </Button>
                              }
                            </div>
                        )})}
                  </Fragment>
                    
                </AccordionDetails>
              </Accordion>
            )})}



            </Box>

            <Box title="Ubicación" subtitle="Mapa" className="puesto_section_box">
              <Map
                latitude={data.workstation.location.latitude}
                longitude={data.workstation.location.longitude}
                setCoordinates={setCoordinates}
              />
            </Box>
            {/* <div id='puesto_section_map'>
          
          
        </div> */}


          </div>
          
          <div>
            <TimeLine workstation_id={workstation_id} />
          </div>
        </div>
      </div>

      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={pickupProduct}
        subtitle="¿Estás seguro de retirar este elemento?"
      />


    </>
  );
};