import { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogContent, DialogContentText, TextField } from '@mui/material';
import { useSnackbar, withSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';

import mapOptions from '../Utils/map-options.json'
import Marker from '../Utils/marker'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Map from '../Utils/Map';


export default withSnackbar(
    function SearchLocation(props: any) {

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    
    const [center, setCenter] = useState([4.753462, -74.700521]) 

    const {open, data, setData, setOpen, setLoading} = props;
    const [alertOpen, setAlertOpen] = useState(false);

    const confirm = () => {
        setData({...data, address: address})
        setOpen(false)
    }



    const [address, setAddress] = useState({
        value: "",
        latitude: -74.100521,
        longitude: 4.653462,
        error: false,
    });


    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)
    

    const search = () => {
        setLoading(true)
        axios.get(ProjectConfig.api_url,
            {
              params: {
                request: 'search_address',
                params: {
                  address: address.value,
                  city_id: data.city_id.value,
                }
              }
            })
              .then((response) => {
                console.log(response.data);
                setLoading(false)

                if(response.data.status === "success"){
                    setAddress({...address, 
                        latitude: response.data.latitude, 
                        longitude: response.data.longitude
                    })

                    setLatitude(response.data.latitude)
                    setLongitude(response.data.longitude)
                    
                }else{
                    enqueueSnackbar(response.data.message, {variant: "error"});
                }
                 
              })
              .catch((err) => {
                console.log(err.data);
              })
    }
    //AIzaSyCISPFtXVXEddeRH4XmosTAZD-eJCeGQfA

    const setCoordinates = (coordinates: any) => {
        console.log("coord",coordinates)
        setAddress({...address,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
        })
    }

  return (
    <>
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            
            <DialogContent  className="forced_box_editor">
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Confirma tu dirección</h2>
                    <DialogContentText>
                        Para registrarte, necesitamos que confirmes tu dirección.
                    </DialogContentText>
                </div>



                <form className="form">
                    
                  
                    <div className="input_row">
                        <TextField
                            fullWidth
                            id="address"
                            label="Dirección"
                            type="text"
                            value={address.value}
                            error={address.error}
                            helperText={data.address.error ? "Por favor ingresa una dirección" : ""}
                            onChange={(e) => setAddress({...address, value: e.target.value})}
                        />
                        <div className="search_button">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={search}
                                startIcon={<LocationSearchingIcon />}
                            >
                                Buscar
                            </Button>
                        </div>
                    </div>
           

                    <Map
                        latitude={latitude}
                        longitude={longitude}
                        setCoordinates={setCoordinates}
                        markerDraggable

                        />
                            
                </form>



                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>

                    <Button 
                        variant="contained" 
                        onClick={
                            () => {
                                setAlertOpen(true)
                            }
                        }
                        >
                        Registrar
                    </Button>
                </div>
            </DialogContent>

        </Dialog>


        {/* ALERT DIALOG */}
        <AlertDialog 
            open={alertOpen} 
            setOpen={setAlertOpen} 
            send={confirm}
            subtitle="¿Estás seguro de que esta es tu ubicación?" 
            />
        
    </>
  );
});

