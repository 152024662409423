import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';

export default function FormFinished(props:any) {
    const {
        selectedForm,
        handleClose,
        handleConfirm,
    } = props;
    
    return (
        <>

            <DialogContent
                className='forced_box_editor'
                sx={{ width: '100%', minWidth: '500px',}}
            >
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Formulario finalizado</h2>
                    <DialogContentText>Formulario</DialogContentText>
                </div>

                <div className='formDescription'>
                    <Typography variant='body1'>
                        {(
                            selectedForm ?
                            selectedForm.finished_message :
                            null
                        )}
                    </Typography>
                </div>

                <div
                    className='buttons'
                    style={{ width: '100%', padding: '1rem', justifyContent: 'center', fontSize: '1rem',minWidth: '500px', fontWeight: 'lighter'}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleConfirm();
                            handleClose()
                        }}
                    >
                        Cerrar
                    </Button>
                </div>
            </DialogContent>

        </>
    );
};