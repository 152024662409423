import {Wrapper} from '@googlemaps/react-wrapper';
import { useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Map(props: any) {
    
  const {latitude, longitude, setCoordinates, markerDraggable=false} = props;
  const [LoadingIn, setLoadingIn] = useState(true);

  

  return (
    <div className='MapContainer'>
      <div className={LoadingIn ? 'LoaderIn' : 'LoaderIn hidden'}
      ><CircularProgress /></div>
    <Wrapper apiKey={"AIzaSyCISPFtXVXEddeRH4XmosTAZD-eJCeGQfA"}>
      <GMap latitude={latitude} longitude={longitude} setCoordinates={setCoordinates} markerDraggable={markerDraggable} setLoadingIn={setLoadingIn}/>
    </Wrapper>
    </div>
  );
};




const GMap = ({latitude, longitude, setCoordinates, markerDraggable, setLoadingIn}: {latitude: number; longitude: number, setCoordinates:any, markerDraggable:any, setLoadingIn: any}) => {
  const ref = useRef(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  

  useEffect(() => {
    if (ref.current && !map) {
      
      setMap(
        new google.maps.Map(ref.current, {
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          center: {
            lat: 4.1156735,
            lng: -72.9301367,
          },
          zoom: 5,
        })
      );
      setLoadingIn(false)
    }
  }, [ref, map, latitude, longitude]);


  //UPDATE MAP POSITION
  useEffect(() => {
    if (map) {
      if (latitude !== 0 && longitude !== 0) {
        map.setCenter({
          lat: latitude,
          lng: longitude,
        });
        map.setZoom(17);
      } else {
        map.setCenter({
          lat: 4.1156735,
          lng: -72.9301367,
        });
        map.setZoom(5);
      }
    }
  }, [latitude, longitude, map]);





  const [marker, setMarker] = useState<google.maps.Marker | null>(null);


    //MARKER 
    useEffect(() => {
      if (map && !marker) {
          setMarker(
              new google.maps.Marker({
                  position: {
                      lat: latitude ?? 0,
                      lng: longitude ?? 0,
                  },
                  map: map,
                  draggable: markerDraggable,
              })
          );
      }
  }, [map, latitude, longitude]);


  //MOVE MARKER
  useEffect(() => {
      if (marker) {
          marker.setPosition({ 
              lat: latitude ?? 0,
              lng: longitude ?? 0,
          });
      }
  }, [latitude, longitude, marker]);


  //UPDATE MARKER POSITION
    useEffect(() => {
      if (marker) {
          marker.addListener('dragend', (event:any) => {
              setCoordinates({
                  latitude: event.latLng.lat(),
                  longitude: event.latLng.lng(),
              });
          });
      }
  }, [marker]);




  

  return <div ref={ref} style={{height: '500px', width: '100%'}} />;
};
