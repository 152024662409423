import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { IconButton } from '@mui/material';
import ic_dots from 'icons/ic_dots.svg';


export default function AutocompleteProduct(props: any) {

  
  const [loading, setLoading] = useState(false); 

  const addProduct = props.addProduct;

  /* =================================================
    PRODUCT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProductList {
      inputValue?: string,
      product_id?: number,
      sale_price: number,
      stone_fields: any,
      product_name: string,
      disabled?: boolean,
    };

    const filter = createFilterOptions<IProductList>();

    //SETTER
    const [productList, setProductList] = useState<any[]>([]);
    
    useEffect(() => {
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'product_list',
          params: {
            category: props.category_filter,
            full_list: true,
            hide_groups: props.hide_groups,
          }
          
        }
      })
        .then((response) => {

          setProductList(response.data.products);
          
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, [])
    /* ================================================= 
    ===================================================*/


  return (
    <Autocomplete
    
      disabled = {props.disabled? props.disabled : false}
      value={props.product_name}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          props.setProduct({
            product_name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          addProduct(newValue.inputValue);

        } else {

          props.setProduct(
            newValue.product_name,
            newValue.product_id,
            newValue.sale_price,
            newValue.stone_fields,
            props.serviceIndex,
            props.category,
            props.itemIndex
          );
          
        }
        
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.product_name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            product_name: `Add "${inputValue}"`,
            sale_price: 0,
            stone_fields: [],
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      fullWidth
      id="autocomplete_product"
      className='autocomplete_products'
      options={productList}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.product_name;
      }}
      renderOption={
        (props, option) => 
        <li {...props} key={option.product_type+option.product_id}>
          <span className='autocomplete_product_code'>{option.product_code}</span>
          <span className='autocomplete_product_name'>{option.product_name}</span>
        </li>
      }
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField 
        {...params}
        fullWidth
        label="Product" 
        size='small'
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <IconButton 
              className="autocomplete_ic_dots"
              //onClick={handleOpenClientList}
              
            ><img src={ic_dots}></img></IconButton>
          ),
          style: {
            fontSize: '12px',
          }
        }}/>
      )}
      
    />
  );
}
