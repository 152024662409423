import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, Dialog, DialogContent, DialogContentText, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import UserEditor from '../Users/UserEditor';
import AlertDialog from 'elements/AlertDialog';

export default function CompanyUsers (props: any) {
  const {
    company_id,
    setLoading,
  } = props;

  useEffect(() => {
    setLoading(false);
  }, [])

  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=1;

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'user_list',
          params: {
            page: page,
            company_id: company_id,
          }
        }
      })
        .then((response) => {

          console.log(response.data);
          
          setUserList(response.data.user_list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/

  

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (estimate_product_id:number) => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_estimate_product',
        estimate_product_id: estimate_product_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setDeleteUserId(null);
      setRefreshList(refreshList + 1)
      
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  
  const [openEditor , setOpenEditor] = useState(false);
  const [active_item, setActiveItem] = useState<any>(null);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<any>(null);

  const popupSubmit = () => {
    setOpenEditor(false);
    setRefreshList(refreshList + 1);
  }
  
  return (
    <div id='ProductList'>
      
      <div className='buttons_top'>
        

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="Administradores" subtitle="Lista">
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Imagen</TableCell>
                  <TableCell>Nombre / Email</TableCell>
                  {/* <TableCell>Phone</TableCell> */}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {user_list.map((user,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>
                        <AppAvatar name={user.name} image={user.image} />
                      </TableCell>

                      <TableCell>
                        <span className='flex_title'>{user.name}</span>
                        <span className='flex_subtitle'>{user.email}</span>
                      </TableCell>

                      {/* <TableCell>
                        {user.phone}
                      </TableCell> */}



                      <TableCell align="right">

                        <Button 
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setOpenEditor(true)
                            setActiveItem(user.user_id)
                          }}
                        >
                          Editar
                        </Button>



                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() =>{
                            setDeleteUserId(user.user_id);
                            setOpenDeleteUser(true);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setActiveItem(null)
              setOpenEditor(true)
            }}
            style={{"marginTop": "20px","alignSelf": "center"}}
          >
            Agregar
          </Button>

          
          {total_pages > 1 && (
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
          </Box>
 
        </div>

        <Dialog
            open={openEditor}
            onClose={() => setOpenEditor(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >

            <UserEditor 
              account_user_id={active_item}
              popup={true}
              popupSubmit={popupSubmit}
              setPopupOpen={setOpenEditor}
              company_id={company_id}
              setLoading={setLoading}
              is_child={true}
            />

        </Dialog>

        <AlertDialog
          open={openDeleteUser}
          setOpen={setOpenDeleteUser}
          subtitle="¿Desea eliminar este usuario?"
          send={() => handleDelete(deleteUserId)}
        />
        


    </div>
  );
};