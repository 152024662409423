import {
  Button,
  createFilterOptions,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";
import { ArrowBack, Save } from "@mui/icons-material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {ProjectConfig} from "Global";
import ProductForm from "./ProductForm";
import SideDrawer from "elements/SideDrawer";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddIcon from '@mui/icons-material/Add';
import { display } from "@mui/system";

const ProductEditor = (props: any) => {

  const {
    setLoading,
    is_child = false,
  } = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const navigate = useNavigate();
  let { product_id, products_group_id } = useParams();
  const [category_list, setCategoryList] = useState<any[]>([]);
  


  const arrayProducts = [
    {
      product_id: "null",
      product_name: "",
      shofi_code: "",
      supplier_code: "",
      category: "",
      category_id: 1,
      product_stock: "",
      minimum_stock: "",
      supplier_id: "",
      supplier_name: "",
      product_description: "",
      unit_price: 0,
      product_status: "",
      folder_id: 0,
    },
  ];

  const formValuesDefault = {
    products_group_id: "null",
    products_group_name: "",
    products_group_description: "",
    products_group_category: "",
    products: arrayProducts,
    is_group: false,
  };


  const [formValues, setFormValues] = useState<any>(formValuesDefault);


  /* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
  interface ISupplierList {
    inputValue?: string;
    supplier_id?: number;
    name: string;
  }
  const filter = createFilterOptions<ISupplierList>();

  //setSupplierList
  const [supplier_list, setSupplierList] = useState<any[]>([]);
  

  useEffect(() => {
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "supplier_list",
        },
      })
      .then((response) => {
        setSupplierList(response.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);
  /* ================================================= 
    ===================================================*/


  /*====================================
  GET PRODUCT 
  =====================================*/
    useEffect(() => {
      if (product_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "product_details",
              product_id: [product_id],
            },
          })
          .then((response) => {
            
            console.log(response.data);
            //UPDATE ONLY PRODUCTS
            setFormValues({ ...formValues, products: response.data });
            
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    }, []);




  /*====================================
  GET GROUP 
  =====================================*/
  useEffect(() => {
    if (products_group_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "products_group_details",
            products_group_id: products_group_id,
          },
        })
        .then((response) => {
          console.log(response.data);
          //UPDATE ALL VALUES
          setFormValues({ ...response.data });
          
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  



  /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {
  

    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_product",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log(response.data); 

        if(formValues.is_group){
          navigate(`/inventory/group/${response.data.products_group_id}`);
          window.location.href = "/inventory/group/" + response.data.products_group_id; 
          //navigate(`/inventory/group/${response.data.products_group_id}`);
        }
        else{
          navigate(`/inventory/edit/${response.data.product_id}`);
          window.location.href = "/inventory/edit/" + response.data.product_id;
          //navigate(`/inventory/edit/${response.data.product_id}`);
        }
        //navigate(-1);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const updateProduct = (product: any, index: any) => {

    const products = [...formValues.products];
    products[index] = product;
    setFormValues({ ...formValues, products });
  };




  /*====================================================
    DRAWER
    ==================================================== */
    const [drawerOpen, setDrawerOpen] = useState(false);

    
    const ListItemIconStyles = {
        minWidth: 0,
        mr: drawerOpen ? 3 : "auto",
        justifyContent: "center",
    };

    const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setDrawerOpen(false);
      };
    /*====================================================
    END DRAWER
    ==================================================== */

    const handleFormChange = (e: { target: { name: any; value: any } }) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    };

    //HANDLE SELECT PRODUCT CATEGORY
    const handleGroupCategoryChange = (event: SelectChangeEvent) => {
      setFormValues({
        ...formValues,
        products_group_category: event.target.value,
      });
    };


  /*=======================================
   PUSH NEW PRODUCT TO PRODUCTS GROUP
  =======================================*/
  const pushArrayProducts = () => {
    const products = [...formValues.products];
    products.push({
      product_id: null,
      product_name: "",
      shofi_code: "",
      supplier_code: "",
      category: "",
      category_id: 0,
      product_stock: "",
      minimum_stock: "",
      supplier_id: "",
      supplier_name: "",
      product_description: "",
      unit_price: 0,
      product_status: "",
      folder_id: 0
    });
    setFormValues({ ...formValues, products });
  };
  /*=======================================
  =======================================*/

    


  /*=========================================================
  LOAD CATEGORY LIST            
  =========================================================*/

    useEffect(() => {
      
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'categories_list',
          full_list: true
        }
      })
        .then((response) => {
          setCategoryList(response.data.list)
          
        })
        .catch((err) => {
          console.log(err.data);
        })
        
    
  }, [])
  /*=========================================================*/

  return (
    <>
      <div id="CategoriesList" className="ModuleContainer" style={{maxWidth: '750px'}}>
        <div className="ModuleWrapper">
        { !is_child &&
          (
          <div className="boxContainerHeader" style={{maxWidth: '680px'}}>
            <div className="boxContainerTitle">
              <h2 className="h2_title">Categoria</h2>
              <span className="h2_subtitle">Editar</span>
            </div>

            <div className="boxContainerActions">
              <Button
                onClick={() =>
                  navigate("/inventory", {replace: true})}
                variant="outlined"
                className="button_2"
                startIcon={<ArrowBack />}
              >
                Volver
              </Button>
            </div>
          </div>
          )}
      <div className="contentWithMiniDrawer">
      
      <div className="products_area">
        {/* <Button
          onClick={() => navigate("/inventory")}
          variant="outlined"
          className="button_2"
        >
          Volver a la lista de productos
        </Button> */}
        {formValues.products.map((product: any, index: any) => {

          return(
            <ProductForm
              productValues = {product}
              updateProduct = {updateProduct}
              supplier_list = {supplier_list}
              index = {index}
              key = {index}
              is_group = {formValues.is_group}
              category_list = {category_list}
            />
        )})}

          {formValues.is_group && (
            <div className='addButtonCointainer'>
              <IconButton className="item_add_button" color="primary" aria-label="add-item" size="small" onClick={()=>{
                  pushArrayProducts();
                }}>
                <AddIcon />
              </IconButton>
            </div>
          )}
      </div>
      
      
      <SideDrawer
          drawerOpen={drawerOpen}
          label = "Producto"
          handleDrawerOpen = {handleDrawerOpen}
          handleDrawerClose = {handleDrawerClose}
          key = "sideDrawer"
      >
        <div className="drawer_in">
            <div className="drawer_content" style={{ opacity: drawerOpen ? 1 : 0 }}>
              <div className="sidebar_body">
                <h2>Configuración de grupo</h2>
                {!formValues.is_group ? (
                  <Fragment>
                    <span>Estas editando un producto individual</span>
                    <Button
                      onClick={() => setFormValues({ ...formValues, is_group: true })}
                      variant="outlined"
                      className="button_2"
                    >
                      Crear un grupo
                    </Button>
                  </Fragment>
                )
                :
                (
                  <Fragment>
                    <span>Estas editando un grupo de productos</span>
                    <TextField
                      id="products_group_name"
                      name="products_group_name"
                      label="Group Name"
                      type="text"
                      required
                      fullWidth
                      value={formValues.products_group_name}
                      onChange={handleFormChange}
                    />
                    <TextField
                      id="products_group_description"
                      name="products_group_description"
                      label="Descripción del grupo"
                      multiline
                      type="text"
                      required
                      fullWidth
                      value={formValues.products_group_description}
                      onChange={handleFormChange}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="lbl_product_category">Group Category</InputLabel>
                      <Select
                        labelId="products_group_category"
                        id="products_group_category"
                        value={formValues.products_group_category}
                        label="Categoría del grupo"
                        onChange={handleGroupCategoryChange}
                      >

                        {category_list.map((category: any, index: any) => {
                          return(
                            <MenuItem key={index} value={category.category_id}>{category.name}</MenuItem>
                          )
                        })
                        }
                       
                      </Select>
                    </FormControl>
                  </Fragment>
                )
              }
              
              </div>
            </div>
        </div>

        <List>
          <ListItem key="save" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                  sx={ListItemButtonStyles}
                  onClick={() => {
                    handleSubmit();
                  }}
              >
                  <ListItemIcon sx={ListItemIconStyles}>
                  <SaveOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                  primary="SAVE"
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
              </ListItemButton>
          </ListItem>
      </List>
      </SideDrawer>
      
        

      
    </div>
    </div>
    </div>
    </>
  );
};

export default ProductEditor;
