import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Badge, Button, Checkbox, Chip, CircularProgress, Dialog, DialogContent, Drawer, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AddWorkstation from '../Workstations/Utils/AddWorkstation';
import Cookies from 'universal-cookie';
import AlertDialog from 'elements/AlertDialog';
import AddProductInvitation from '../Workstations/Utils/AddProductInvitation';
import AddIcon from '@mui/icons-material/Add';
import ListOrders from './ListOrders';
import ServiceList from './ServiceList';

import 'assets/css/main.css';

const cookies = new Cookies();

const serviceDefault = {
  tracking_number: '',
  comments: '',
}

export default function WorkstationsOrders(props: any) {

  const {setLoading} = props;
  

    useEffect(() => {
      setLoading(false);
    }, []);

  
  const [listSend, setListSend] = useState<any[]>([]);
  const [listPickup, setListPickup] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);

  const currentPage= "1";

  const [page, setPage] = useState(currentPage);

  const [checkedList, setCheckedList] = useState<any[]>([]);
  
  const [serviceType, setServiceType] = useState(0);






  



  /*=========================================================
  LOAD LIST            
  =========================================================*/

//WHEN PAGE CHANGES 
useEffect(() => {
  loadList()
}, [page])

//LOAD LIST 
const loadList = () => {
  setLoading(true)
  axios.get(ProjectConfig.api_url,
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'orders_list',
        params: {
          page: page,
        }
      }
    })
      .then((response) => {
        console.log("res",response.data);
        setListSend(response.data.list_send)
        setListPickup(response.data.list_pickup)
        setLoading(false)
         
      })
      .catch((err) => {
        console.log(err.data);
      })
      
      setForceUpdate(!forceUpdate);
}

const [allCheckedSend, setAllCheckedSend] = useState(false);
const [allCheckedPickup, setAllCheckedPickup] = useState(false);

const [openServiceEditor, setOpenServiceEditor] = useState(false);

  //when close AddProductInvitation uncheck all rows
  useEffect(() => {
    if(!openServiceEditor){
      let newList = listSend;
      newList.forEach((item: any) => {
        item.checked = false;
        item.products_list.forEach((product:any) => {
          product.checked = false;
        })
      });
      setListSend(newList);

      newList = listPickup;
      newList.forEach((item: any) => {
        item.checked = false;
        item.products_list.forEach((product:any) => {
          product.checked = false;
        })
      });
      setListPickup(newList);


      setCheckedList([]);

      setAllCheckedSend(false);
      setAllCheckedPickup(false);
    }
  }, [openServiceEditor]);
  

/*=========================================================*/



const [alertOpen, setAlertOpen] = useState(false);

const createService = () => {
  
  axios.post(ProjectConfig.api_url,null,
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'create_service',
        params: {
          products_list: checkedList,
          service_type: serviceType,
        }
      }

    })
      .then((response) => {
        console.log("create",response.data);
        setOpenServiceEditor(false);
        loadList();
      })
      .catch((err) => {
        console.log(err.data);
      })
      


}



  const [forceUpdate, setForceUpdate] = useState(false);

  return (
    <>
    <div className='flexModulesColumn'>
      <div id='OrdersList' className='ModuleContainer' style={{display: listSend.length > 0 || listPickup.length > 0 ? 'block' : 'none', width: '100%'}}> 
        <div className='ModuleWrapper'>
          <div id="userWorkstationsHeader" className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Ordenes</h2>
              <span className="h2_subtitle">Lista de pedidos de productos para puestos</span>
            </div>
            
            <div className="boxContainerActions">
            
            </div>
            
          </div>

          <div id='OrdersListIn'>
            <Box id='orders_list' title="" subtitle=""  className="box100">
              {listSend.length > 0 && (
              <>
              <h2>Productos por enviar</h2>
                
              <ListOrders 
                setLoading={setLoading} 
                list={listSend} 
                setList={setListSend} 
                oppositeList={listPickup}
                setOppositeList={setListPickup}
                type={1}  
                checkedList={checkedList} 
                setCheckedList={setCheckedList} 
                setServiceType={setServiceType}
                allChecked={allCheckedSend}
                setAllChecked={setAllCheckedSend}
                setOppositeAllChecked={setAllCheckedPickup}
              />
              </>
              )}
              
              {listPickup.length > 0 && (
              <>
                <h2>Productos por retirar</h2>
                <ListOrders 
                  setLoading={setLoading} 
                  list={listPickup} 
                  setList={setListPickup} 
                  oppositeList={listSend}
                  setOppositeList={setListSend}
                  type={2} 
                  checkedList={checkedList} 
                  setCheckedList={setCheckedList}
                  setServiceType={setServiceType}
                  allChecked={allCheckedPickup}
                  setAllChecked={setAllCheckedPickup}
                  setOppositeAllChecked={setAllCheckedSend}
                />
              </>
              )}
            </Box>
          </div>
        </div>
      </div>

      <ServiceList setLoading={setLoading} forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} />
    </div>



    <Dialog
      open={openServiceEditor}
      onClose={() => {setOpenServiceEditor(false)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="forced_box_editor">
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>
                Usuario
              </TableCell>
              <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>
                Productos
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {/* show USER AND PRODUCTS CHECKED */}
            {listSend.map((item:any, index:any) => {
              return(
                <TableRow key={index}>
                  {item.checked &&
                  <>
                    <TableCell>
                      <div className='tdwimg'>
                        <AppAvatar name={item.name} image="" />
                        <div className='flexTd'>
                          <span className='flexTd_bold'>{item.name} {item.lastname}</span>
                          <span className='flexTd_normal'>{item.city_name} - {item.address}</span>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>

                      <div className='flex_products'>
                        {item.products_list.map((product:any, product_index:any) => {
                          return(
                            product.checked && (
                              <div className='flexRow' key={product_index}>
                                <AppAvatar size="30" name={product.product_name} image={product.product_image} />
                                <span className='flexTd_normal'>{product.product_name}</span>
                                
                              </div>
                            )
                          )
                        })}
                      </div>
                    </TableCell>
                  </>
                  }
                </TableRow>
              )
            })}

            {listPickup.map((item:any, index:any) => {
              return(
                <TableRow key={index}>
                  {item.checked &&
                  <>
                    <TableCell>
                      <div className='tdwimg'>
                        <AppAvatar name={item.name} image="" />
                        <div className='flexTd'>
                          <span className='flexTd_bold'>{item.name} {item.lastname}</span>
                          <span className='flexTd_normal'>{item.city_name} - {item.address}</span>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>

                      <div className='flex_products'>
                        {item.products_list.map((product:any, product_index:any) => {
                          return(
                            product.checked && (
                              <div className='flexRow' key={product_index}>
                                <AppAvatar size="30" name={product.product_name} image={product.product_image} />
                                <span className='flexTd_normal'>{product.product_name}</span>
                                
                              </div>
                            )
                          )
                        })}
                      </div>
                    </TableCell>
                  </>
                  }
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        



          {/* BUTTONS */}
          <div className="buttons">
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {setOpenServiceEditor(false)}}
            >
                Cancelar
            </Button>

            <Button 
                variant="contained" 
                startIcon={<AddIcon />}
                onClick={
                    () => {
                      setAlertOpen(true);
                    }
                }
                >
                Crear servicio
            </Button>
        </div>

      </DialogContent>
    </Dialog>


    <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={createService}
        subtitle="¿Estás seguro de eliminar este puesto?"
      />




      <div id='masive_actions' className='bottom_fixed_box' style={{display: checkedList.length > 0 ? 'flex' : 'none'}}>
        {checkedList.length} productos seleccionados 
        <Button variant="contained" color="primary" onClick={() => {
          console.log("listSend",listSend);
          setOpenServiceEditor(true)
        }}>
          Crear servicio
        </Button>
      </div>

      

    </>
  );
};

