import { AddTask } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import { Scheduler } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { ProjectConfig } from 'Global';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ScheduleDetails from './ScheduleDetails';

const cookies = new Cookies();

export default function ScheduleView (props: any) {

  const {setLoading, isMobile} = props;

  useEffect(() => {setLoading(false);}, []);

  const [data, setData] = useState<any>([]);
  const [selectedTaskId, setSelectedTaskId] = useState<any>(null);


  /*=========================================================
  LOAD TASKS            
  =========================================================*/
  useEffect(() => {
    console.log(cookies.get('company_id'))
    
    axios.get(ProjectConfig.api_url,
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
        'COMPANYID': cookies.get('company_id'),
      },
      params: {
        request: 'get_schedule',
        params: {
          month: '2023-03',
        }
      }
    })
      .then((response) => {
        console.log("RES ",response.data); 
        setData(response.data);
         
      })
      .catch((err) => {
        console.log(err.data);
      })
  
}, [])
/*=========================================================*/



  

  
  const onAppointmentClick = (e: any) => {
    console.log("click", e.appointmentData);
    e.cancel = false;
    setActiveItem(e.appointmentData);
    setOpen(true);
  }


  useEffect (() => {
    //scheduler 
  }, [data])


  const currentDate = new Date();
  const views = ['week', 'month'];

  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  return (
    <div>
      <h1>Schedule</h1>
      
      <Scheduler
        timeZone="America/Nassau"
        dataSource={data}
        views={['month']}
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        height={isMobile ? 500 : 800}
        startDayHour={0}
        showAllDayPanel={false}
        editing={false}
        onAppointmentClick={onAppointmentClick}
        onAppointmentDblClick={onAppointmentClick}
        adaptivityEnabled={true}
      />

      <ScheduleDetails
        open={open}
        setOpen={setOpen}
        setLoading={setLoading}
        data={activeItem}
      />

    

    </div>
  );
}