import { Button, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";


const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')


export default withSnackbar(
  function AdminEditor(props : any) {

    const {setLoading} = props;

          /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let {user_id } = useParams();
  user_id = props.account_user_id ? props.account_user_id : user_id;
  /*=======================================
  =======================================*/


  const navigate = useNavigate();
  const defaultValues: any = {
    user_id: user_id? user_id : "null",
    name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    is_active: true,
  };
  
  const [formValues, setFormValues] = useState(defaultValues)
  const [roles_list, setRolesList] = useState<any []>([]);







  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };



  const handleSubmit = () => {
    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'add_edit_admin',
          formdata: formValues,
        }
      })
      .then((response) => {
        console.log(response); 

        if(response.data){
          enqueueSnackbar('Administrador guardado con éxito', { variant: 'success' });
          setLoading(true)
          navigate(-1)
        }else{
          enqueueSnackbar('Error al guardar el administrador', { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.data);
      });    
  };



  /**/

  useEffect(() => {
    if (user_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "admin_details",
            user_id: user_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);


  return (
      <>
        <div id="QuestionList" className="ModuleContainer" style={{maxWidth:'502px'}}>
            <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                        <h2 className="h2_title">Administrador</h2>
                        <span className="h2_subtitle">Editar</span>
                    </div>

                    <div className="boxContainerActions">
                      <Button
                          onClick={() => navigate('/administrators',{replace: true})}
                          variant="outlined"
                          className="button_2"
                      >
                        Cancelar
                      </Button>

                      <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
                    </div>
                </div>

                <div id="userForm">
                  <div className="userForm_right">
                    <Box id="user_editor">
                      <div className='input_group'>
                          <h2>Información Personal</h2>
                          <div className='input_group'>
                              <div className='input_row'>
                              <TextField 
                                id="name"
                                name="name"
                                label="Nombres"
                                type="text"
                                required
                                fullWidth
                                value={formValues.name}
                                onChange={handleInputChange}
                              />

                              <TextField
                                id="last_name"
                                name="last_name"
                                label="Apellidos"
                                type="text"
                                required
                                fullWidth
                                value={formValues.last_name}
                                onChange={handleInputChange}
                              />
                            </div>

                            <TextField
                              id="phone"
                              name="phone"
                              label="Teléfono"
                              type="text"
                              required
                              fullWidth
                              value={formValues.phone}
                              onChange={handleInputChange}
                            />

                          </div>

                          <div className='input_group'>
                            <h2>Información de sesión</h2>
                            <div className="input_row">
                              <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="text"
                                required
                                fullWidth
                                value={formValues.email}
                                onChange={handleInputChange}
                              />

                              <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                required
                                fullWidth
                                value={formValues.password}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>


                        <FormControlLabel
                          checked={formValues.is_active}
                          
                          control={
                            <IOSSwitch 
                              sx={{ m: 1 }}
                              onChange={(e) => {
                                setFormValues({
                                  ...formValues,
                                  ["is_active"]: e.target.checked
                                });
                              }
                              }
                            />
                          }
                          label="Activo"
                        />
                      </div>
                    </Box>
                  </div>
              </div>
            </div>
        </div>
      </>
  );
})
