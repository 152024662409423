import { Button, CSSObject, Dialog, DialogContent, DialogContentText, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, styled, Switch, SwitchProps, TextField, Theme, Typography } from "@mui/material"
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";

//ICONS
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { FolderCopyOutlined } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import MuiDrawer from "@mui/material/Drawer";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const drawerWidth = 300;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



interface Props {
  project_id?: number,
  client_id?: number,
  folder_id?: number,
  view_type?: string,
  hide_folder_button?: boolean,
}    


 const FilesBrowser = (props: Props) => {
  const navigate = useNavigate();

  /*=======================================
    GET ID FROM URL
  =======================================*/
  let { project_id } = useParams();

  /*=======================================
  =======================================*/

  /*====================================================
    DRAWER
    ==================================================== */
    const [drawerOpen, setDrawerOpen] = useState(false);

    
    const ListItemIconStyles = {
        minWidth: 0,
        mr: drawerOpen ? 3 : "auto",
        justifyContent: "center",
    };

    const ListItemButtonStyles = {
    minHeight: 48,
    justifyContent: drawerOpen ? "initial" : "center",
    px: 2.5,
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setDrawerOpen(false);
      };
    /*====================================================
    END DRAWER
    ==================================================== */




  const [loading, setLoading] = useState(false); 
  const [fileList, setFileList] = useState<any>([])
  const [viewType, setViewType] = useState(props.view_type? props.view_type : "list");
  const [openLightBox, setOpenLightBox] = useState(false);
  const [imageLightbox, setImageLightBox] = useState("");
  


  // GET FILE LIST
    useEffect(() => {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "file_list",
              parent_folder_id: props.folder_id? props.folder_id : 1,
            },
          })
          .then((response) => {
            console.log(response.data);
            const newFileList = [...fileList];
            newFileList[0] = response.data;
            setFileList(newFileList);
          })
          .catch((err) => {
            console.log(err.data);
          });
    }, []);
    //===========================================================
    

    const handleFolderClick = (folder_id: number, folderLevel: number) => {
      axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "file_list",
          project_id: project_id,
          parent_folder_id: folder_id,
        },
      })
      .then((response) => {
        
        //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
        const newFileList = [...fileList];
        newFileList[folderLevel+1] = response.data;

        setFileList(newFileList);

        //DELETE index and next indexes
        const newFileList2 = [...newFileList];
        
        newFileList2.splice(folderLevel+2, newFileList2.length);
        setFileList(newFileList2);
        


      })
      .catch((err) => {
        console.log(err.data);
      });
    }



    const handleFileClick = (file_id: number, folderLevel: number) => {
      console.log("file_id",file_id);
    }

    
 /*====================================
  SUBMIT 
  =====================================*/
  const handleSubmit = () => {


    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_client",
          //formdata: formValues,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/

  const [openDialogCreateFolder, setOpenDialogCreateFolder] = useState(false);

  const handleCloseDialogCreateFolder = () => {
    setOpenDialogCreateFolder(false);
  };
  


  /*====================================
  CREATE FOLDER FUNCTION
  =====================================*/
  const handleCreateFolder = () => {
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "create_folder",
          formdata: newFolderFormValues,
          project_id: project_id,
        },
      })
      .then((response) => {
        setOpenDialogCreateFolder(false);
        console.log(response.data);
        //REFRESH FILE LIST
        //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
        const newFileList = [...fileList];
        newFileList[newFolderFormValues.folderLevel] = response.data.folder_content;
        setFileList(newFileList);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }
  /*====================================
  =====================================*/



  /*====================================
  UPLOAD FILE
  =====================================*/
  const handleUploadFiles = (selected_files: any, folder_id: any, folderLevel: number) => {
    console.log("selected_files",selected_files);
    
    //MULTIPLE FILES UPLOAD
    const formData = new FormData();
    for (let i = 0; i < selected_files.length; i++) {
      formData.append("files["+i+"]", selected_files[i]);
    }
    formData.append("request", "upload_files");
    formData.append("folder_id", folder_id);

    //add className to the progress bar
    const progressBar = document.getElementById("progressBar_"+folderLevel);
    //add className to the progress bar
    progressBar?.classList.add("show_progress_bar");
    



    //UPLOAD FILES AND SHOW PROGRESS
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent:any) => {
          setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );

        },
      })
      .then((response) => {
        console.log(response);
        //REFRESH FILE LIST
        //ADD DATA LIKE A CHILD OF THE PARENT FOLDER
        const newFileList = [...fileList];
        newFileList[folderLevel] = response.data.file_list;
        setFileList(newFileList);
        progressBar?.classList.remove("show_progress_bar");
        setProgress(0);
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
    

  /*====================================
  =====================================*/



  const showDialogCreateFolder = (parent_folder_id: any, folderLevel: any) => {
    setNewFolderFormValues({
      folder_name: "",
      parent_folder_id: parent_folder_id,
      folder_locked: 0,
      folderLevel: folderLevel,
    });
    setOpenDialogCreateFolder(true);
  }

  

  const newFolderValues = {
    folder_name: "",
    parent_folder_id: 1,
    folder_locked: 0,
    folderLevel: 0,
  };

  console.log("fileList",fileList);

  const [newFolderFormValues, setNewFolderFormValues] = useState(newFolderValues);

  const [progress, setProgress] = useState(0);
  return (
    <div className="contentWithMiniDrawer">    
      <div id="filesBrowser">

        {fileList.map((element: any, folderLevel: any) => {
          
          return(
            <Box className="box_file_browser" subtitle="Edit" id={"level_"+folderLevel} key={folderLevel}>
              <LinearProgress variant="determinate" value={progress} id={"progressBar_"+folderLevel} className="progressBar"/>
              {/* ON DRAG ADD CLASS */}
              <div className="dropzone" onDrop={(e) => {
                e.preventDefault();
                  handleUploadFiles(e.dataTransfer.files, element.level_folder_id, folderLevel)
                  e.currentTarget.classList.remove("dropzone_active");
                }
                } 
                onDragOver={
                  (e) => {
                    e.preventDefault();
                    e.currentTarget.classList.add("dropzone_active");
                  }
                  
                }
                onDragLeave={
                  (e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove("dropzone_active");
                  }
                }
                >
                
                <div className="level_folder_name">{element.level_folder_name}
                  <div className="addButton_container">
                    <label>
                      <IconButton 
                        aria-label="delete"
                        size="small"
                        className="addFile_button"
                        component="span"
                      >
                        <NoteAddIcon fontSize="small" />
                        <input 
                          type="file"
                          multiple 
                          hidden 
                          onChange={(e) => {
                            handleUploadFiles(
                                e.target.files,
                                element.level_folder_id,
                                folderLevel
                                );
                          }}
                        />
                        
                      </IconButton>
                    </label>

  
                    {!props.hide_folder_button && (
                    <IconButton 
                      aria-label="delete"
                      size="small"
                      className="addFolder_button"
                      onClick={() => showDialogCreateFolder(element.level_folder_id, folderLevel)}

                    >
                      <CreateNewFolderIcon fontSize="small" />
                      
                    </IconButton>
                    )}
                  </div>
                </div>
                <div className="file_list">
                  {!props.folder_id && (
                  
                    (element.folders.map((folder: any) => {
                      return(
                        <Button 
                          key={folder.folder_id}
                          className="fs_row folder" 
                          onClick={() => handleFolderClick(folder.folder_id,folderLevel)}>
                          <FolderIcon />
                          <span>{folder.folder_name}</span>
                        </Button>
                      )
                    }))
                  
                  )}

                  {(element.files.map((file: any) => {
                    const file_type = (file.file_extension === "jpg" || file.file_extension === "png" || file.file_extension === "jpeg" || file.file_extension === "gif") ? "image" : file.file_extension;
                    
                    return(
                      <Button 
                        key={file.file_id}
                        className="fs_row file" 
                        onClick={() => {
                          if(file_type === "image" && props.folder_id){
                            setOpenLightBox(true)
                            setImageLightBox(file.file_src)
                          }
                          else{
                            handleFileClick(file.file_id,folderLevel)
                          }
                      }}

                      >
                        
                        {viewType === "list" &&  
                          <Fragment>
                            <InsertDriveFileOutlinedIcon />
                            <span>{file.file_name}.{file.file_extension}</span> 
                          </Fragment>
                        }
                        {viewType === "thumbnail" && (
                          file_type === "image" ? (
                            <div className="file_thumnbnail_image">
                              <img src={file.file_src} />
                              <span className='thumbnail_name'>{file.file_name}.{file.file_extension}</span>
                            </div>
                          ) : (
                          <div className="file_thumnbnail">
                            <InsertDriveFileOutlinedIcon />
                            <span>{file.file_name}.{file.file_extension}</span>
                          </div>
                          )

                        
                        )}
                      </Button>
                    )
                  }))}
                </div>
              </div>
            </Box>
            
          )
        })}
        
        <Dialog 
           
          id="imageLightbox" 
          open={openLightBox} 
          onClose={() => setOpenLightBox(false)}
          maxWidth="lg"
        >


            <img src={imageLightbox} />
        </Dialog>


        <Dialog open={openDialogCreateFolder} onClose={handleCloseDialogCreateFolder}>
          <DialogContent id="estimate_configuration" className="forced_box_editor">
            <div className="DialogHeader">
              <h2>CREATE FOLDER</h2>
              <span>Complete the form to create a new folder</span>
            </div>
            <TextField
              id="folder_name"
              name="folder_name"
              label="Folder Name"
              type="text"
              required
              fullWidth
              value={newFolderFormValues.folder_name}
              onChange={
                (e) => setNewFolderFormValues({ ...newFolderFormValues, folder_name: e.target.value })
              }
            />
            <div className="buttons">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseDialogCreateFolder}
              >
                Cancel
              </Button>

              <Button 
                variant="contained" 
                startIcon={<FolderCopyOutlined />}
                onClick={() => {
                  handleCreateFolder();
                }}>
                Create Folder
              </Button>
            </div>
          </DialogContent>
        </Dialog>

      </div>

      {!props.folder_id &&(
      <Drawer
        variant="permanent"
        open={drawerOpen}
        anchor="right"
        className="miniDrawer"
        PaperProps={{
        style: {
            paddingTop: "55px",
            boxShadow: "0 0 4px #00000026",
            border: "none",
        },
        }}
      >
        <ListItem key="drawerOpenBtn" disablePadding sx={{ display: "block" }}>
        <ListItemButton
            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
            sx={ListItemButtonStyles}
        >
            <ListItemIcon
            sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : "auto",
                justifyContent: "center",
            }}
            >
            {!drawerOpen ? <MenuIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
            <ListItemText>
            <h2 style={{ opacity: drawerOpen ? 1 : 0 }}>QUOTE SUMMARY</h2>
            </ListItemText>
        </ListItemButton>
        </ListItem>

        <div className="drawer_in">
            <div className="drawer_content" style={{ opacity: drawerOpen ? 1 : 0 }}>
                <div className="box_header">
                    <span className="subtitle">
                        
                    </span>
                </div>
            </div>

            <List>

           
                
                <ListItem key="save" disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                      sx={ListItemButtonStyles}
                      onClick={() => {
                      handleSubmit();
                      }}
                  >
                      <ListItemIcon sx={ListItemIconStyles}>
                      <SaveOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                      primary="SAVE"
                      sx={{ opacity: drawerOpen ? 1 : 0 }}
                      />
                  </ListItemButton>
                </ListItem>

  
            </List>
        </div>
    
      </Drawer>
      )}
    </div>
  );
};


export default FilesBrowser;


