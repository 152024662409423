import { useEffect, useState } from 'react'
import { Autocomplete, Button, Dialog, DialogContent, DialogContentText, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';




import SendIcon from '@mui/icons-material/Send';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar, withSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';
import AutocompleteKits from 'elements/AutocompleteKits';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import AppAvatar from 'elements/AppAvatar';
import CloseIcon from '@mui/icons-material/Close';
import AutocompleteForms from 'elements/AutocompleteForms';

const defaultData = {
    invitation_type: {
        error: false,
        value: "1",
    },
    kit_id: {
        error: false,
        value: '',
    },
    form_id: {
        error: false,
        value: '',
    },
    bugget: {
        error: false,
        value: '',
    },
    start_date: {
        error: false,
        value: null,
    },
}



export default withSnackbar(
    function AddProductInvitation(props: any) {

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const {open, send, setOpen, checkedList, setCheckedList, setLoading, list, setList} = props;

    useEffect(() => {
      setLoading(false);
    }, []);

    //IF CLOSE DIALOG, RESET DATA
    useEffect(() => {
        if(!open){
            setInvitation(defaultData);
        }
    }, [open]);
    


    const [invitation, setInvitation] = useState(defaultData);

    const checkForm = () => {
        
        let errors = false;
        if(invitation.invitation_type.value == "1" && invitation.kit_id.value == ""){
            setInvitation({...invitation, kit_id: {error: true, value: invitation.kit_id.value}})
            errors = true;
        
        }else if(invitation.invitation_type.value == "2" && invitation.bugget.value == ""){
            setInvitation({...invitation, bugget: {error: true, value: invitation.bugget.value}})
            errors = true;

        }else if(invitation.invitation_type.value == "3" && invitation.form_id.value == ""){
            setInvitation({...invitation, form_id: {error: true, value: invitation.form_id.value}})
            errors = true;
        }
        
        if((invitation.invitation_type.value == "1" || invitation.invitation_type.value == "2") && invitation.start_date.value == null){
            setInvitation({...invitation, start_date: {error: true, value: invitation.start_date.value}})
            errors = true;
        }

        return errors;
    }
  
    const [alertOpen, setAlertOpen] = useState(false);

    // SEND INVITATIONS TO WORKSTATIONS LIST
    const sendInvitations = () => {
        setOpen(false);
        send(invitation)
    }


    const handleKitInputChange = (e: any) => {
        setInvitation({...invitation, kit_id: {error: false, value: e.target.value}})
    }

    const handleFormInputChange = (e: any) => {
        setInvitation({...invitation, form_id: {error: false, value: e.target.value}})
    }


    //IF CHECKEDLIST IS EMPTY, CLOSE DIALOG
    useEffect(() => {
        if(checkedList.length == 0){
            setOpen(false);
        }
    }, [checkedList]);
    


  return (
    <>
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            
            <DialogContent className="forced_box_editor">
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Nuevos puestos</h2>
                    <DialogContentText>
                        Por favor, ingresa los datos de los nuevos puestos.
                    </DialogContentText>
                </div>

                <h3>Enviar invitación a: </h3>

                <div id='checkedWorkstations'>
                    {checkedList.map((item: any, index: number) => (
                        <div className="checkedWorkstation" key={index}>

                            <AppAvatar name={item.name} image={item.image} size='small' className='checkedWorkstation_avatar' />
                            <div>
                                
                                <div className='checkedWorkstation_name'>
                                    {item.name} {item.lastname}
                                </div>
                                
                                <div className='checkedWorkstation_email'>
                                    {item.email}
                                </div>
                            </div>
                            <div className='icon_button_container'>
                                <IconButton 
                                    size='small'
                                    onClick={() => {
                                    let list = [...checkedList]
                                    list.splice(index, 1);
                                    setCheckedList(list);
                                    }}
                                >
                                    <CloseIcon fontSize='small'/>
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>
                
                <h3>Invitación</h3>
                <form className="form">

                    {/* select KIT / PRESUPUESTO  */}
                    <FormControl fullWidth>
                        <InputLabel id="lbl_product_category">Tipo de invitación</InputLabel>
                            <Select
                            labelId="invitation_type"
                            id="invitation_type"
                            value={invitation.invitation_type.value}
                            label="Tipo de invitación"
                            onChange={(e) => {
                                setInvitation({...invitation, invitation_type: {error: false, value: e.target.value}})
                            }}
                            >
                            <MenuItem value={1}>Kit</MenuItem>
                            <MenuItem value={2}>Presupuesto</MenuItem>
                            <MenuItem value={3}>Formulario</MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{display: invitation.invitation_type.value == "1" ? 'block' : 'none'}}> 
                    <AutocompleteKits 
                        onChange={handleKitInputChange}
                        value={invitation.kit_id.value}
                        error={invitation.kit_id.error}
                    />
                    </div>

                    
                    <div style={{display: invitation.invitation_type.value == "2" ? 'block' : 'none'}}> 
                    <TextField
                        helperText={invitation.bugget.error ? "Este campo es obligatorio" : ""}
                        fullWidth
                        id="bugget"
                        label="Presupuesto"
                        type="number"
                        value={invitation.bugget.value}
                        onChange={(e) => { 
                            setInvitation({...invitation, bugget: {error: false, value: e.target.value}})
                        }}
                        error={invitation.bugget.error}
                    />
                    </div>

                    <div style={{display: invitation.invitation_type.value == "3" ? 'block' : 'none'}}> 
                    <AutocompleteForms
                        onChange={handleFormInputChange} 
                        value={invitation.form_id.value}
                        error={invitation.form_id.error}
                    />
                    </div>

                    {/* START DATE */}
                    { (invitation.invitation_type.value == "1" || invitation.invitation_type.value == "2") &&
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                        
                        label="Fecha de inicio"
                        inputFormat="MMMM DD, yyyy"
                        value={invitation.start_date.value}
                        InputProps={{
                            endAdornment: (
                            <EventIcon />
                            ),
                        }}
                        showToolbar={false}
                        closeOnSelect 
                        onChange={(newValue:any) => {
                            const val = newValue? (newValue).format('YYYY-MM-DD') : '';
                            setInvitation({...invitation, start_date: {error: false, value: val}})
                        }}
                        renderInput={(params) => <TextField {...params} error={invitation.start_date.error} helperText={invitation.start_date.error ? "Este campo es obligatorio" : ""} />}
                        />
                    </LocalizationProvider>
                    }



                            
                </form>



                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>

                    <Button 
                        variant="contained" 
                        endIcon={<SendIcon />}
                        onClick={
                            () => {
                                if(!checkForm())
                                {
                                    setAlertOpen(true);
                                }
                                else{
                                    enqueueSnackbar('Errores en el formulario', {variant: 'error'});
                                }
                            }
                        }
                        >
                        Enviar invitación
                    </Button>
                </div>
            </DialogContent>

        </Dialog>


        {/* ALERT DIALOG */}
        <AlertDialog 
            open={alertOpen} 
            setOpen={setAlertOpen} 
            send={sendInvitations} 
            subtitle="¿Estás seguro que deseas enviar las invitaciones?"/>
        
    </>
  );
});

