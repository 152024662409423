import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';

import Box from 'elements/Box';
import { Chip, Divider, Typography } from '@mui/material';
import App from 'elements/HtmlEditor/App';
import { Person } from '@mui/icons-material';

const cookies = new Cookies();

export default function TicketsView(props: any) {
    const {
        tickets,
        isAdmin = false,
    } = props;

    return (
        <>
        {tickets.map((ticket: any) => {
            const isMine: boolean = ticket.user_id == cookies.get('user_id');
            const ownerDecision = (isAdmin ? ((ticket.isAdmin && isMine) ? 0 : 1) : ((!ticket.isAdmin && isMine) ? 1 : 0)); // 0 = admin, 1 = user

            return (
                <div className='ModuleWrapper' key={ticket.ticket_message_id}>
                    {ticket.ticket_message_id !== 'null' && (
                    <Box id="messageBox" className='box100' style={{padding: '20px 0px 35px', borderLeft: 'solid 2px' + (ownerDecision ? '#82b184' : '#67aaee')}}>
                        <div className="box_header" style={{display: 'flex', flexDirection: 'column', rowGap: '5px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '15px', borderBottom:'solid 1px #ddd'}}>
                            <div className="box_header_title" style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start', columnGap: '10px'}}>
                                <Person sx={{fontSize: '1.2rem', color: '#666'}} />
                                
                                <h2 className="card_title">{ticket.owner}</h2>
                            </div>
                            <div className="box_header_title" style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <Chip
                                    variant="outlined"
                                    label={
                                        
                                            ticket.isAdmin ? 'Administrador' : 'Usuario'
                                     
                                    }
                                    size="small"
                                    color={ownerDecision ? 'success' : 'primary'}
                                />
                                <Typography variant='caption'>
                                    {ticket.date.toString()}
                                </Typography>
                            </div>
                        </div>

                        <App
                            handleEditorChange={()=>{}}
                            content={ticket.message}
                            editable={false}
                        />
                    </Box>
                    )}
                </div>
            )
        })}
    </>
    )
}