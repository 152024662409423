/**
 * third-party dependencies
 */
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from '@mui/material';

/**
 * styles dependencies
 */
import '../styles/forms.css';
import '../../../assets/css/main.css';


/**
 * project dependencies
*/
import CardResultPie from 'components/Workstations/Utils/CardResultPie';
import { ProjectConfig } from 'Global';
import Box from 'elements/Box';

/**
 * read-only variables and interfaces
 */
const cookies = new Cookies();
interface Iform_results {
    form_id: number,
    form_name: string,
    total_results: number,
    results: {
        question: string,
        question_id: number,
        answers_labels: string[],
        answers_quantities: number[]
    }[],
};

const defaultValues: Iform_results = {
    form_id: 0,
    form_name: '',
    total_results: 0,
    results: [],
};

const options = {
    responsive: true,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
    },
};

export default function DialogResults(props: any) {
    const {
        handleClose,
        open,
        setOpen,
        selectedItem,
    } = props;

    const [results, setResults] = useState<Iform_results>({...defaultValues});

    useEffect(() => {
        if (selectedItem) {
            loadList();
        } else{
            setResults({...defaultValues});
        }
    }, [selectedItem, open]);

    const loadList = () => {

        // AXIOS GET
        axios.get(ProjectConfig.api_url, {
            headers: {
                'COMPANYID': cookies.get('COMPANYID'),
            },
            params: {
                request: "get_form_dashboard",
                params: {
                    form_id: selectedItem.form_id,
                },
            },
        })
        .then((response) => {
            console.log(response.data); 
            setResults({...response.data});
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
        });
    };

    function handleClickExport() {
        
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={(e, reason) => handleClose(reason, setOpen)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='lg'
        >
            <DialogContent
                className='forced_box_editor'
                sx={{ width: '100%', padding: '1rem', textAlign: 'center', fontSize: '1rem',minWidth: '800px', fontWeight: 'lighter',}}
            >
                <div className="dialog_header">
                    <h2 style={{marginBottom:'5px', marginTop: '10px',}} className='h2_title'>
                        {(selectedItem ?
                        selectedItem.form_name:
                        null)}
                    </h2>
                    <DialogContentText>
                        Resultados
                    </DialogContentText>
                </div>

                <div
                            style={{
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center',
                                height:'100%',
                            }}>
                            <h2 className='h2_title' style={{marginBottom:'0'}}>{ results.total_results }</h2>
                            <span className='formSmallMessage h2_subtitle'>Total de resultados</span>
                        </div>
                
                <div id="form_results">
                    
                    {results.results.map((result) => (
                        <CardResultPie
                        labels={result.answers_labels}
                        quantities={result.answers_quantities}
                        options={options}
                        title={result.question} />
                    ))}
                    
                </div>

                {/* BUTTONS */}
                <div className="buttons">
                    {/**
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handleClickExport()}
                    >
                        Exportar
                    </Button>
                    */}
                    
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => handleClose(null,setOpen)}
                    >
                        Cerrar
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};