import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import '../../../assets/css/main.css';

import ic_empty from 'icons/ic_empty.svg';
import Box from 'elements/Box';
import EmptyElement from 'elements/EmptyElement';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function CardResultPie(props: any) {
    const {
        labels,
        options,
        quantities,
        title,
    } = props;

    const data = {
        labels: labels,
        datasets: [
          {
            label: '# of Votes',
            data: quantities,
            backgroundColor: [
              
              "#1C72E2",
              "#87CFD4",
              "#7F2FF2",
              "#FFD62C",
              "#F9F7EF",
              "#000000",
              "#000000"
            ],
            borderWidth: 1,
          },
        ],
      };

    return (
        <Box>
            <span className='flex_title'>{title}</span>

            
            {(labels.length === 0) && 
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
                <EmptyElement
                    title='Sin datos'
                    subtitle='Aun no hay datos para mostrar'
                />
            </div>
                    
            }
            {(labels.length !== 0) && (
            <>
                <Pie
                    data={data}
                    options={options}
                    title={title}
                    style={{
                        maxWidth: '100%',
                        marginTop: '15px',
                        marginBottom: '15px',
                        padding: '15px',
                    }}
                />
                
                {labels.map((item: any, index: number) => (
                    <div className='result_row'>
                        <span>{labels[index]}</span>
                        <span>{quantities[index]}</span>
                    </div>
                ))}
            </>
            )}
        </Box>
    );
};