import { Button, createFilterOptions, Dialog, DialogContent, DialogContentText, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import ic_empty from 'icons/ic_empty.svg';
import Cookies from 'universal-cookie';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
const cookies = new Cookies();


export default function Store(props: any) {

    const {addProduct, setLoading, storeDialogOpen, setStoreDialogOpen} = props;

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);

    const [parent_category, setParentCategory] = useState(0);
    const [active_category_name, setActiveCategoryName] = useState("Categorías");


    /*=============================================
    CATEGORIES 
    =============================================*/

    useEffect(() => {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "categories_list",
            full_list: true,
          },
        })
        .then((response) => {
          setCategories(response.data.list);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }, []);
  


  /*=============================================
  PRODUCTS
  =============================================*/

  const openCategory = (category_id: any, category_name: any) => {
    setParentCategory(category_id);
    setActiveCategoryName(category_name);

    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn')
        },
        params: { 
          request: 'product_list',
          params: {
            category_id: category_id,
          }
        }
      })
        .then((response) => {
          setProducts(response.data.products);
           
        })
        .catch((err) => {
          console.log(err.data);
        })
  }




  const [productLoading, setProductLoading] = useState(false);


  const [productDetailDialogOpen, setProductDetailDialogOpen] = useState(false);
  const [productDetail, setProductDetail] = useState<any>({});
  const [activeImage, setActiveImage] = useState(0);
  
  const openProduct = (product_id: any) => {
    setProductLoading(true);
    setProductDetailDialogOpen(true);
    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn')
        },
        params: {
          request: 'product_details',
          product_id: [product_id], 
        }
      })
        .then((response) => {
          console.log("product",response.data);
          setProductDetail(response.data[0]);
          setProductLoading(false);
           
        }
      )
        .catch((err) => {
          console.log(err.data);
        }
      )
  }


  return (
    <>
      <Dialog id='kits_store' open={storeDialogOpen} onClose={() => setStoreDialogOpen(false)}
      aria-labelledby="form-dialog-title" maxWidth="md" >
        <DialogContent>
          <div id="kits_store_header">
            {/* icon button */}
            {parent_category != 0 ?
            <>
              <IconButton id="backToButton" onClick={() => openCategory(0, "")}>
                <ArrowBackIosIcon />
              </IconButton>
              <h2 className="category_title">{active_category_name}</h2>
            </>
            :
            <h2 className="category_title">Categorías</h2>
            }
            
          </div>

          <div id="kits_store_container">
            {parent_category == 0 ? 
              (
                <div id='categories_list'>
                  {categories.map((category: any, index: any) => {
                    return (
                      <Button 
                        //text color #ddd
                        sx={{ color: '#000' }}
                        onClick={() => openCategory(category.category_id, category.name)}
                        key={index}
                      >
                        <div className="category" key={index}>
                          <img src={category.image} alt=""/>
                          <h2 className="categoryTitle">{category.name}</h2>
                        </div>
                      </Button>
                    )
                  })}
                </div>
              )
              :
              (
                <div id='products_list'>

                  {products.map((product: any, index: any) => {
                    return (
                      <Button 
                        //text color #ddd
                        sx={{ color: '#000' }}
                        onClick={() => openProduct(product.product_id)}
                        key={index}
                        className="product_button"
                      >
                        <img src={product.image} alt=""/>
                        <h2>{product.product_name}</h2>
                      </Button>
                    )
                  })}

                </div>
              )
            }
          </div>

        </DialogContent>
      </Dialog>



      <Dialog 
        id='kits_store' 
        open={productDetailDialogOpen} 
        onClose={() => setProductDetailDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",  // Set your width here
            },
          },
        }}
      >
        <DialogContent  className="forced_box_editor">
          <div id="kits_store_header">
            {/* icon button */}
            <IconButton
              id="backToButton"
              onClick={() => {
                setProductDetailDialogOpen(false)
                setProductDetail({})
              }}>
              <ArrowBackIosIcon />
            </IconButton>
            <h2>{productDetail.product_name}</h2>
          </div>

          
          <div id="product_detail_container">

          <div id='containedLoader' className={!productLoading ? 'loaderHide' : ''}>
            <CircularProgress />
          </div>

            <div id="product_detail_images">
              <div id="product_detail_miniatures">
                {productDetail.images && productDetail.images.map((image: any, index: any) => {
                  return (
                    <Button
                      className="miniature_button"
                      onClick={() => setActiveImage(index)}
                      key={index}
                    >
                      <img src={image.image} alt=""/>
                    </Button>
                  )
                })}
                
              </div>
              <div id="product_detail_main_image">
                {productDetail.images &&
                <img src={productDetail.images[activeImage].image} alt=""/>
                }
              </div>
            </div>
            <div id="product_detail_info">
              
              <div className="product_detail_info_rows">
                <div className="product_detail_info_row">
                  <span className="textLabel">Precio:</span>
                  <span className="textValue">
                    $ {productDetail.prices && productDetail.prices[productDetail.contract_time].price .toLocaleString("es-CO")}
                  </span>
                </div>
              </div>

              {/* select contract time labeled VIgencia with options  1 = 6 meses, 2 = 12 meses */}
              <FormControl variant="outlined" className="select">
                <InputLabel id="demo-simple-select-outlined-label">Vigencia</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={productDetail.contract_time? productDetail.contract_time : 1}
                  onChange={(e) => setProductDetail({...productDetail, contract_time: e.target.value})}
                  label="Vigencia"
                >

                    <MenuItem value={1}>6 Meses</MenuItem>
                    <MenuItem value={2}>12 Meses</MenuItem>
                    <MenuItem value={3}>24 Meses</MenuItem>
                    <MenuItem value={4}>36 Meses</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                className="button_1"
                onClick={() => {
                  addProduct(productDetail)
                  setProductDetailDialogOpen(false);
                }}
              >
                Agregar Producto
              </Button>


            </div>
          </div>
          <div className="description">
            <h4>Descripción</h4>
            
            {/* descripcion lorem ipsum */}
            <p>{productDetail.product_description}</p>

          </div>

          </DialogContent>
      </Dialog>
          

    </>
    
  );
};