import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

import '../styles/forms.css';

import { ProjectConfig } from 'Global';

const typeOfAnswers = [
    {id: 1, value: 'multiple',  label: 'Selección múltiple' },
    {id: 2, value: 'unique',    label: 'Selección única' },
    {id: 3, value: 'text',      label: 'Pregunta abierta' },
];

interface IquestionController {
    question_id: number | string,
    question_type: string,
    question: string,
    answers: {
        answer_id: number | string,
        answer: string,
        score: number,
        haveComment: boolean,
        labelComment: string,
    }[],
    answers_deleted: (number | string)[],
}

const defaultValues: IquestionController = {
    question_id: 'null',
    question_type: typeOfAnswers[0].value,
    question: '',
    answers: [
        {
            answer_id: 'null',
            answer: '',
            score: 0,
            haveComment: false,
            labelComment: '',
        },
    ],
    answers_deleted: [],
}

const cookies = new Cookies();

export default function DialogAnswers( props: any) {
    const {
        open,
        setOpen,
        selectedItem,
        handleClose,
        handleReload,
        formId,
        setLoading,
    } = props;

    const [questionController, setQuestionController] = useState<IquestionController>({...defaultValues});

    useEffect(() => {
        if (selectedItem) {
            loadList(selectedItem.question_id);
        } else{
            setQuestionController({...defaultValues});
        }
    }, [selectedItem, open]);

    const loadList = (question_id: number) => {
        setLoading(true);
        
        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltkn'),
                'COMPANYID': cookies.get('company_id'),
            },
            params: {
                request: "get_question_details",
                params: {
                    question_id: question_id,
                },
            },
        })
        .then((response) => {
            setQuestionController({...response.data, answers_deleted: []});
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const styleRowBox = {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        m: 'auto',
        p: '10px 0',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    };

    function handleAnswerChange(e: any, answerIndex: number, specyficValue: any = null): void {
        const newAnswers = [...questionController.answers];
        if (specyficValue !== null) {
            newAnswers[answerIndex] = {...newAnswers[answerIndex], ...{[e.target.name]: specyficValue}};
        } else {
            newAnswers[answerIndex] = {...newAnswers[answerIndex], ...{[e.target.name]: e.target.value}};
        }
        setQuestionController({
            ...questionController,
            answers: newAnswers,
        });
    }

    function handleDiscard(){
        setOpen(false);
    }

    function handleSubmit() {
        let formValues;
        if (questionController.question_type === 'text') {
            //setQuestionController({...questionController, answers: []});
            formValues = {
                ...questionController,
                ...{form_id: formId},
                ...{answers: []},
                ...{answers_deleted: questionController.answers.map((answer) => {return answer.answer_id})}};
        } else {
            formValues = {...questionController,...{form_id: formId}};
        }
        
        
        // AXIOS POST
        axios.post(ProjectConfig.api_url, formValues, {
            headers: {
                'LTKN': cookies.get('ltkn'),
                'COMPANYID': cookies.get('company_id'),
            },
            params: {
                request: "add_edit_question",
            },
        })
        .then((response) => {
            setOpen(false);
            handleReload();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
            handleDiscard();
        });
    }

    function handleDeleteAnswer(index: number){
        const itemDeleted = questionController.answers[index].answer_id;
        const answersDeleted = [...questionController.answers_deleted];
        if (itemDeleted != 'null') {
            answersDeleted.push(itemDeleted);
        }
        const newAnswers = [...questionController.answers];
        newAnswers.splice(index, 1);
        setQuestionController({
            ...questionController,
            answers: newAnswers,
            answers_deleted: answersDeleted,
        });
    }

    return (
        <Dialog
            open={open}
            onClose={(event: object,reason:string) => handleClose(reason, setOpen)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{minWidth: '800px'}}
        >

            <DialogContent
                className="forced_box_editor"
                sx={(questionController.question_type === 'text' ? {} : {pb: '0 !important'})}
            >
                <div className="dialog_header">
                    <h2 className='condensedElement dialog_h2'>Pregunta</h2>
                    <DialogContentText>
                        Editor
                    </DialogContentText>
                </div>

                <div className="formContainer">
                    <Box
                        noValidate
                        component="form"
                        sx={styleRowBox}
                        className='formContainer'
                    >
                        <FormControl className='formItem' sx={{width: '60%'}}>
                            <TextField
                                label='Pregunta'
                                className='formInput'
                                multiline
                                maxRows={3}
                                placeholder='Pregunta del formulario'
                                value={ questionController.question }
                                onChange={(e) => setQuestionController({...questionController, question: e.target.value}) }
                            />
                        </FormControl>

                        <FormControl className='formItem' sx={{width: '40%'}}>
                            <InputLabel id="selectInputLabel">Tipo de respuesta</InputLabel>
                            <Select
                                labelId="selectInputLabel"
                                id="selectInput"
                                label='Tipo de respuesta'
                                value={ questionController.question_type }
                                onChange={(e) =>
                                    setQuestionController({...questionController, question_type: e.target.value})}
                                className='formInput'
                            >

                                { typeOfAnswers.map((item: any) => (
                                    <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                    </Box>
                </div>
            </DialogContent>

            {/** MULTIPLE CHOICE CONTAINER */}

            { (questionController.question_type === 'multiple' || questionController.question_type === 'unique') &&
                <>
                    <DialogContent className='forced_box_editor'>
                        <div className="dialog_header">
                            <h2 className='condensedElement dialog_h2'>Respuestas</h2>
                        </div>
                        <Table className="formContainer">
                            <TableBody id="table_body_answers">
                            {questionController.answers.map((item: any, index: number) => (
                                <TableRow
                                    key={(item.answer_id == 'null'? -index : item.answer_id)}
                                    className='formContainer'
                                    >
                                    <TableCell className='formItem' sx={{width: '45%'}}>
                                        <TextField
                                            label='Respuesta'
                                            className='formInput'
                                            placeholder='Respuesta'
                                            type='text'
                                            multiline
                                            maxRows={3}
                                            size='small'
                                            value={item.answer}
                                            name='answer'
                                            onChange={(e) => handleAnswerChange(e, index)}
                                        />
                                    </TableCell>
                                    
                                    <TableCell className='formItem' sx={{width: '120px'}}>
                                        <TextField
                                            label='Puntaje'
                                            className='formInput'
                                            type='number'
                                            size='small'
                                            value={item.score}
                                            name='score'
                                            onChange={(e) => handleAnswerChange(e, index)}
                                            />
                                    </TableCell>

                                    <TableCell className='formItem' sx={{display: 'block', maxWidth: '177px'}}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                className='formInputCheck'
                                                checked={item.haveComment}
                                                name='haveComment'
                                                onChange={(e) => handleAnswerChange(e, index, !item.haveComment)}
                                            />
                                            }
                                            label={ (item.haveComment ?
                                                <TextField
                                                    label='Comentario'
                                                    className='formInput'
                                                    placeholder='Comentario'
                                                    type='text'
                                                    size='small'
                                                    value={item.labelComment}
                                                    name='labelComment'
                                                    onChange={(e) => handleAnswerChange(e, index)}
                                                />:
                                                <Typography
                                                    variant='body2'
                                                    sx={{minWidth: '152px',}}
                                                >
                                                    Incluir campo de texto
                                                </Typography>
                                            )}
                                            sx={{mr: '0px'}}
                                        />
                                    </TableCell>

                                    <TableCell className='formItem' sx={{width: 'fit-content'}}>
                                        <IconButton
                                            size='small'
                                            disabled={questionController.answers.length === 1}
                                            onClick={() => handleDeleteAnswer(index)}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <div className='formContainerActions'>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setQuestionController({
                                        ...questionController,
                                        answers: [
                                            ...questionController.answers,
                                            {
                                                answer_id: 'null',
                                                answer: '',
                                                score: 0,
                                                haveComment: false,
                                                labelComment: '',
                                            },
                                        ],
                                    });
                                }}
                                >
                                Agregar respuesta
                            </Button>
                        </div>
                    </DialogContent>
                </>
            }
            <div className='buttons'>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        handleSubmit()
                        setOpen(false);
                    }}
                    autoFocus
                >
                    Guardar
                </Button>
            </div>
        </Dialog>
    );

}
