import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  registerables
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  ...registerables
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'USUARIOS POR MES',
    },
    legend: {
      display: true,
      position: 'bottom' as const,
      labels: {
        usePointStyle: true,
      },
      pointStyle: 'circle',
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
    
  },
};


export default function TotalUsers(props: any) {
  const { setLoading, monthUsers} = props;

  const labels = monthUsers.labels;

 const data = {
  labels,
  datasets: [
    {
      label: 'Total usuarios',
      data: monthUsers.active_workstations,
      borderColor: 'rgb(238, 209, 87)',
      backgroundColor: 'rgb(238, 209, 87)',
      borderWidth: 2,
      fill: false,
      lineTension: 0.4,
      yAxisID: 'y1',
      type: 'line' as const,
    },
    {
      label: 'Ingresos',
      data: monthUsers.onboardings,
      backgroundColor: 'rgb(28, 114, 226)',
      borderWidth: 0,
      fill: true,
      lineTension: 0.4,
      yAxisID: 'y',
    },
    {
      label: 'Retiros',
      data: monthUsers.departures,
      backgroundColor: 'rgb(138, 179, 60)',
      borderWidth: 0,
      fill: true,
      lineTension: 0.4,
      yAxisID: 'y'
    }
    
  ],
};

  useEffect(() => {
    setLoading(false);
  }, []);

  return <Chart data={data} type={'bar'} options={options} />;
}