import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Box from 'elements/Box';

ChartJS.register(ArcElement, Tooltip, Legend);



export function ProductsInstalled(props: any) {
  const {productsPerCategory} = props;

  const data = {
    labels: productsPerCategory.labels,
    datasets: [
      {
        label: '# of Votes',
        data: productsPerCategory.quantity,
        backgroundColor: [
          
          "#1C72E2",
          "#87CFD4",
          "#7F2FF2",
          "#FFD62C",
          "#F9F7EF",
          "#000000",
          "#000000"
        ],
        borderWidth: 1,
      },
    ],
  };


  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
      },
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
        pointStyle: 'circle',
      },
    },
  };
  
  return <Box title='Productos instalados'>
    <Pie data={data} options={options} style={{maxWidth: '100%'}}/>
  </Box>;
}
