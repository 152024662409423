import { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar, withSnackbar } from "notistack";
import AlertDialog from "elements/AlertDialog";
import axios from "axios";
import { ProjectConfig } from "Global";
import AppAvatar from "elements/AppAvatar";
import ISwitch from "elements/ISwitch";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default withSnackbar(function ServiceDetails(props: any) {
  const { open, setOpen, setLoading, service_id, loadServiceList} = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [list, setList] = useState([]);

  useEffect(() => {
    if(open)
    {loadList();}
    
  }, [service_id,open]);

  const [serviceDetails, setServiceDetails] = useState<any>({})

  const loadList = () => {
    if (service_id === 0) {
      return;
    }
    setLoading(true);
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "service_details",
          params: {
            service_id: service_id,
          },
        },
      })
      .then((response) => {
        console.log(response.data);
        setServiceDetails(response.data)
        setList(response.data.orders);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const [alertOpen, setAlertOpen] = useState(false);

  // SEND INVITATIONS TO WORKSTATIONS LIST
  const changeStatus = () => {
    setLoading(true);

    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "change_service_status",
          params: {
            service_id: service_id,
            status: serviceDetails.service_status_id == 0 ? 1 : 2,
            service_type_id: serviceDetails.service_type_id,
          },
        },
      })
      .then((response) => {
        console.log(response.data);
        loadServiceList();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        setOpen(false);
      });
  };
  console.log("serviceDetails.service_status_id", serviceDetails.service_status_id)
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <DialogContent className="forced_box_editor">
          <div className="dialog_header header_flex">
            <div>
              <h2 className="dialog_h2">Detalles del servicio
                    <Chip 
                      label={serviceDetails.service_type_id == 1 ? "ENVÍO" : "RETIRO"} 
                      size='small' 
                      color={serviceDetails.service_type_id == 1 ? 'primary' : 'error'} 
                      sx={{marginLeft: "10px",}} 
                      icon={serviceDetails.service_type_id == 1 ? <FileUploadIcon sx={{paddingLeft: "5px",}} /> : <FileDownloadIcon sx={{paddingLeft: "5px", }} />}
                    />
                    </h2>
              <DialogContentText>
                Lista de pedidos asociados al servicio
                
              </DialogContentText>
            </div>

            <div className="dialog_header_right">

              <Chip 
                label={serviceDetails.service_status} 
                icon={serviceDetails.service_status_id == 0? <HowToRegIcon sx={{paddingLeft: "5px",}} /> : serviceDetails.service_status_id == 1? <LocalShippingIcon sx={{paddingLeft: "5px",}} /> : <EmojiFlagsIcon sx={{paddingLeft: "5px",}} />}
                variant='outlined' 
                color={serviceDetails.service_status_id == 0 ? 'error' : serviceDetails.service_status_id == 1 ? 'success' : 'primary'} 
              />
  
              {serviceDetails.service_status_id !== 2 && (
                <>
                <ArrowForwardIosIcon sx={{color: "#ccc",}} />
                <Button
                  variant="outlined"
                  color={serviceDetails.service_status_id == 0 ? 'success' : 'primary'}
                  onClick={() => {
                    setAlertOpen(true)
                  }}
                  startIcon={serviceDetails.service_status_id == 0 ? <LocalShippingIcon /> : <EmojiFlagsIcon />}
                >
                  Cambiar a {serviceDetails.service_status_id == 0 ? 'En camino' : 'Entregado'}
                </Button>
                </>
              )}


            </div>
          </div>

          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>USUARIO</TableCell>
                <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>PRODUCTOS</TableCell>
                <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>DIRECCIÓN</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item: any, list_index: any) => {
                return (
                  <TableRow key={list_index}>
                    <TableCell>
                      <div className="tdwimg">
                        <AppAvatar name={item.name} image="" />
                        <div className="flexTd">
                          <span className="flexTd_bold">
                            {item.name} {item.lastname}
                          </span>
                          <span className="flexTd_normal">{item.email}</span>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="flex_products">
                        {item.products_list.map(
                          (product: any, product_index: any) => {
                            return (
                              <div className="flexRow" key={product_index}>
                                <AppAvatar
                                  size="30"
                                  name={product.product_name}
                                  image={product.product_image}
                                />
                                <span className="flexTd_normal">
                                  {product.product_name}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="flexTd">
                        <span className="flexTd_bold">
                          {item.address ? item.address : "Dirección pendiente"}
                        </span>
                        <span className="flexTd_normal">
                          {item.city_name ? item.city_name : ""}
                        </span>
                      </div>
                    </TableCell>

                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {/* BUTTONS */}
          <div className="buttons">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>


          </div>
        </DialogContent>
      </Dialog>

      {/* ALERT DIALOG */}
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        send={changeStatus}
        subtitle="¿Estás seguro que deseas cambiar el estado del servicio?"
      />
    </>
  );
});
