import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {
  AdminPanelSettings,
  BarChart,
  Business,
  Category,
  ExpandMore,
  HeadsetMic,
  Inventory2,
  ListAlt,
  People,
  PersonPin,
  RocketLaunch,
} from '@mui/icons-material';
import { 
  Accordion,
  AccordionDetails, 
  AccordionSummary,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import ic_menu from 'icons/ic_menu.svg';
import ic_store from 'icons/ic_store.svg';

import 'assets/css/main.css';
import MenuButton from 'elements/MenuButton';

const styleMenu = {
  buttons: {
    textDecoration: 'none',
    color: 'inherit',
    padding: '0 25px',
    width: '100%',
    justifyContent: 'start',
  },
  button_labels: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    textTransform: 'uppercase',
  },
  button_imgs: {
    width: '20px',
    height: '20px',
    margin: '0',
    marginLeft: '5px',
    color: '#666',
  },
  accordion: {
    padding: '0px 20px',
    bgcolor: 'transparent',
    color: '#ffffff',
    boxShadow: 'none',
    '&:hover': {backgroundColor: 'transparent'},
  },
  accordion_buttons: {
    display: 'flex',
    color: '#222',
    fontSize: '14px',
    width: '100%',
    justifyContent: 'start',
    columnGap: '10px',
    alignItems: 'center',
  },
  accordion_img_company: {
    width: '30px',
    height: '30px',
    margin: '0',
  },
  accordion_imgs: {
    width: '30px',
    height: '30px',
    padding: '4px',
    margin: '0',
  },
}

export default function MenuAdmin() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const open = {menu_1: Boolean(anchorEl),
  //               menu_2: Boolean(anchorEl)};

  const [open, setOpen] = useState({menu_1: false, menu_2: false});
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event.currentTarget.id)

    //SET ALL FALSE
    setOpen({
      menu_1: false,
      menu_2: false
    })

    setOpen({
      ...open,
      [event.currentTarget.id]: true
    })
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
     //SET ALL FALSE
     setOpen({
      menu_1: false,
      menu_2: false
    })
    setAnchorEl(null);
  };

  const handleClickButton = () => {
    //SET ALL FALSE
    setOpen({
      menu_1: false,
      menu_2: false
    })
    setAnchorEl(null);
  };

  return (
    <div className='menu_button_container'>
      <div>
        <h2 className='menu_title'>Usuario</h2>
        <MenuButton label="Usuarios" to="/users" startIcon={<People />} />
        <MenuButton label="Administradores" to="/administrators" startIcon={<AdminPanelSettings />} />
        <MenuButton label="Empresas" to="/companies" startIcon={<Business />} />
        <MenuButton label="Puestos" to="/workstations" startIcon={<PersonPin />} />
        <MenuButton label="Formularios" to="/forms" startIcon={<ListAlt />} />
        <MenuButton label="Soporte" to="/tickets" startIcon={<HeadsetMic />} />
        <MenuButton label="Ordenes" to="/orders" startIcon={<RocketLaunch />} />

      </div>

      <div>
        <h2 className='menu_title'>Catálogo</h2>
        <MenuButton label="Categorías" to="/categories" startIcon={<Category />} />
        <MenuButton label="Productos" to="/inventory" startIcon={<Inventory2 />} />
      </div>
     
    </div>
  );
}
