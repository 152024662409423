import { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import Cookies from 'universal-cookie';

import SendIcon from '@mui/icons-material/Send';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar, withSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import AppAvatar from 'elements/AppAvatar';
import ISwitch from 'elements/ISwitch';
import CheckIcon from '@mui/icons-material/Check';
import BudgetSelector from '../User/BudgetSelector';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import DialogForm from '../User/Dialogs/DialogForm';

const cookies = new Cookies();

const dataDefault = {
    workstation_invitation_id: 0,
    workstation_id: 0,
    invitation_type: 1,
    kit_id: 0,
    budget: 0,
    test_id: 0,
    fixed_products: 0,
    included_products: 0,
    start_date: '',
    status: '',
    approval_required: 0,
    invitation_products: [],
}


export default withSnackbar(
    function InvitationDetail(props: any) {

    const {open, setOpen, setLoading, workstation_invitation, getWorksationDetails} = props;
    const [data, setData] = useState<any>(dataDefault);
    const [form, setForm] = useState<any>(null);


    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    useEffect(() => {
      setLoading(false);
    }, []);

    useEffect(() => {
        if(open){
            axios.get(ProjectConfig.api_url,{
                headers: {
                    'COMPANYID': 1,
                },
                params: {
                    request: 'get_invitation_details',
                    params: {
                        workstation_invitation_id: workstation_invitation.workstation_invitation_id,
                    }
                }
                })
                .then((response) => {
                    console.log("invitation detail ",response.data);
                    setData(response.data);
                    setForm(response.data.form);
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    // always executed
                    //setOpen(true);
                });
        }
    }, [open, workstation_invitation]);



    const checkForm = () => {
        //CHECK IF ALL FIELDS ARE FILLED AND ADD ERROR MESSAGE IF NOT TO EACH TEXTFIELD
        
        let errors = false;
        return errors;
    }
  
    const [alertOpen, setAlertOpen] = useState(false);

    // SEND INVITATIONS TO WORKSTATIONS LIST
    const confirm = () => {

        var formdata = new FormData();
        formdata.append("params", JSON.stringify(
            {
                workstation_invitation_id: workstation_invitation.workstation_invitation_id,
                data: data,
            }
        ));
        
        axios.post(ProjectConfig.api_url,formdata,
            {
                params: {
                    request: 'accept_invitation',
                  }
            })
            .then((response) => {
                console.log("invitation detail ",response.data);
                getWorksationDetails()
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                setOpen(false);
            });
    

        
        
    }


    const handleCloseForm = (reason: string, openSetter: CallableFunction) => {
        if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            openSetter(false)
        }
    };


  return (
    <>
        { (data.invitation_type === 1 || data.invitation_type === 2) &&
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            
            <DialogContent  className="forced_box_editor">

                <form className="form">
                    
                    { data.invitation_type == 1 &&
                    <>
                        <div className="form_header">
                            <h2 className='dialog_h2'>KIT</h2>
                            <p className='subtitle'>Selecciona los productos que deseas incluir a tu puesto de trabajo</p>
                        </div>

                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Imagen</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Seleccionado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {data.invitation_products.map((product: any, index: number) => {
                                    
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <div className="invitation_product_image">
                                                    <img src={product.images[0].image} alt=""/>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <span className='product_name'>{product.product_name}</span>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <ISwitch checked={product.selected} onChange={() => {
                                                    let newProducts = data.invitation_products;
                                                    newProducts[index].selected = !newProducts[index].selected;
                                                    setData({...data, invitation_products: newProducts});
                                                }}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </>
                    }
                    { data.invitation_type == 2 &&
                    <div className="invitation_products">
                        <div className="user_invitation_header">
                            <div className='form_header'>
                                <h2 className='dialog_h2'>PRESUPUESTO</h2>
                                <p className='subtitle'>Selecciona productos de acuerdo a tu presupuesto asignado</p>
                            </div>

                            <div id="budgetValueContainer">
                                <CreditCardIcon />
                                <div id="budgetValue">
                                    <span className='label1'>Presupuesto: </span>
                                    <span className='value1'>${data.budget.toLocaleString('es-ES')}</span>
                                </div>
                            </div>
                        </div>
                        <BudgetSelector 
                            setLoading={setLoading}
                            budget={data.budget}
                            data={data}
                            setData={setData}
                        />
                    </div>
                    }
                        
                </form>



                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>

                    <Button 
                        variant="contained" 
                        startIcon={<CheckIcon />}
                        onClick={
                            () => {
                                if(!checkForm())
                                {
                                    setAlertOpen(true);
                                }
                                else{
                                    enqueueSnackbar('Errores en el formulario', {variant: 'error'});
                                }
                            }
                        }
                        >
                        Confirmar
                    </Button>
                </div>
            </DialogContent>

        </Dialog>
        }


        {/* ALERT DIALOG */}
        <AlertDialog 
            open={alertOpen} 
            setOpen={setAlertOpen} 
            send={confirm}
            subtitle="¿Estás seguro de confirmar la invitación?"
        />

        { data.invitation_type === 3 &&
        <DialogForm
            open={open}
            setOpen={setOpen}
            selectedForm={form}
            setSelectedForm={setForm}
            handleClose={handleCloseForm}
            setLoading={setLoading}
            handleConfirm={getWorksationDetails}
            workstation_invitation_id={data.workstation_invitation_id}
        />
        }
        
    </>
  );
});

