import { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useSnackbar, withSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';
import AutocompleteCities from 'elements/AutocompleteCities';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchLocation from '../User/SearchLocation';
import axios from 'axios';

import Cookies from 'universal-cookie';
import { ProjectConfig } from 'Global';

const cookies = new Cookies();





export default withSnackbar(
    function RegisterWorkstation(props: any) {

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const {open, send, setOpen, setLoading, workstation_id, loadList} = props;

    const defaultData = {
        workstation_id: workstation_id,
        city_id: {
            error: false,
            value: '',
        },
        address: {
            error: false,
            value: "",
            latitude: -74.100521,
            longitude: 4.653462,
        },
        phone: {
            error: false,
            value: "",
        },
        height: {
            error: false,
            value: "",
        },
        shirt_size: {
            error: false,
            value: "XS",
        },
        
    }

    const [openSearchLocation, setOpenSearchLocation] = useState(false);

    useEffect(() => {
        setData({...data, workstation_id: workstation_id});
    }, [workstation_id]);

    useEffect(() => {
      setLoading(false);
    }, []);

    //IF CLOSE DIALOG, RESET DATA
    useEffect(() => {
        if(!open){
            setData(defaultData);
        }
    }, [open]);
    


    const [data, setData] = useState(defaultData);

    const checkForm = () => {

        let error = false;

        if(data.city_id.value === ''){
            setData({...data, city_id: {error: true, value: data.city_id.value}});
            error = true;
        }

        if(data.address.value === ''){
            setData({...data, address: {error: true, value: data.address.value, latitude: data.address.latitude, longitude: data.address.longitude}});
            error = true;
        }

        if(data.phone.value === ''){
            setData({...data, phone: {error: true, value: data.phone.value}});
            error = true;
        }

        if(data.height.value === ''){
            setData({...data, height: {error: true, value: data.height.value}});
            error = true;
        }

        return error;
    }
  


    // SEND INVITATIONS TO WORKSTATIONS LIST
    const confirm = () => {
        setLoading(true);
        axios.post(ProjectConfig.api_url, null,
        {
        headers:{
            'LTKN': cookies.get('ltkn')
        },
        params: {
            request: 'register_user_workstation',
            params:{
                data: data
            }
        } 
        })
        .then((response) => {
            enqueueSnackbar('Registro completado', {variant: 'success'});
            loadList();
            setOpen(false);
            setLoading(false);
            
        })
        .catch((err) => {
            console.log(err.data);
        })
        }


    const handleInputChange = (e: any) => {
        setData({...data, city_id: {error: false, value: e.target.value}})
    }

    const [alertOpen, setAlertOpen] = useState(false); 
 
 




    return (
    <>

    <SearchLocation 
        open={openSearchLocation}
        setOpen={setOpenSearchLocation}
        setData={setData}
        data={data}
        setLoading={setLoading}
        
    />


        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            
            <DialogContent  className="forced_box_editor">
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Completar registro</h2>
                    <DialogContentText>
                        Por favor ingresa los siguientes datos para completar el registro.
                    </DialogContentText>
                </div>



                <form className="form">
                    
                    <div className="input_row">
                    {/* select KIT / PRESUPUESTO  */}
                    <FormControl fullWidth>
                        <InputLabel id="lbl_product_category">Talla</InputLabel>
                            <Select
                            labelId="shirt_size"
                            id="shirt_size"
                            value={data.shirt_size.value}
                            label="Talla"
                            
                            onChange={(e) => {
                                setData({...data, shirt_size: {error: false, value: e.target.value}})
                            }}
                            >
                            <MenuItem value={"XS"}>XS</MenuItem>
                            <MenuItem value={"S"}>S</MenuItem>
                            <MenuItem value={"M"}>M</MenuItem>
                            <MenuItem value={"L"}>L</MenuItem>
                            <MenuItem value={"XL"}>XL</MenuItem>
                            <MenuItem value={"XXL"}>XXL</MenuItem>
                        </Select>
                    </FormControl>



                    <TextField
                        fullWidth
                        id="phone"
                        label="Teléfono"
                        value={data.phone.value}
                        error={data.phone.error}
                        helperText={data.phone.error ? "Este campo es obligatorio" : ""}
                        onChange={(e) => {
                            setData({...data, phone: {error: false, value: e.target.value}})
                        }}
                    />

                    <TextField
                        fullWidth
                        id="height"
                        label="Estatura"
                        type="number"
                        value={data.height.value}
                        error={data.height.error}
                        helperText={data.height.error ? "Este campo es obligatorio" : ""}
                        //CM AS END ICON preventing text select
                        InputProps={{
                            endAdornment: (
                            <span style={{userSelect: "none"}}>cm</span>
                            ),
                        }}
                        onChange={(e) => {
                            setData({...data, height: {error: false, value: e.target.value}})
                        }}

                        
                    />
                    </div>
                    


                    <AutocompleteCities 
                        onChange={handleInputChange} 
                        value={data.city_id.value}
                        error={data.city_id.error}
                    />
                    
                    {data.city_id.value !== "" &&
                    <TextField
                        fullWidth
                        id="address"
                        label="Dirección"
                        value={data.address.value}
                        error={data.address.error}
                        helperText={data.address.error ? "Este campo es obligatorio" : ""}
                        // start icon 
                        InputProps={{
                            endAdornment: (
                            <LocationOnIcon sx={{ color: 'action.active'}} />
                            ),
                        }}
                        // cursor pointer
                        InputLabelProps={{
                            sx: { cursor: 'pointer' },
                        }}
                        inputProps={{
                            style: { cursor: 'pointer', userSelect: "none" },
                            readOnly: true,
                        }}
                        onClick={() => {
                            setOpenSearchLocation(true);
                        }}

                    />
                    }

                    



                            

                            
                </form>



                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>

                    <Button 
                        variant="contained" 
                        onClick={
                            () => {
                                if(!checkForm())
                                {
                                    setAlertOpen(true);
                                }
                                else{
                                    enqueueSnackbar('Errores en el formulario', {variant: 'error'});
                                }
                            }
                        }
                        >
                        Registrar
                    </Button>
                </div>
            </DialogContent>

        </Dialog>


        {/* ALERT DIALOG */}
        <AlertDialog 
            open={alertOpen} 
            setOpen={setAlertOpen} 
            send={confirm} 
            subtitle="¿Estás seguro de los datos ingresados?" 
        />
        
    </>
  );
});

