import { PersonPin } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function MenuButton(props: any) {

  const { label, to = null, startIcon, onClickCallback, company_id = null, } = props;

  const checkActiveMenu = (href: string) => {

    //IF IS only / 
    if (href === '/') {
      if (window.location.pathname === '/') {
        return true;
      } else {
        return false;
      }
    }else if (window.location.pathname.startsWith(href)) {
      if (company_id) {
        if (cookies.get('company_id') === company_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }else{
      return false;
    }
  }

  const styleMenu = {
    buttons: {
      textDecoration: 'none',
      padding: '10px 20px',
      width: '100%',
      justifyContent: 'start',
      color: '#637381',
      textTransform: 'none',
      //svg
      '& svg': {
        width: '25px',
        height: '25px',
        marginRight: '10px',
        color: checkActiveMenu(to)? '#ffffff' : '#637381',
      },
    }
  }
  


  return (
    <>
    {to === null ?
      <Button
        startIcon={startIcon}
        sx={styleMenu.buttons}
        onClick={onClickCallback}
        style={{
          color: checkActiveMenu(to)? '#ffffff' : '#637381',
          backgroundColor: checkActiveMenu(to)? '#1E67E2' : 'transparent',
        }}
        >
        {label}
      </Button>
      :
      <Button
        component={Link}
        startIcon={startIcon}
          to={to}
          sx={styleMenu.buttons}
          onClick={onClickCallback}
          style={{
            color: checkActiveMenu(to)? '#ffffff' : '#637381',
            backgroundColor: checkActiveMenu(to)? '#1E67E2' : 'transparent',
          }}
          
          
          >
        {label}
      </Button>
    }
    </>
  );
}