import SendIcon from '@mui/icons-material/Send';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { Avatar, Badge, Divider, Tooltip } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function StatusTimeline(props: any) {

  const { status } = props;
  
  return (
    <div className='userStatusTimeline'>
      <div className='userStatusTimeline__item'>
        <Tooltip title="Invitación enviada" placement="top">
          <Badge 
            invisible={status >= 0 ? false : true}
            overlap='circular'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={<CheckCircleIcon fontSize='small' color='primary' />}
            > 
          <Avatar sx={{ bgcolor: 'transparent', width: 50, height: 50, 
            border: status >= 0 ? '2px solid #0271e2' : '2px solid #ccc' }}>
            <SendIcon  sx={{ color: status >= 0 ? '#0271e2' : '#ccc' }} />
          </Avatar>
          </Badge>
        </Tooltip>
      </div>

      <div className="timelineDivider" style={{ borderColor: status >= 1 ? '#0271e2' : '#ccc' }}></div>

      <div className='userStatusTimeline__item'>
      <Tooltip title={status >= 1 ? "Usuario vinculado" : "Usuario no vinculado"} placement="top">
      <Badge 
          invisible={status >= 1 ? false : true}
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={<CheckCircleIcon fontSize='small' color='primary' />}
          > 
        <Avatar sx={{ bgcolor: 'transparent', width: 50, height: 50, 
          border: status >= 1 ? '2px solid #0271e2' : '2px solid #ccc' }}>
          <HowToRegIcon  sx={{ color: status >= 1 ? '#0271e2' : '#ccc' }} />
        </Avatar>
        </Badge>
        </Tooltip>
      </div>

      <div className="timelineDivider" style={{ borderColor: status >= 2 ? '#0271e2' : '#ccc' }}></div>


      <div className='userStatusTimeline__item'>
      <Tooltip title={status >= 2 ? "Registro completado" : "Registro pendiente"} placement="top">
      <Badge 
          invisible={status >= 2 ? false : true}
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={<CheckCircleIcon fontSize='small' color='primary' />}
          > 
        <Avatar sx={{ bgcolor: 'transparent', width: 50, height: 50, 
          border: status >= 2 ? '2px solid #0271e2' : '2px solid #ccc' }}>
          <WhereToVoteIcon  sx={{ color: status >= 2 ? '#0271e2' : '#ccc' }} />
        </Avatar>
        </Badge>
        </Tooltip>
      </div>


    </div>
  );
}