import * as React from 'react';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { Button, Dialog, DialogContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar, withSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useNavigate } from 'react-router-dom';

export default function LinkUserToWorkstation(props: any) {
    

    const { invitation, email, workstation_code, ltkn } = props;


        // delete localStorage['invitation'];
        // delete localStorage['email'];
        // delete localStorage['workstation_code'];

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [openDialog, setOpenDialog] = useState(true);
    const [linkStatus, setLinkStatus] = useState(false);
    const [linkMessage, setLinkMessage] = useState('Vinculando usuario');

    useEffect (() => {
        if (workstation_code === '') return;
        console.log('linking user to workstation', email, workstation_code);
        axios.post(ProjectConfig.api_url,null,
        {
            headers:{
            'LTKN': ltkn
            },
            params: {
                request: 'link_user_to_workstation',
                params: {
                    email: email,
                    workstation_code: workstation_code
                }
            }
        })
        .then((response) => {
            console.log(response);
            setLinkStatus(true);
            setLinkMessage(response.data);
        })
        .catch((err) => {
            console.log(err.code);
        })
    }, [workstation_code]);
    

    const navigate = useNavigate();
    
  return (
    <>
        <Dialog 
            open={openDialog}
        >
            <DialogContent  className="forced_box_editor">
                <div className="linkUserToWorkstationDialog">
                    {!linkStatus ? <CircularProgress /> : <TaskAltIcon sx={{ fontSize: 45 }} color='primary' />}
                    <span>{linkMessage}</span>
                </div>


                <div className="buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            //REPLACE current history with new one
                            navigate(`/myworkstations`, { replace: true });
                            setOpenDialog(false);
                        }}
                    >
                        Ir a puestos
                    </Button>

                </div>
            </DialogContent>
        </Dialog>
    </>
  )
}