import { useEffect } from "react";



export default function Dashboard(props: any) {

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  

  return (
    <div>
      <h1>Dashboard</h1>
    </div>
  );
}