import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Badge, Button, Checkbox, Chip, CircularProgress, Dialog, DialogContent, Drawer, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AddWorkstation from '../Workstations/Utils/AddWorkstation';
import Cookies from 'universal-cookie';
import AlertDialog from 'elements/AlertDialog';
import AddProductInvitation from '../Workstations/Utils/AddProductInvitation';
import AddIcon from '@mui/icons-material/Add';

const cookies = new Cookies();

const serviceDefault = {
  tracking_number: '',
  comments: '',
}

export default function ListOrders(props: any) {

  const {
    setLoading, 
    list, 
    setList,
    type, 
    checkedList,
    setCheckedList, 
    oppositeList, 
    setOppositeList,
    setServiceType,
    allChecked,
    setAllChecked,
    setOppositeAllChecked
  } = props;
  
  useEffect(() => {
    setServiceType(type);
  }, [list]);


  const checkSelectedProducts = () => {

      //UNCHECK ALL OPPSITE LIST
      let newOppositeList = oppositeList;
      newOppositeList.forEach((item: any) => {
        item.checked = false;
        item.products_list.forEach((product:any) => {
          product.checked = false;
        })
      });
      setOppositeList(newOppositeList);
      
  

      let selectedRows: any[] = [];
      list.forEach((item: any) => {
        item.products_list.forEach((product: any) => {
          if(product.checked){
            selectedRows.push(product);
          }
        })
      }
      );
      setCheckedList(selectedRows);
  
  }

  


    /*=========================================================
  LOAD LIST            
  =========================================================*/


const [openServiceEditor, setOpenServiceEditor] = useState(false);

  //when close AddProductInvitation uncheck all rows
  useEffect(() => {
    if(!openServiceEditor){
      let newList = list;
      newList.forEach((item: any) => {
        item.checked = false;
        item.products_list.forEach((product:any) => {
          product.checked = false;
        })
      });
      setList(newList);
      setCheckedList([]);
      setAllChecked(false);
      setOppositeAllChecked(false);

    }
  }, [openServiceEditor]);
  

/*=========================================================*/

useEffect(() => {
  checkSelectedProducts();
}, [list]);


const [alertOpen, setAlertOpen] = useState(false);


const checkRowChecked = (rowIndex: any) => {
  
  //CHECK IF ALL PRODUCTS ARE CHECKED
  let allUncheched = true;

  list[rowIndex].products_list.forEach((product:any) => {
    if(product.checked){
      allUncheched = false;
    }
  })


  //IF ALL PRODUCTS ARE CHECKED CHECK PARENT
  if(allUncheched){
    let newList = [...list];
    newList[rowIndex].checked = false;
    setList(newList);
  }
  

}


  return (
    <>

    <Table aria-label="sticky table"> 
      <TableHead>
        <TableRow>
          <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => {
                let newList = [...list];
                newList.forEach((item: any) => {
                  item.checked = e.target.checked; 
                  item.products_list.forEach((product:any) => {
                    product.checked = e.target.checked;
                  })
                });
                setList(newList);
                setAllChecked(e.target.checked);
                setOppositeAllChecked(false);
              }}
              checked={allChecked}
            />
          </TableCell>
                
          <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>USUARIO</TableCell>
          <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>PRODUCTOS</TableCell>
          <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>DIRECCIÓN</TableCell>
          <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>FECHA</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((item:any,list_index:any) => {

          return(
          <TableRow key={list_index}>

            <TableCell>
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                checked={item.checked}
                onChange={(e) => {
                 
                  let newList = [...list];
                  newList[list_index].checked = e.target.checked;
                  newList[list_index].products_list.forEach((product:any) => {
                    product.checked = e.target.checked;
                  })
                  setList(newList);
                  setAllChecked(false);
                  setOppositeAllChecked(false);
                  
                }}
                indeterminate={item.checked && item.products_list.some((product:any) => !product.checked)}
              />
            </TableCell>

            <TableCell>
              <div className='tdwimg'>
                <AppAvatar name={item.name} image="" />
                <div className='flexTd'>
                  <span className='flexTd_bold'>{item.name} {item.lastname}</span>
                  <span className='flexTd_normal'>{item.email}</span>
                </div>
              </div>
            </TableCell>

            <TableCell>
              <div className='flex_products'>
              {item.products_list.map((product:any, product_index:any) => {
                
                return(
                <div className='flexRow' key={product_index}>
                  <Checkbox
                    color="primary"
                    size='small'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={product.checked}
                    onChange={(e) => {
                      let newList = [...list];
                      
                      //checked parent 
                      if(e.target.checked){
                        newList[list_index].checked = true;
                      }

                      


                      newList[list_index].products_list[product_index].checked = e.target.checked;
                      setList(newList);
                      setAllChecked(false);
                      setOppositeAllChecked(false);
                      
                      checkRowChecked(list_index);
                    }}
                  />
                  <AppAvatar size="30" name={product.product_name} image={product.product_image} />
                  <span className='flexTd_normal'>{product.product_name}</span>
                </div>
              )})}
              </div>
            </TableCell>

            <TableCell >
              <div className='flexTd'>
                <span className='flexTd_bold'>{item.address? item.address : "Dirección pendiente"}</span>
                <span className='flexTd_normal'>{item.city_name? item.city_name : ""}</span>
              </div>
            </TableCell>

            <TableCell>
                {moment(item.start_date).format('DD-MM-YYYY')}
            </TableCell>






          </TableRow>
        )})}
      </TableBody>
    </Table>

    </>
  );
};

