import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { BorderColor, Check, ExpandMore, LockOpen, } from '@mui/icons-material';
import { useSnackbar } from 'notistack';


import { ProjectConfig } from 'Global';
import Box from 'elements/Box';
import TicketsView from './TicketsView';

import 'assets/css/main.css';

import TicketForm from './TicketForm';
import AlertDialog from 'elements/AlertDialog';

const cookies = new Cookies();

interface ITicket {
    ticket_id: number | 'null';
    subject: string;
    status: 'open' | 'closed' | 'in_progress' | '';
    priority: 'low' | 'medium' | 'high' | '';
    created_at: string | Date;
    last_update: string | Date;
    tickets: {
        ticket_message_id: number | 'null';
        owner: string;
        date: string | Date;
        message: any;
    }[],
}

const defaultValues: ITicket = {
    ticket_id: 'null',
    subject: '',
    status: 'open',
    priority: 'low',
    created_at: '',
    last_update: '',
    tickets: [],
};

export default function TicketsEditor( props: any ) {
    const {
        isAdmin = false,
    } = props;
    
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const { ticket_id = 'null' } = useParams();

    const [formValues, setFormValues] = useState<ITicket | any>(defaultValues);
    const [openReply, setOpenReply] = useState<boolean>(false);

    const [openAlert, setOpenAlert] = useState<boolean>(false);

    
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (ticket_id !== 'null') {
            loadData();
        }
    }, []);

    const loadData = () => {
        setLoading(true);

        const params = isAdmin ? { isAdmin: true } : {};
        
        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltkn'),
                'COMPANYID': cookies.get('COMPANYID'),
            },
            params: {
                request: 'get_ticket_messages_list',
                params: {
                    ...params,
                    ticket_id: ticket_id,
                },
            }
        })
        .then((response) => {
            console.log("get_ticket_details",response.data);
            if (response.data.status === "error") {
                enqueueSnackbar(response.data.message, { variant: 'error' });
                navigate('/tickets');
            }
            setFormValues(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleChangeStatus = (status: string) => {
        setLoading(true);

        const params = isAdmin ? { isAdmin: true } : {};

        axios.post(ProjectConfig.api_url, {
            ticket_id: ticket_id,
            status: status,
        }, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                ...params,
                request: 'change_ticket_status',
            }
        })
        .then((response) => {
            console.log("change_ticket_status",response.data);
            if (response.data.status === "error") {
                // enqueueSnackbar(response.data.message, { variant: 'error' });
            }
            else {
                // enqueueSnackbar(response.data.message, { variant: 'success' });
                loadData();
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <div className='ModuleContainer' style={{maxWidth: (formValues.ticket_id !== 'null' ? '1200px' : '900px')}}>
                <div className='ModuleWrapper'>
                    <div className="boxContainerHeader">
                        <div className="boxContainerTitle" style={{minWidth:'50%'}}>
                            <h2 className="h2_title">
                                {formValues.ticket_id !== 'null' ? <> Ticket #{formValues.ticket_id} </>: <> Nuevo ticket </>}
                            </h2>
                            <span className="h2_subtitle">Editor</span>
                        </div>
                    </div>

                    <div className='ModuleWrapper columns2'>
                        {formValues.ticket_id !== 'null' &&
                        <div className="boxContainerBody">
                            <Box className="box100" title={formValues.ticket_id !== 'null' ? undefined : "Datos"} style={{padding: '20px 0', minWidth: '240px'}}>
                                <div
                                    className='dataDisplayer'
                                    style={
                                        formValues.status !== 'closed' ?
                                        {paddingBottom: '90px'} :
                                        {paddingBottom: '40px'}
                                    }
                                >
                                    <div className='dataDisplayerInformation'>
                                        <Typography variant="body2">Asunto: </Typography>
                                        <Typography variant="body1">{formValues.subject}</Typography>
                                    </div>

                                    <div className='dataDisplayerInformation'>
                                        <Typography variant="body2">Creado</Typography>
                                        <Typography variant="body2">{formValues.created_at}</Typography>
                                    </div>

                                    <div className='dataDisplayerInformation'>
                                        <Typography variant="body2">Actualizado</Typography>
                                        <Typography variant="body2">{formValues.last_update}</Typography>
                                    </div>

                                    <div className='dataDisplayerInformation'>
                                        <Typography variant="body2">Prioridad</Typography>
                                        <Typography variant="body1">
                                            { formValues.priority === 'high' && 'Alta'}
                                            { formValues.priority === 'medium' && 'Media'}
                                            { formValues.priority === 'low' && 'Baja'}
                                        </Typography>
                                    </div>

                                    <div className='dataDisplayerInformation' style={{borderBottom: 'none'}}>
                                        <Typography variant="body2">Estado</Typography>
                                        <Typography variant="body1">
                                            { formValues.status === 'open' && 'Abierto'}
                                            { formValues.status === 'closed' && 'Cerrado'}
                                            { formValues.status === '' && 'Indeterminado'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className='buttons' style={{flexDirection: 'column', gap: '10px'}}>
                                    {formValues.status !== 'closed' ? (
                                        <>
                                            {!isAdmin ? (
                                                <Button
                                                    variant="outlined"
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    startIcon={<Check />}
                                                    onClick={() => {
                                                        setOpenAlert(true);
                                                    }}
                                                >
                                                    Marcar como cerrado
                                                </Button>
                                            ) : (
                                                <TextField
                                                    label="Mensaje"
                                                    variant="outlined"
                                                    select
                                                    value={formValues.status}
                                                    onChange={(e) => {
                                                        handleChangeStatus(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value="open">Abierto</MenuItem>
                                                    <MenuItem value="closed">Cerrado</MenuItem>
                                                    <MenuItem value="in_progress">En proceso</MenuItem>
                                                </TextField>
                                            )}
                                        </>
                                    ) : null }

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<BorderColor />}
                                        onClick={() => {
                                            setOpenReply(true);
                                        }}
                                    >
                                        Responder
                                    </Button>
                                </div>
                            </Box>
                        </div>
                        }

                        <div style={{width: '-webkit-fill-available', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            {formValues.ticket_id === 'null' ? (
                                <TicketForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    setLoading={setLoading}
                                    isAdmin={isAdmin}
                                />
                            ): (
                                <Accordion
                                    sx={{ boxShadow: 'none', borderRadius: '5px'}}
                                    defaultExpanded={false}
                                    expanded={openReply}
                                    onChange={() => setOpenReply(!openReply)}
                                >
                                    <AccordionSummary
                                        sx={{ background: 'white', color: '#666', pl: '20px', pr: '20px', "&.Mui-expanded": {
                                            background: "white",
                                            color: "var(--color1)",
                                        } }}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', }}>
                                            <BorderColor sx={{fontSize: '1rem', color: (openReply ? 'var(--color1)' : '#666')}} />
                                            <Typography variant="body1" sx={{fontWeight: 500}}>Responder</Typography>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails
                                        sx={{ p: '0' }}
                                        >
                                        <TicketForm
                                            formValues={formValues}
                                            setFormValues={setFormValues}
                                            setLoading={setLoading}
                                            setOpen={setOpenReply}
                                            isAdmin={isAdmin}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                            <TicketsView tickets={formValues.tickets} isAdmin={isAdmin} />
                        </div>
                    </div>
                </div>
            </div>

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="¿Está seguro que desea cerrar el ticket?"
                send={() => {
                    handleChangeStatus('closed');
                }}
            />
        </>
    )
}