import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Box from 'elements/Box';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export function WorkstationsPerCity(params: any) {

  const { workstationsPerCity } = params;
  
  const labels = workstationsPerCity.labels;


  const data = {
    labels: workstationsPerCity.labels,
    datasets: [
      {
        data: workstationsPerCity.quantity,
        backgroundColor: [
          "#1C72E2",
          "#87CFD4",
          "#7F2FF2",
          "#FFD62C",
          "#F9F7EF",
          "#000000",
          "#000000"
        ],
        
      }
    ],
  };

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        
      },
    },
    maintainAspectRatio:false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
        pointStyle: 'circle',
        scale: {
          ticks: {
            mirror: true,
          },
        }
      },
      title: {
        display: false,
      },
    },
    



  };

  return (
    <Box title='Usuarios por ciudad'>
      <Bar data={data} options={options} style={{ maxHeight: '100%', height: '300px', maxWidth: '100%' }} />
    </Box>
  );
}

