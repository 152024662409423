import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, Chip, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import 'assets/css/main.css';
import AlertDialog from 'elements/AlertDialog';

const UserList = (props: any) => {

  const {setLoading} = props;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, [])

  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'user_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setUserList(response.data.user_list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/

  console.log("ul ",user_list); 

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (item_id:number) => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_user',
        user_id: item_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1);
      setDeleteItemId(null);
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  

  
  return (
    <>
        <div id='UsersList' className='ModuleContainer' style={{maxWidth: '800px'}}> 
          <div className="ModuleWrapper">
            <div className="boxContainerHeader">
              
              <div className="boxContainerTitle">
                <h2 className="h2_title">Usuarios</h2>
                <span className="h2_subtitle">Lista de usuarios registrados</span>
              </div>
              
              <div className="boxContainerActions">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="add"
                startIcon={<AddIcon />}
              >
                Crear usuario
              </Button>

              </div>
              
            </div>

            <div id='UsersListIn'>
              
                <Box id='boxUserList' className="box100">
                <TableContainer>
                <Table aria-label="sticky table"> 
                  <TableHead>
                    <TableRow>
                      <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>USUARIO</TableCell>
                      <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {user_list.map((user,i) => (
                        <TableRow 
                          
                          key={i}
                          hover
                          role="checkbox"
                        >
                          <TableCell>
                          <div className='tdwimg'>
                            <AppAvatar name={user.name} image="" />
                            <div className='flexTd'>
                              <span className='flexTd_bold'>{user.name} {user.last_name}</span>
                              <span className='flexTd_normal'>{user.email}</span>
                            </div>
                          </div>
                          </TableCell>



                          <TableCell align="right">

                            <Button
                              variant="contained"
                              component={Link}
                              to={`/users/edit/${user.user_id}`}
                              color="primary"
                              size="small"
                              style={{marginRight: "10px"}}
                              startIcon={<ModeEditIcon />}
                            >
                              Editar
                            </Button>


                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                setDeleteItemId(user.user_id);
                                setOpenDeleteDialog(true);
                              }}>
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
              <Pagination count={total_pages} page={page}  onChange={(event, value) => {
                setPage(value)
              }
              } 
              color="primary"
              style={{"marginTop": "20px","alignSelf": "center"}}
              />
              </Box>
    
            </div>
          </div>
      </div>

      <AlertDialog 
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        subtitle="¿Desea eliminar a este usuario?"
        send={() => handleDelete(Number(deleteItemId))}
      />
    </>
  );
};



export default UserList;