import React, { useState, useEffect, Fragment } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Box from 'elements/Box';
import { TimelineOppositeContent } from '@mui/lab';
import HotelIcon from '@mui/icons-material/Hotel';

import { Typography } from '@mui/material';

import 'assets/css/main.css';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import { AddCircle, CheckCircle, Info, Send } from '@mui/icons-material';


const cookies = new Cookies();

export default function TimeLine(props: any) {

  const {workstation_id} = props;

  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    //setList(fakeAPI);return;
    axios.get(ProjectConfig.api_url, {
      headers: {
        'LTKN': cookies.get('ltkn')
      },
      params: {
        request: 'get_workstation_logs',
        workstation_id: workstation_id,
      }
    })
    .then(function (response) {
      console.log(response.data);
      setList(response.data.list);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
    });
  }


  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {list.map((item, index) => (
        <Fragment key={index}>
          <TimelineItem sx={{width: '100%'}}>
            <TimelineSeparator className='timeLineSeparator'>
              <TimelineDot
                className='side-center'
                color={
                  !item.type ?
                  "grey":
                  (item.type =="created" ?
                    "info":
                    (item.type =="success" ?
                      "primary": 
                      "grey"))
                  }
              >
                {item.type === 'alert' && <Info />}
                {item.type === 'created' && <AddCircle />}
                {item.type === 'success' && <CheckCircle />}
                {item.type === 'sent' && <Send />}
              </TimelineDot>
            {index !== list.length - 1 && <TimelineConnector />}
          </TimelineSeparator>

          <TimelineContent>
            <Typography variant="overline" >
              {item.date}
            </Typography>

            <Box title={item.title} className="box100">
              <span className='timeline_desc'>{item.description}</span>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Fragment>
      ))}
    </Timeline>
  );
}