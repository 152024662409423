import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';

//import './FormDescription.css'

export default function FormDescription(props:any) {
    const {
        selectedForm,
        handleDiscard,
        handleReload,
    } = props;


    const handleSubmit = () => {
        handleReload();
    };

    return (
        <>
            <DialogContent
                className="forced_box_editor"
                sx={{ width: '100%', minWidth: '500px'}}
            >
                <div className="dialog_header">
                    <h2 className='dialog_h2'>
                        {(
                            selectedForm ?
                            selectedForm.form_name :
                            null
                        )}
                    </h2>
                    <DialogContentText>
                        Formulario
                    </DialogContentText>
                </div>

                <div className='formDescription'>
                    <Typography variant="body1">
                        {(
                            selectedForm ?
                            selectedForm.form_description :
                            null
                        )}
                    </Typography>
                </div>
                
                <div className='buttons'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDiscard() }
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit() }
                        autoFocus
                    >
                        Iniciar
                    </Button>
                </div>

            </DialogContent>
        </>
    );
};