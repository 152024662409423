import React, { useEffect, useState } from 'react';
import { useSnackbar, withSnackbar } from 'notistack';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    TextField
} from '@mui/material';
import { Close } from '@mui/icons-material';

const defaultValuesQuestion = {
    question_id: null,
    question_type: null,
    question: null,
    answers: [],
};

interface IAnswer {
    answers: {
        answer_id: number,
        haveComment: boolean,
        comment: string,
    }[],
    error: boolean,
}

const defaultValuesAnswer: IAnswer = {
    answers: [],
    error: false,
}

export default function FormQuestionChoices(props:any) {
    const {
        questionProp,
        isLast,
        index,
        handleNext,
        handleBack,
        checkForm,
        handleUpdateAnswers,
        handleSave,
        handleClose,
    } = props;

    const [question, setQuestion] = useState<any>({...defaultValuesQuestion});
    const [userAnswer, setUserAnswer] = useState<IAnswer>({...defaultValuesAnswer});

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    useEffect(() => {
        if(questionProp){
            setQuestion({...questionProp});
        } else {
            setQuestion({...defaultValuesAnswer});
        }
        setUserAnswer({...defaultValuesAnswer});
    },[questionProp]);

    const handleSelectAnswer = (answer_id:number, haveComment: boolean = false) => {
        const indexChecked = userAnswer.answers.find(x => x.answer_id === answer_id);

        if(indexChecked !== undefined){
            const newAnswers = userAnswer.answers.filter(x => x.answer_id !== answer_id);
            setUserAnswer({...userAnswer, answers: [...newAnswers]});
        } else {
            setUserAnswer({...userAnswer, answers: [...userAnswer.answers, {answer_id: answer_id, comment: '', haveComment: haveComment}]});
        }
    }
    
    const handleClickNext = () => {
        if (!checkForm(question.question_type,userAnswer,setUserAnswer)) {
            handleNext();
            handleUpdateAnswers({question_id: question.question_id, question_type: question.question_type, answers: userAnswer.answers});
            if (isLast) {
                handleSave();
            }
        } else {
            enqueueSnackbar('Errores en el formulario', {variant: 'error'});
        }
    }

    return (
        <>
            <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <Close />
            </IconButton>

            
            <DialogContent
                className='forced_box_editor'
                sx={{ width: '100%', minWidth: '500px'}}
            >
                <div className="dialog_header" style={{paddingRight: '40px'}}>
                    <h2 className='dialog_h2'>{ question.question }</h2>
                    <DialogContentText>{ question.question_type }</DialogContentText>

                </div>

                <div
                    className='formQuestionChoices'
                >
                    {(question.answers.map((item:any) => (
                        <Box
                            className='formQuestionChoiceItem'
                            key={item.answer_id}
                            sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%'}}
                        >
                            <FormControl className='formItem' sx={{width: 'fit-content'}}>
                                <FormControlLabel control={
                                    <Checkbox
                                        className='formInput'
                                        checked={ userAnswer.answers.find(x => x.answer_id === item.answer_id) !== undefined }
                                        onChange={() => handleSelectAnswer(item.answer_id, item.haveComment)}
                                    />
                                    }
                                    label={item.answer}
                                />
                            </FormControl>

                            { (item.haveComment && userAnswer.answers.find(x => x.answer_id === item.answer_id) !== undefined) &&
                            <FormControl>
                                <TextField
                                    className='formInput'
                                    size='small'
                                    label={item.labelComment}
                                    value={ userAnswer.answers.find(x => x.answer_id === item.answer_id)?.comment}
                                    onChange={(e) => {
                                        const indexComment = userAnswer.answers.find(x => x.answer_id === item.answer_id);
                                        if (indexComment !== undefined){
                                            indexComment.comment = e.target.value;
                                            const newAnswers = userAnswer.answers.filter(x => x.answer_id !== item.answer_id);
                                            setUserAnswer({...userAnswer, answers: [...newAnswers, {...indexComment}]});
                                        }
                                    }}
                                    />

                            </FormControl>
                            }
                        </Box>
                    )))}
                </div>

                <div className='buttons'>
                    { index !== 0 &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleBack() }
                    >
                        Anterior
                    </Button>
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ () => handleClickNext() }
                        autoFocus
                    >
                        { !isLast ?
                        <>Siguiente</>
                        :
                        <>Finalizar</>
                        }
                    </Button>
                </div>
            </DialogContent>

        </>
    );
};