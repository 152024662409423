import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, Chip, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from 'elements/AlertDialog';


import 'assets/css/main.css';
import { Edit } from '@mui/icons-material';
import EmptyElement from 'elements/EmptyElement';


const cookies = new Cookies();

export default function KitsList(props: any) {

  const {setLoading} = props;

  const company_id = cookies.get('company_id');

  useEffect(() => {
    setLoading(false);
  }, []);
  

  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(false)
      axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
          'COMPANYID': company_id
        },
        params: {
          request: 'kits_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList, company_id])
  /*=========================================================*/


  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (kit_id:number) => {

    setActiveItemId(kit_id);
    setOpenAlert(true);


  }
  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem_id, setActiveItemId] = useState(0);

const confirm = () => {
  axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_kit',
        kit_id: activeItem_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1)
    })
    .catch((err) => {
      console.log(err.data);
    })
}

  
  return (
    <div id="KitsList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Kits</h2>
            <span className="h2_subtitle">Listado</span>
          </div>

          <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="add"
              startIcon={<AddIcon />}
            >
              Crear KIT
            </Button>
          </div>
        </div>

        
        <div id="KitsListIn">
          <Box id='list' className='box100'>
          {list.length == 0 ? (
            <EmptyElement title="No hay kits" subtitle="Empieza creando un nuevo kit" />
            ) :
            (
            <Table> 
              <TableHead>
                <TableRow>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>KIT ID</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE DEL KIT</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>CANTIDAD DE PRODUCTOS</TableCell>
                  
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((listItem,i) => (
                  <TableRow 
                    
                    key={i}
                    hover
                    role="checkbox"
                  >
                    <TableCell>{listItem.kit_id}</TableCell>
                    <TableCell>{listItem.kit_name}</TableCell>
                    <TableCell><Chip label={listItem.products_quantity+" productos"} color="primary" variant='outlined' /></TableCell>


                    <TableCell align="right"> 
                      <Button
                        component={Link}
                        to={`/kits/edit/${listItem.kit_id}`}
                        variant="contained"
                        size='small'
                        startIcon={<Edit />}
                      >
                        Editar
                      </Button>

                      <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(listItem.kit_id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>

                      
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            )}
          
            {total_pages > 1 && (
              <Pagination count={total_pages} page={page}  onChange={(event, value) => {
                setPage(value)
              }
              } 
              color="primary"
              style={{"marginTop": "20px","alignSelf": "center"}}
              />
            )}
          </Box>

          <AlertDialog 
            subtitle="¿Estás seguro de eliminar este kit?"
            open={openAlert}
            setOpen={setOpenAlert}
            send={confirm}
            />
        </div>
      </div>
    </div>
  );
};

