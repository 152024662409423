/**
 * third-party dependencies
 */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField
} from '@mui/material';

/**
 * styles dependencies
 */

import '../styles/forms.css';

/**
 * project dependencies
 */
import { ProjectConfig } from 'Global';
import { Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

/**
 * read-only variables
 */
const cookies = new Cookies();

const defaultValues: any = {
    form_id: "null",
    form_name: "",
    form_description: "",
    finished_message: "",
};

export default function DialogForm(props: any) {
    // PROPS INITIALIZATION
    const {
        setLoading,
        open,
        setOpen,
        selectedItem,
        handleClose,
        handleReload,
    } = props;


    // HOOKS INITIALIZATION
    const [formValues, setFormValues] = useState({...defaultValues});
    
    useEffect(() => {
        if (selectedItem) {
            setFormValues({...selectedItem});
        } else{
            setFormValues({...defaultValues});
        }
    }, [selectedItem, open]);

    
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar } = useSnackbar();
    /*============================================= */


    // HANDLER FUNCTIONS
    const handleChanges = (event: any) => {
        setFormValues({...formValues,...{[event.target.name]: event.target.value}});
    };

    const handleSubmit = () => {
        setLoading(true);
        
        // AXIOS POST
        axios
          .post(ProjectConfig.api_url, formValues, {
            headers: {
                'LTKN': cookies.get('ltkn'),
                'COMPANYID': cookies.get('company_id'),
            },
            params: {
                request: "add_edit_form",
            },
        })
        .then((response) => {
            setOpen(false);
            handleReload();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
            handleDiscard();
        });
    };

    function handleDiscard(){
        setOpen(false);
    }

    const checkForm = () => {
        let errors = false;

        if(formValues.form_name === ""){
            errors = true;
        }

        return errors;
    }

    // RENDER
    return (
        <Dialog
            open={open}
            onClose={(event: object,reason:string) => handleClose(reason, setOpen)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent className="forced_box_editor">
                <div className="dialog_header">
                    <h2 style={{marginBottom:'0'}}>
                        {(
                            !selectedItem ?
                            'Nuevo formulario' :
                            'Editar formulario'
                        )}
                    </h2>
                    <DialogContentText>
                        Ingrese los datos del formulario
                    </DialogContentText>
                </div>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '10px',
                        m: 'auto',
                        p: '10px',
                        width: '380px',
                    }}
                    className='formContainer'
                >

                    <FormControl className='formItem'>
                        <TextField
                            required
                            label='Nombre del formulario'
                            className='formInput'
                            placeholder='Nombre del formulario'
                            name='form_name'
                            value={formValues.form_name}
                            onChange={(e) => handleChanges(e)}
                        />
                    </FormControl>

                    <FormControl className='formItem'>
                        <TextField
                            label='Descripción'
                            className='formInput'
                            placeholder='Descripción'
                            name='form_description'
                            multiline
                            rows={3}
                            value={formValues.form_description}
                            onChange={(e) => handleChanges(e)}
                        />
                    </FormControl>
                    
                    <FormControl className='formItem'>
                        <TextField
                            label='Mensaje al enviar'
                            className='formInput'
                            placeholder='Mensaje al enviar'
                            name='finished_message'
                            multiline
                            rows={2}
                            value={formValues.finished_message}
                            onChange={(e) => handleChanges(e)}
                        />
                    </FormControl>
                </Box>
                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleDiscard() }
                    >
                        Cancelar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Save />}
                        onClick={() => {
                            if(!checkForm()){
                                handleSubmit()
                            } else {
                                enqueueSnackbar('Debe completar todos los campos obligatorios', { variant: 'error' });
                            }
                        }}
                        autoFocus
                    >
                        Guardar
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}