/**
 * third-party dependencies
 */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
    Button,
    IconButton,
    List,
    ListItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    Add,
    DeleteOutline,
    KeyboardArrowUp,
    KeyboardArrowDown,
    ModeEditOutline,
    Save,
} from '@mui/icons-material';

/**
 * styles dependencies
*/
import './styles/forms.css';
import 'assets/css/main.css';


/**
 * project dependencies
*/
import DialogQuestion from './Dialogs/DialogQuestion';
import { ProjectConfig } from 'Global';
import Box from 'elements/Box';
import AlertDialog from 'elements/AlertDialog';

/**
 * read-only variables
 */
const cookies = new Cookies();

export default function DialogQuestions(props: any) {
    // PROPS
    const {
        setLoading,
        isAdmin = false,
    } = props;

    // HOOKS AND PARAMS INITIALIZATION
    const [openAnswers, setOpenAnswers] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const { form_id = 0 } = useParams();

    useEffect(() => {
        setLoading(true);
        loadList(); // AXIOS GET
        setLoading(false);
    }, []);

    const loadList = () => {
        setLoading(true);
        //HEADERS AND PARAMS
        const {headers, params} = getHeaderNParams();

        //AXIOS GET
        axios.get(ProjectConfig.api_url,
            {
              headers: {
                  ...headers,
              },
              params: {
                request: 'get_questions_list',

                params: {
                    form_id: form_id,
                    ...params,
                },
                
              }
            })
              .then((response) => {
                console.log(response.data);
                setList(response.data);
              })
              .catch((err) => {
                console.log(err.data);
              })
              .finally(() => {
                  setLoading(false);
              });
    };

    // HANDLER FUNCTIONS
    function handleClickAddQuestion(){
        setSelectedItem(null);
        setOpenAnswers(true);
    }
    
    function handleClickOpenEditMode(item: any) {
        setSelectedItem(item);
        setOpenAnswers(true);
    }

    function handleClickDelete() {
        setLoading(true);

        const {headers, params} = getHeaderNParams();
        
        //AXIOS POST
        axios.post(ProjectConfig.api_url,null,{
            headers: {
                ...headers,
            },
            params: {
                request: 'delete_question',
                question_id: selectedItem.question_id,
                ...params,
            }
            })
            .then((response) => {
                loadList();
            })
            .catch((err) => {
                console.log(err.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    const handleClose = (reason: string, openSetter: CallableFunction) => {
        if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            openSetter(false)
        }
    };

    function handleChangePosition(index: number, numMovs: number) {
        const newList = [...list];
        const item = newList[index];
        newList.splice(index, 1);
        newList.splice(index + numMovs, 0, item);
        setList(newList);
    }

    function handleClickSaveQuestion() {
        return;
        setLoading(true);
        
        //AXIOS POST
        axios.post(ProjectConfig.api_url,null,{
            headers: {
                'COMPANYID': cookies.get('company_id'),
            },
            params: {
                request: 'save_questions_list',
                params: {
                    form_id: form_id,
                    questions: list,
                }
            }
        })
        .then((response) => {
            loadList();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getHeaderNParams = () => {
        //HEADERS AND PARAMS
        const headers: any = {}, params: any = {};
        if (isAdmin){
            params.isAdmin = true;
        } else {
            headers.COMPANYID = cookies.get('company_id');
        }
        return {headers, params};
    };

   

    return (
        <div id="QuestionList" className="ModuleContainer">
            <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                        <h2 className="h2_title">Preguntas</h2>
                        <span className="h2_subtitle">Listado</span>
                    </div>

                    <div className="boxContainerActions">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => handleClickAddQuestion()}
                        >
                            Agregar pregunta
                        </Button>
                        
                        {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Save />}
                            onClick={() => handleClickSaveQuestion()}
                        >
                            Guardar
                        </Button> */}
                       
                    </div>
                </div>

                <div id='QuestionListIn'>
                    <Box id='list' className='box100'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/**<TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>*/}
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}} className='table-header'>PREGUNTA</TableCell>
                                    <TableCell  align='right' sx={{color:'#a8a8a8', borderBottom:"none"}} className='table-header'>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {list.map((item: any, index) => {


                                const typeLabel = item.question_type === 'unique' ? 'Única respuesta' : item.question_type === 'multiple' ? 'Múltiple respuesta' : 'Respuesta abierta';
                                
                                return(
                            <TableRow
                                key={item.question_id}
                                hover
                                role="checkbox"
                            >
                                {/**<TableCell>
                                    <List className="compact-list" style={{display: 'hidden'}}>
                                        <ListItem sx={{pt:0,pb:0}}>
                                            <IconButton
                                                color='primary'
                                                onClick={() => handleChangePosition(index, -1)}
                                                disabled={index === 0}
                                                className='narrow-vertical-element compact-content'
                                                >
                                                <KeyboardArrowUp />
                                            </IconButton>
                                        </ListItem>

                                        <ListItem sx={{pt:0,pb:0}}>
                                            <IconButton
                                                color='primary'
                                                onClick={() => handleChangePosition(index, 1)}
                                                disabled={index === list.length - 1}
                                                className='narrow-vertical-element compact-content'
                                                >
                                                <KeyboardArrowDown />
                                            </IconButton>
                                        </ListItem>
                                    </List>
                            </TableCell>*/}
                                <TableCell>
                                    <div className='flexTd'>
                                        <span className='flexTd_bold'>{ item.question }</span>
                                        <span className='flexTd_normal'>{typeLabel}</span>
                                    </div>
                                </TableCell>
                                <TableCell className="list-item-medium compact-content equalize">
                                    <div className='boxContainerActions'>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            onClick={() => handleClickOpenEditMode(item)}
                                            className='compact-list'
                                            sx={{flexDirection:'row',width:'auto'}}
                                            startIcon={ <ModeEditOutline /> }
                                            >
                                            Editar
                                        </Button>
                                        {/* <Button
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            onClick={ () => {
                                                setSelectedItem(item);
                                                setOpenAlert(true);
                                            }}
                                            className='compact-list'
                                            sx={{flexDirection:'row',width:'auto'}}
                                            startIcon={ <DeleteOutline /> }
                                            >
                                            Eliminar
                                        </Button> */}
                                        <IconButton
                                            
                                            size='small'
                                            aria-label="delete"
                                            onClick={ () => {
                                                setSelectedItem(item);
                                                setOpenAlert(true);
                                            }}
                                            className='compact-list'
                                            sx={{flexDirection:'row',width:'auto'}}
                                            >
                                            <DeleteOutline />
                                        </IconButton>
                                            
                                    </div>
                                </TableCell>
                            </TableRow>
                            )})}
                        </TableBody>
                    </Table>
                </Box>
                </div>
            </div>

            <DialogQuestion
                open={openAnswers}
                setOpen={setOpenAnswers}
                handleClose={handleClose}
                handleReload={loadList}
                selectedItem={selectedItem}
                setLoading={setLoading}
                formId={form_id}
            />

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="¿Está seguro que desea eliminar esta pregunta?"
                send={handleClickDelete}
            />
        </div>
    );
}