import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Badge, Button, Checkbox, Chip, CircularProgress, Drawer, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AddWorkstation from '../Workstations/Utils/AddWorkstation';
import Cookies from 'universal-cookie';
import AlertDialog from 'elements/AlertDialog';
import AddProductInvitation from '../Workstations/Utils/AddProductInvitation';
import WorkstationsOrders from './WorkstationsOrders';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import ServiceDetails from './ServiceDetails';

const cookies = new Cookies();

export default function ServiceList(props: any) {

  const {setLoading, forceUpdate, setForceUpdate} = props;

  const [list, setList] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  

  
  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadList()
  }, [page, forceUpdate])
  /*=========================================================*/


  const loadList = () => {

    setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'services_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
  }

  const [activeService, setActiveService] = useState(0);
  const [openServiceDetails, setOpenServiceDetails] = useState(false);

  const handleOpenServiceDetails = (service_id: number) => {
    setActiveService(service_id);
    setOpenServiceDetails(true);
  }
  

  
  return (
    <>
      <ServiceDetails 
        setOpen={setOpenServiceDetails} 
        open={openServiceDetails} 
        service_id={activeService} 
        setLoading={setLoading}
        loadServiceList={loadList}
      />


      <div id='ServicesList' className='ModuleContainer'>
        <div className='ModuleWrapper'>
          <div id="userWorkstationsHeader" className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Servicios</h2>
              <span className="h2_subtitle">Lista de pedidos de productos para puestos</span>
            </div>
            
            <div className="boxContainerActions">
            
            </div>
            
          </div>

          <div id='ServicesListIn'>
            <Box id='services_list' title="" subtitle=""  className="box100">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ID</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>TIPO</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>CREACÍON</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ESTADO</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>CANTIDAD PRODUCTOS</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {list.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{item.service_id}</TableCell>
                    <TableCell>
                      <Chip 
                        label={item.service_type_id == 1 ? "ENVÍO" : "RETIRO"} 
                        size='small' 
                        color={item.service_type_id == 1 ? 'primary' : 'error'} 
                        sx={{marginLeft: "10px",}} 
                        icon={item.service_type_id == 1 ? <FileUploadIcon sx={{paddingLeft: "5px",}} /> : <FileDownloadIcon sx={{paddingLeft: "5px", }} />}
                      />
                    </TableCell>

                    <TableCell>
                      <div className='flexTd'>
                        <span className='flexTd_bold'>{moment(item.creation_date).format('DD/MM/YYYY')}</span>
                        <span className='flexTd_normal'>{moment(item.creation_date).format('HH:mm')}</span>
                      </div>
                    </TableCell>


                    <TableCell>
                      <Chip 
                        label={item.service_status} 
                        icon={item.service_status_id == 0? <HowToRegIcon sx={{paddingLeft: "5px",}} /> : item.service_status_id == 1? <LocalShippingIcon sx={{paddingLeft: "5px",}} /> : <EmojiFlagsIcon sx={{paddingLeft: "5px",}} />}
                        variant='outlined' 
                        color={item.service_status_id == 0 ? 'error' : item.service_status_id == 1 ? 'success' : 'primary'} 
                      />
                        
                    </TableCell>

                    

                    <TableCell>{item.products_quantity}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleOpenServiceDetails(item.service_id)}
                      >
                        GESTIONAR
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>


              </Table>
              
                
            </Box>
          </div>
        </div>
      </div>
      
    </>
  );
};

