import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";  
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from "icons/ic_dots.svg";
import {ProjectConfig} from "Global";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FilesBrowser from "../Files/FilesBrowser";
import AutocompleteProduct from "elements/AutoCompleteProduct";

export default function ProductForm(props: any){

  const {
    is_child = false,
  } = props;

  const productValues = props.productValues;
  const supplier_list = props.supplier_list;
  const index = props.index;
  const is_group = props.is_group;

  

  
  //function updateProduct

  const updateProduct = (product: any) => {
    props.updateProduct(product, index);
  };
    

  /* =================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
  interface ISupplierList {
    inputValue?: string;
    supplier_id?: number;
    name: string;
  }
  const filter = createFilterOptions<ISupplierList>();
  const [supplier, setSupplier] = useState<ISupplierList | null>(null);

  /* ================================================= 
    ===================================================*/

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    updateProduct({
      //update the productValues index 
      ...productValues,
      [name]: value,
    });
  };








  const handlePriceChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    updateProduct({
      ...productValues,
      [name]: value,
    });
    
    
  }

  useEffect(() => {
    setSupplier({
      name: productValues.supplier_name
    })
  }, [productValues.supplier_name]);

  //HANDLE SELECT PRODUCT CATEGORY
  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    updateProduct({
      ...productValues,
      ["category_id"]: event.target.value as string,
    });
  };



  const handleOpenSupplierList = () => {
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);




    /*=======================================
    PUSH NEW ITEM 
    =======================================*/
    const handleAddItem = () => {
        
      const newWoodDimensions = [...productValues.wood_dimensions];
      newWoodDimensions.push({
        width: 0,
        length: 0,
        thickness: 0,
        quantity: 0,
        boardFeet: 0,
      });
      
      updateProduct({
        ...productValues,
        wood_dimensions: newWoodDimensions,
      });


    }; 
    /*=======================================
    =======================================*/

    /*=======================================
    DELETE WOOD DIMENSION
    =======================================*/
    const handleDeleteWoodDimension = (index: number) => {
      const newWoodDimensions = [...productValues.wood_dimensions];
      newWoodDimensions.splice(index, 1);

      //STOCK QUANTITY = SUM OF WOOD DIMENSIONS BOARD FEET
      let product_stock = 0;
      newWoodDimensions.map((item: any) => {
        product_stock += parseInt(item.boardFeet);
      });

      
      updateProduct({
        ...productValues,
        wood_dimensions: newWoodDimensions,
        product_stock: product_stock,
      });

    }; 
    /*=======================================
    =======================================*/


    /*=======================================
    CHANGE WOOD DIMENSION
    =======================================*/
    const handleWoodDimensionsChange = (e: any, index: any) => {
      const { name, value } = e.target;
      const newWoodDimensions = [...productValues.wood_dimensions];
      newWoodDimensions[index][name] = value;

      const bf = Math.round((newWoodDimensions[index].width * newWoodDimensions[index].length * newWoodDimensions[index].thickness) / 144) * newWoodDimensions[index].quantity;
      newWoodDimensions[index].boardFeet = bf;
      
      updateProduct({
        ...productValues,
        wood_dimensions: newWoodDimensions,
      });

      //STOCK QUANTITY = SUM OF WOOD DIMENSIONS BOARD FEET
      let product_stock = 0;
      newWoodDimensions.map((item: any) => {
        product_stock += item.boardFeet;
      });
      updateProduct({
        ...productValues,
        product_stock: product_stock,
      });
    };
    /*=======================================
    =======================================*/


    const setProduct = (product_name:any, product_id:any) => {

      //get product data
      axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "product_details",
              product_id: [product_id],
            },
          })
          .then((response) => {
            
            //UPDATE product WITH RESPONSE
            updateProduct(response.data[0]);
            
          })
          .catch((err) => {
            console.log(err.data);
          });

      //update productValues.product_name
      
    }

    const addProduct = (product_name: any) => {
      updateProduct({
        ...productValues,
        ["product_id"]: null,
        ["product_name"]: product_name,
      });

    }
    

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ outline: "none" }}>
          <Box
            id="supplier_list"
            title="Proveedores"
            subtitle="Lista"
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre proveedor</TableCell>
                    <TableCell>NIT</TableCell>
                    <TableCell>Direccíon</TableCell>
                    <TableCell>Forma de pago</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplier_list.map((supplier: any, i: any) => (
                    <TableRow
                      onClick={() => (
                        setSupplier({
                          name: supplier.name,
                        }),
                        updateProduct({
                          ...productValues,
                          ["supplier_id"]: supplier.supplier_id.toString(),
                        }),
                        handleClose()
                      )}
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{supplier.name}</TableCell>
                      <TableCell>{supplier.tin}</TableCell>
                      <TableCell>{supplier.address}</TableCell>
                      <TableCell>{supplier.payment_terms}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </Modal>
      
    <Box
      className="product_form"
      title={!is_child ? "" : 'Usuario'} subtitle={!is_child ? "" : 'Editar'}
    >
      <div className="form">
        <div className="input_group">

          <div className='input_group'>
            <h2>Imágenes</h2>
            {productValues.product_id && productValues.folder_id!=0 && productValues.folder_id!=undefined && (
            <FilesBrowser 
                folder_id={productValues.folder_id} 
                view_type="thumbnail"
                hide_folder_button={true}
            />
            )}

            {(!productValues.product_id || productValues.folder_id==0 || productValues.folder_id==undefined) && (<div className='msg_save_quote'>
                <SaveOutlinedIcon />
                <p>Guarda el producto para subir imágenes</p>
            </div>)}
          </div>
          
          {is_group? (
            <AutocompleteProduct 
                setProduct={setProduct} 
                product_name={productValues.product_name}
                addProduct={addProduct}
                hide_groups={true}
            />
          ) : (
            <Fragment>
              

              <TextField
                id="product_name"
                name="product_name"
                label="Nombre del producto"
                type="text"
                required
                fullWidth
                value={productValues.product_name}
                onChange={handleInputChange}
              />
            </Fragment>
          )}

          <div className="input_row">
            
            <TextField
              id="shofi_code"
              name="shofi_code"
              label="Código SHOFI"
              type="text"
              required
              fullWidth
              value={productValues.shofi_code}
              onChange={handleInputChange}
            />
            <TextField
              id="supplier_code"
              name="supplier_code"
              label="Código proveedor"
              type="text"
              required
              fullWidth
              value={productValues.supplier_code}
              onChange={handleInputChange}
            />
          </div>
        
          <div className="input_row">
            <FormControl fullWidth>
              <InputLabel id="lbl_product_category">Categoría</InputLabel>
              <Select
                labelId="product_category"
                id="product_category"
                value={productValues.category_id}
                label="Categoría"
                onChange={handleChange}
              >
                {props.category_list.map((category: any, index: any) => {
                  return(
                    <MenuItem key={index} value={category.category_id}>{category.name}</MenuItem>
                )})}
              </Select>
            </FormControl>
            
            <Autocomplete
              value={supplier}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setSupplier({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setSupplier({
                    name: newValue.inputValue,
                  });
                } else {
                  setSupplier(newValue);
                  if (newValue?.supplier_id != null) {
                    updateProduct({
                      ...productValues,
                      ["supplier_id"]: newValue.supplier_id.toString(),
                    });
                  } else {
                    updateProduct({
                      ...productValues,
                      ["supplier_id"]: "",
                    });
                  }
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              fullWidth
              id="category"
              options={supplier_list}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              sx={{  }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Proveedor"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <IconButton onClick={handleOpenSupplierList}
                        style={{ padding: "0px 5px" }}
                      >
                        <img src={ic_dots}></img>
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>

          <h2>Inventario</h2>
          <div className="input_row">
            <TextField
              id="product_stock"
              name="product_stock"
              label="Stock"
              type="number"
              required
              fullWidth
              //IF WOOD DISABLE
              {...(productValues.category === "wood" && { disabled: true })}

              InputProps={{
                endAdornment: productValues.category === "wood" && (
                  <Tooltip title="Stock quantity is updated by Wood Dimensions">
                    <InputAdornment position="end">
                      BF
                    </InputAdornment>
                  </Tooltip>
                ),
              }}


        
              value={productValues.product_stock}
              onChange={handleInputChange}
            />

            <TextField
              id="minimum_stock"
              name="minimum_stock"
              label="Stock mínimo"
              type="number"
              required
              fullWidth
              value={productValues.minimum_stock}
              onChange={handleInputChange}
            />
          </div>

          <h2>Precio</h2>
          <div className="input_row">
            
            <TextField
              id="unit_price"
              name="unit_price"
              label="Unit Price"
              type="number"
              required
              fullWidth
              value={productValues.unit_price}
              onChange={handlePriceChange}
            />


          </div>

          <TextField
            id="product_description"
            name="product_description"
            label="Product Description"
            type="text"
            size="small"
            required
            fullWidth
            multiline
            rows={4}
            value={productValues.product_description}
            onChange={handleInputChange}
          />


          

          

                    

        </div>
      </div>
    </Box>
    </Fragment>
  );
};

