import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import 'assets/css/main.css';
import { ArrowBack, Save } from "@mui/icons-material";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')

  interface Props {
    account_user_id?: any,
  }

 export default function UserEditor(props: any) {


  const {
    setLoading,
    is_child = false,
    is_my_account = false,
  } = props;

  useEffect(() => {
    setLoading(false);
  }, [])

  const {popupSubmit,setPopupOpen, company_id} = props;

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {user_id } = useParams();
  user_id = props.account_user_id ? props.account_user_id : user_id;

  /*=======================================
  =======================================*/
  

  const navigate = useNavigate();
  const defaultValues: any = {
    user_id: user_id? user_id : "null",
    name: "",
    last_name: "",
    phone: "",
    image: "",
    email: "",
    password: "",
    role_id: "",
    is_active: true
  };
  
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  const handleSubmit = () => {


    const formData = new FormData();
    formData.append("files", files);
    
    axios.post(ProjectConfig.api_url, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          request: 'add_edit_user',
          params:{
            formdata: formValues,
            company_id: company_id? company_id : 0
          }
        }
      })
        .then((response) => {
          console.log(response.data);

          if(popupSubmit) {
            popupSubmit();
          }
          else
          {
            if(props.account_user_id){
              //UPDATE LOCAL STORAGE IMAGE
              localStorage.setItem('user_image', response.data.image);
              window.location.reload();
            } else{
              navigate('/users');
            }

          }
          
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/
  console.log("user_id: ", user_id);
  useEffect(() => {
    if (user_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "user_details",
            user_id: user_id,
          },
        })
        .then((response) => {
          
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);


  return (
    <>
      <div id="FormsList" className="ModuleContainer" style={{maxWidth: '502px'}}>
        <div id="FormListWrapper" className="ModuleWrapper">
        { !is_child &&
          (
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Usuario</h2>
              <span className="h2_subtitle">Editar</span>
            </div>

            <div className="boxContainerActions">
              {(!is_my_account) && (
              <Button
                onClick={() => {
                  popupSubmit? setPopupOpen(false) : navigate('/users', { replace: true })
                }}
                variant="outlined"
                className="button_2"
                startIcon={<ArrowBack />}
              >
                Volver
              </Button>
              )}
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="button_1"
                startIcon={<Save />}
              >
                Guardar
              </Button>
            </div>
          </div>
          )}

          <div id="userForm">
            <div className="userForm_right">

              <Box id="project_editor" title={!is_child ? "" : 'Usuario'} subtitle={!is_child ? "" : 'Editar'} className={!is_child ? "" : 'box_editor'}>
                <div className='form'>

                <div className='input_group'>
                    <h2>Información de usuario</h2>
                    <div className='input_row'>

                      {/* UPLOAD button with preview */}
                      <div className="drawerImagesContainer">
                                  
                        <div className="drawerImagesContainerDiv"
                          onDrop={(e) => {
                              e.preventDefault();
                              // UPDATE FILE
                              const files = e.dataTransfer.files;
                              setFiles(files[0]);
                              const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                              setFormValues({
                                ...formValues,
                                image: fileArray[0],
                              });
                              e.currentTarget.classList.remove("dropzone_active");
                            }
                            } 
                            onDragOver={
                              (e) => {
                                e.preventDefault();
                                e.currentTarget.classList.add("dropzone_active");
                              }
                              
                            }
                            onDragLeave={
                              (e) => {
                                e.preventDefault();
                                e.currentTarget.classList.remove("dropzone_active");
                              }
                            }
                        
                            >
                          {formValues.image && (
                            <img src={formValues.image}  />
                            )}
                          
                          <label id='dropFileDiv' className={formValues.image && ('showOnlyOnHover')}>
                            <IconButton 
                              aria-label="delete"
                              size="small"
                              className="addFile_button"
                              component="span"
                              >
                              <CameraAltIcon/>
                              <input 
                                type="file"
                                multiple 
                                hidden 
                                onChange={(e) => {
                                  const files = e.target.files;
                                  if (files) {
                                    
                                    // UPDATE FILE
                                    setFiles(files[0]);
                                    const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                    setFormValues({
                                      ...formValues,
                                      image: fileArray[0],
                                    });
                                    
                                  }

                                }}
                              />
                              
                            </IconButton>
                          </label>
                          
                        </div>

                      </div>
                    

                              
                      <div className='inputColumn'>
                        <TextField 
                          id="name"
                          name="name"
                          label="Nombre"
                          type="text"
                          required
                          fullWidth
                          value={formValues.name}
                          onChange={handleInputChange}
                          />

                      
                      
                      </div>
                    </div>
                  </div>

                    <div className='input_group'>
                      <h2>Información para inicio de sesión</h2>
                      <div className="input_row">
                        <TextField
                          id="email"
                          name="email"
                          label="Correo electrónico"
                          type="text"
                          required
                          fullWidth
                          autoComplete="NO"
                          value={formValues.email}
                          onChange={handleInputChange}
                          />

                        <TextField
                          id="password"
                          name="password"
                          label="Contraseña"
                          type="password"
                          required
                          fullWidth
                          autoComplete="NO"
                          value={formValues.password}
                          onChange={handleInputChange}
                          />
                      </div>
                    </div>


                  
                  {!props.account_user_id && (
                    <FormControlLabel
                    checked={formValues.is_active}
                    
                    control={
                      <IOSSwitch 
                      sx={{ m: 1 }}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          ["is_active"]: e.target.checked
                        });
                      }
                    }
                    />
                  }
                  label="Activo"
                  />
                  )}
                </div>
                { is_child && (
                <div className='buttons'>
                  <Button
                    onClick={() => {
                      popupSubmit? setPopupOpen(false) : navigate('/users', { replace: true })
                    }}
                    variant="outlined"
                    className="button_2"
                  >
                    Cancelar
                  </Button>
                  <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
                </div>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
    
  );
};