import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material"
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import ic_empty from 'icons/ic_empty.svg';
import Cookies from 'universal-cookie';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Store from "../Store/Store";
import Joyride from "react-joyride";

import 'assets/css/main.css';
import { Save } from "@mui/icons-material";

const cookies = new Cookies();

const joyTour = {
  steps: [
    {
      target: "#kit_name",
      content: "Primero, ingresa el nombre de tu kit."
    },
    {
      target: "#kit_add_product_button",
      content: "Luego, da clic en agregar producto para incluir los productos que desees en tu kit."
    },
    {
      target: "#kit_add_save_button",
      content: "Finalmente, da clic en guardar para crear tu kit."
    }
  ],
  styles: {
    options: {
      primaryColor: "#0271e2",
      width: 300,
      zIndex: 10000,
    }
  },
  locale: {
    back: 'Anterior',
    close: 'Cerrar',
    last: 'Finalizar',
    next: 'Siguiente',
    open: 'Abrir diálogo de ayuda',
    skip: 'Saltar',
  },
};

export default function KitsEditor(props: any) {

  const company_id = cookies.get('company_id') || cookies.get('default_company_id');
  console.log("company_id", company_id);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isInTourMode, setIsInTourMode] = useState<boolean>( searchParams.get('tour') === 'true' ? true : false );
  const [stepIndex, setStepIndex] = useState<number>(0);

  const handleTourCallback = (data: any) => {
    const { action, index, type, status } = data;
    if (status === 'finished') {
      setIsInTourMode(false);
      setStepIndex(0);
      setSearchParams({ tour: 'false' });
    } else if (type === 'step:after') {
      const skipNumber = (action === 'next' || action === 'close') ? 1 : - 1;
      setStepIndex(index + skipNumber);
    }
  };

  const {setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  let { kit_id } = useParams();

  const defaultKitValues: any = {
    kit_id: null,
    kit_name: "",
    kit_products:[],
    kit_price: "",
  };

  const [errors, setErrors] = useState<any>({});

  const [formValues, setFormValues] = useState(defaultKitValues);
  
  const navigate = useNavigate();


  const handleInputChange = (e: { target: { name: any; value: any; required: any }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    //CHECK IF IS REQUIRED
    if(e.target.required){
      if(value === ""){
        setErrors({
          ...errors,
          [name]: true,
        });
      }
      else{
        //REMOVE KEY  IF EXISTS 
        if(errors[name]){
          delete errors[name];
          setErrors({
            ...errors,
          });
        }
      }
    }
    
      
  };

  const handleSubmit = () => {
    setLoading(true);
      console.log(formValues);
    //IF THERE ARE ERRORS DO NOT SUBMIT
    if(Object.keys(errors).length !== 0 || formValues.kit_products.length === 0){
      return false;
    }

    var formdata = new FormData();
    formdata.append("formdata", JSON.stringify(formValues));

    axios.post(ProjectConfig.api_url, formdata,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
          'COMPANYID': company_id
        },
        params: {
          request: 'add_edit_kit',
          dato: "áéíóúñ"
        }
      })
        .then((response) => {
          console.log("submit",response.data);

          if (isInTourMode) {
            setIsInTourMode(false);
            setSearchParams({ tour: 'false' });
          }
          
          if (response.data.tour) cookies.set('user_steps', response.data.tour);  // SAVE TOUR STEPS

          navigate('/kits');
        })
        .catch((err) => {
          console.log(err.data);
        })


        
  };

  /**/

  useEffect(() => {
    if (kit_id) {
      setLoading(true);
      axios
        .get(ProjectConfig.api_url, {
          headers:{
            'LTKN': cookies.get('ltkn'),
            'COMPANYID': company_id
          },
          params: {
            request: "kit_details",
            kit_id: kit_id,
          },
        })
        .then((response) => {
          console.log("kit_details",response.data);
          setFormValues(response.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);


  

  const [storeDialogOpen, setStoreDialogOpen] = useState(false);


  
  const addProductToKit = (productDetail:any) => {
    let kit_products = formValues.kit_products;
    kit_products.push(productDetail);
    setFormValues({
      ...formValues,
      kit_products: kit_products
    });
  }

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let total = 0;
    formValues.kit_products.forEach((product:any) => {
      total += parseFloat(product.prices[product.contract_time].price);
    });
    setTotalPrice(total);
  }, [formValues]);



  return (
    <>
      <div id="kitContainer" className="ModuleContainer">

        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Kits</h2>
              <span className="h2_subtitle">Crear / Editar </span>
            </div>
            
            <div className="boxContainerActions"> 
            
            </div>
            
          </div>

          <div className="kitContainerFlex">
            <div className="kitContainer_left">
              <Box id="KitsEditorContainer" className="box_editor">
                <div className='form'>
                    <div className='input_group'>
                      <div className="input_row">
                        <TextField
                              id="kit_name"
                              name="kit_name"
                              label="Nombre del kit"
                              type="text"
                              required
                              fullWidth
                              value={formValues.kit_name}
                              onChange={handleInputChange}
                              error={errors.kit_name}
                              helperText={errors.kit_name ? "Este campo es requerido" : ""}
                            />
                      </div>
                    </div>

                    <div id="kit_products_container">
                      <h2>Productos</h2>
                      <div id="kit_products">
                        {//if kit_products is not empty
                          formValues.kit_products.length > 0 ?
                          formValues.kit_products.map((product: any, index: any) => {
                            return (
                              <div className="kit_product" key={index}>
                                <IconButton
                                  aria-label="delete"
                                  className="deleteButtonOnImage"
                                  onClick={() => {
                                    let kit_products = formValues.kit_products;
                                    kit_products.splice(index, 1);
                                    setFormValues({
                                      ...formValues,
                                      kit_products: kit_products
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>

                                <div className="kit_product_image">
                                  <img src={product.images[0].image} alt=""/>
                                </div>
                                <div className="kit_product_info">
                                  <h3>{product.product_name}</h3>
                                </div>
                                <span className="kitProductPrice">$ {product.prices[product.contract_time].price.toLocaleString('es')}</span>
                              </div>
                            )
                          })
                          :
                          <div className="noProducts">
                            <img src={ic_empty} alt=""/>
                            <p>No hay productos en este kit</p>
                          </div>
                          }
                          
                      </div>
                        <Button
                          id="kit_add_product_button"
                          variant="contained"
                          color="primary"
                          className="button_1"
                          onClick={() => {
                            setStepIndex(joyTour.steps.length - 1);
                            setStoreDialogOpen(true);
                          }}
                          startIcon={<AddIcon />}
                        >
                          Agregar producto
                        </Button>
                    </div>
                </div>
                <div className='buttons'>
                  <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cerrar</Button>
                  <Button
                    id="kit_add_save_button"
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={<Save />}
                    className="button_1"
                  >
                    Guardar
                  </Button>
                </div>
              </Box>
            </div>

            <div className="kitContainer_right">

              <div className="kit_summary">
                <div className="kit_summary_item">
                <h3>Productos</h3>
                  <span>{formValues.kit_products.length}</span>
                  
                  
                </div>

                <div className="kit_summary_item">
                <h3>Precio / Mes</h3>
                  <span>$ {totalPrice.toLocaleString('es')}</span>
                  
                  
                </div>
                
              </div>

            </div>
          </div>
        </div>
      </div>
      

      <Store
        addProduct = {addProductToKit}
        setLoading = {setLoading}
        storeDialogOpen = {storeDialogOpen}
        setStoreDialogOpen = {setStoreDialogOpen}
      />

      {(isInTourMode && !storeDialogOpen) &&
      <Joyride
        styles={joyTour.styles}
        steps={joyTour.steps}
        locale={joyTour.locale}
        callback={handleTourCallback}
        stepIndex={stepIndex}
        showProgress
        continuous
        showSkipButton
      />
      }
    </>
  );
};