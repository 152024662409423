import { Button, IconButton, TextField } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import ic_empty from 'icons/ic_empty.svg';
import Cookies from 'universal-cookie';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Store from "components/Store/Store";
import { useSnackbar } from "notistack";

const cookies = new Cookies();


 export default function BudgetSelector(props: any) {

  const {setLoading, budget, data, setData} = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const defaultKitValues: any = {
    kit_products:[],
    kit_price: 0,
  };


   /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   /*============================================= */

  const [formValues, setFormValues] = useState(defaultKitValues);
  
  const navigate = useNavigate();




  const handleSubmit = () => {
    setLoading(true);
      console.log(formValues);
    //IF THERE ARE ERRORS DO NOT SUBMIT
    if(formValues.kit_products.length === 0){
      return false;
    }

    var formdata = new FormData();
        formdata.append("formdata", JSON.stringify(formValues));


    axios.post(ProjectConfig.api_url, formdata,
      {
        headers:{
          'LTKN': cookies.get('ltkn')
        },
        params: {
          request: 'add_edit_budget_kit',
        }
      })
        .then((response) => {
          console.log("submit",response.data);
          navigate('/kits');
        })
        .catch((err) => {
          console.log(err.data);
        })


        
  };



  

  const [storeDialogOpen, setStoreDialogOpen] = useState(false);

  
  const addProductToKit = (productDetail:any) => {
    let remainingBudget = budget;
    //CHECK BUDGET LEFT TO ADD PRODUCT
    formValues.kit_products.forEach((product:any) => {
        remainingBudget -= product.unit_price;
    });
    console.log(remainingBudget);

    if((remainingBudget - productDetail.unit_price) >= 0){
        let kit_products = formValues.kit_products;
        kit_products.push(productDetail);
        setFormValues({
        ...formValues,
        kit_products: kit_products
        });

        setData({
            ...data,
            invitation_products: kit_products
        });
    }else{
        enqueueSnackbar('Presupuesto insuficiente', { variant: 'error' });
    }
    
        

  }

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let total = 0;
    formValues.kit_products.forEach((product:any) => {
      total += parseFloat(product.unit_price);
    });
    setTotalPrice(total);
  }, [formValues]);



  return (
    <>
      <div id="kitContainer" className="ModuleContainer">




        <div className='form'>

            <div id="kit_products_container">
            <div id="kit_products">
                {//if kit_products is not empty
                formValues.kit_products.length > 0 ?
                formValues.kit_products.map((product: any, index: any) => {
                    return (
                    <div className="kit_product" key={index}>
                        <IconButton
                        aria-label="delete"
                        className="deleteButtonOnImage"
                        onClick={() => {
                            let kit_products = formValues.kit_products;
                            kit_products.splice(index, 1);
                            setFormValues({
                            ...formValues,
                            kit_products: kit_products
                            });
                        }}
                        >
                        <DeleteIcon />
                        </IconButton>

                        <div className="kit_product_image">
                        <img src={product.images[0].image} alt=""/>
                        </div>
                        <div className="kit_product_info">
                        <h3>{product.product_name}</h3>
                        </div>
                        <span className="kitProductPrice">$ {product.unit_price.toLocaleString('es')}</span>
                    </div>
                    )
                })
                :
                <div className="noProducts">
                    <img src={ic_empty} alt=""/>
                    <p>No hay productos en este kit</p>
                </div>
                }
                
            </div>
                <Button
                variant="contained"
                color="primary"
                className="button_1"
                onClick={() => setStoreDialogOpen(true)}
                startIcon={<AddIcon />}
                >
                Agregar producto
                </Button>
            
            </div>

        

            
            
            

        </div>

        <div className="budgetSummary">
            <span>{formValues.kit_products.length} Productos</span>
            <span>Valor: <b>${totalPrice.toLocaleString('es')}</b></span>
        </div>


      </div>
      

      <Store
        addProduct = {addProductToKit}
        setLoading = {setLoading}
        storeDialogOpen = {storeDialogOpen}
        setStoreDialogOpen = {setStoreDialogOpen}

      />
          

    </>
    
  );
};