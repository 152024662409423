import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CompanyUsers from "./CompanyUsers";
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';

 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')

  interface Props {
    account_company_id?: any,
  }

 export default function CompaniesEditor(props: any) {

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {company_id } = useParams();
  company_id = props.account_company_id ? props.account_company_id : company_id;

  /*=======================================
  =======================================*/
  

  const navigate = useNavigate();
  const defaultValues: any = {
    company_id: company_id? company_id : "null",
    company_name: "",
    company_logo: "",
    company_document: "",
    is_active: true,
  };
  

  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };





  const handleSubmit = () => {

    const formData = new FormData();
    formData.append("files", files);
    
    axios.post(ProjectConfig.api_url, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          request: 'add_edit_company',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log("res ",response.data);
          setFormValues(response.data);
         
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/

  useEffect(() => {
    if (company_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "company_details",
            company_id: company_id,
          },
        })
        .then((response) => {
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);


  return (
    <div id="UsersList" className="ModuleContainer" style={{maxWidth: '600px'}}>
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Empresas</h2>
            <span className="h2_subtitle">Editar</span>
          </div>
        </div>

        <div id="UsersListIn">
            <Box id="list" className="box_editor box100">
              <div className='form'>

              <div className='input_group'>
                  <h2>Información de la empresa</h2>
                  <div className='input_row'>

                    {/* UPLOAD button with preview */}
                    <div className="drawerImagesContainer">
                                
                      <div className="drawerImagesContainerDiv"
                        onDrop={(e) => {
                            e.preventDefault();
                            // UPDATE FILE
                            const files = e.dataTransfer.files;
                            setFiles(files[0]);
                            const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                            setFormValues({
                              ...formValues,
                              company_logo: fileArray[0],
                            });
                            e.currentTarget.classList.remove("dropzone_active");
                          }
                          } 
                          onDragOver={
                            (e) => {
                              e.preventDefault();
                              e.currentTarget.classList.add("dropzone_active");
                            }
                            
                          }
                          onDragLeave={
                            (e) => {
                              e.preventDefault();
                              e.currentTarget.classList.remove("dropzone_active");
                            }
                          }
                      
                        >
                        {formValues.company_logo && (
                          <img src={formValues.company_logo}  />
                        )}
                        
                        <label id='dropFileDiv' className={formValues.company_logo && ('showOnlyOnHover')}>
                          <IconButton 
                            aria-label="delete"
                            size="small"
                            className="addFile_button"
                            component="span"
                          >
                            <CameraAltIcon/>
                            <input 
                              type="file"
                              multiple 
                              hidden 
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files) {
                                    
                                    // UPDATE FILE
                                    setFiles(files[0]);
                                    const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                    setFormValues({
                                      ...formValues,
                                      company_logo: fileArray[0],
                                    });

                                  }

                              }}
                            />
                            
                          </IconButton>
                        </label>
                        
                      </div>

                    </div>
                  

                            
                    <div className='inputColumn'>
                      <TextField 
                        id="company_name"
                        name="company_name"
                        label="Nombre / Razón social"
                        type="text"
                        required
                        fullWidth
                        value={formValues.company_name}
                        onChange={handleInputChange}
                      />

                      <TextField
                        id="company_document"
                        name="company_document"
                        label="Documento / NIT"
                        type="text"
                        required
                        fullWidth
                        value={formValues.company_document}
                        onChange={handleInputChange}
                      />



                    
                    </div>
                  </div>
                </div>
                

              </div>
              <div className='buttons'>
                <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Volver</Button>
                <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
              </div>
            </Box>
          </div>
        </div>


        <div>
          {formValues.company_id !== "null"? (
            <CompanyUsers company_id={formValues.company_id} setLoading={setLoading}/>
            ) : (
            <Box className="noAdmins box100">
              <div className="noAdminsDiv">
                <PersonAddDisabledIcon fontSize="large" />
                <p>Para agregar administradores, primero guarde la empresa.</p>
              </div>
            </Box>
          )}
        </div>

    </div>
    
  );
};