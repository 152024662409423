import  React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
    Dialog,
    DialogTitle
} from '@mui/material';

import { ProjectConfig } from 'Global';
import FormDescription from './DialogForms/FormDescription';
import FormQuestionChoices from './DialogForms/FormQuestionChoices';
import FormQuestionText from './DialogForms/FormQuestionText';
import FormFinished from './DialogForms/FormFinished';

const cookies = new Cookies();


export default function DialogForm(props:any) {
    const {
        open,
        setOpen,
        selectedForm,
        setSelectedForm,
        setLoading,
        handleClose,
        handleConfirm,
        workstation_invitation_id
    } = props;

    const defaultValues = {
        form_id: null,
        results: [],
        workstation_invitation_id: workstation_invitation_id,
    };

    
    

    const [list, setList] = useState<any>([]);
    const [question, setQuestion] = useState<any>(null);
    const [index, setIndex] = useState<number>(0);
    const [userAnswer, setUserAnswer] = useState<any>(defaultValues);
    const [form, setForm] = useState<any>(null);

    useEffect(() => {
        if (selectedForm) {
            loadList();
            setIndex(0);
        }
    }, [selectedForm,open]);
    
    const loadList = () => {
        setLoading(true);
        axios.get(ProjectConfig.api_url,{
            headers: {
                'COMPANYID': cookies.get('company_id'),
            },
            params:{
                request: 'get_form_details',
                params: {
                    form_id: selectedForm.form_id,
                }
            }
        })
        .then(response => {
            setList(response.data.questions);
            setForm({
                form_id: response.data.form_id,
                form_name: response.data.form_name,
                form_description: response.data.form_description,
                finished_message: response.data.finished_message,
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleDiscard = () => {
        setSelectedForm(null);
        setList([]);
        setQuestion(null);
        setUserAnswer(null);
        handleClose(null,setOpen);
        setIndex(0);
    }

    const handleInit = () => {
        setUserAnswer({form_id: selectedForm.form_id, results: [], workstation_invitation_id: workstation_invitation_id});
        setQuestion(list[0]);
    }
    
    const handleNext = () => {
        if(index < list.length-1){
            setQuestion(list[index+1]);
            setIndex(index+1);
        } else {
            setQuestion(null);
            setIndex(-1);
        }
    }
    
    const handleBack = () => {
        if(index > 0){
            setQuestion(list[index-1]);
            setIndex(index-1);
            handleDeleteLastAnswer();
        }
    }

    const handleUpdateAnswers = (questionWithUserAnswers: any) => {
        setUserAnswer({...userAnswer, results: [...userAnswer.results, questionWithUserAnswers]});
    }

    const handleDeleteLastAnswer = () => {
        let results = userAnswer.results;
        results.pop();
        setUserAnswer({...userAnswer, results: results});
    }

    const handleCheckForm = (question_type: string, userAnswers: any, setUserAnswers: Function ) => {
        
        let errors = false;
        if(question_type == "text" && userAnswers.answer == ""){
            setUserAnswers({...userAnswers, error: true});
            return true; // return error = true to avoid the next if
        
        } else if(question_type == "multiple" && userAnswers.answers.length == 0){
            setUserAnswers({...userAnswers, error: true});
            errors = true;
        
        }else if(question_type == "unique" && userAnswers.answers.length != 1){
            setUserAnswers({...userAnswers, error: true});
            errors = true;
        
        }

        if(!errors){
            if (userAnswers.answers !== undefined) {
                for (let answer of userAnswers.answers) {
                    if(answer.haveComment && answer.comment == ''){
                        setUserAnswers({...userAnswers, error: true});
                        return true; // return error = true to avoid the next if
                    }
                }
            }
        }
        return errors;
    }

    const handleSave = () => {

        // AXIOS POST
        axios.post(ProjectConfig.api_url,userAnswer,{
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params:{
                request: 'save_answers',
            }
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            handleNext();
        });
    }

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => handleClose(reason, setOpen)}
            aria-labelledby="alert-dialog-title"
        >
            { (question === null && index === 0) &&
                <FormDescription
                    selectedForm={form}
                    handleDiscard={handleDiscard}
                    handleReload={handleInit}
                />
            }

            { (question !== null && question.question_type === "text") &&
                <FormQuestionText
                    questionProp={question}
                    isLast={index === list.length-1}
                    index={index}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    checkForm={handleCheckForm}
                    handleUpdateAnswers={   handleUpdateAnswers}
                    handleSave={handleSave}
                    handleClose={() => handleDiscard()}
                    />
                }

            { (question !== null && (question.question_type === "multiple" || question.question_type === "unique")) &&
                <FormQuestionChoices
                    questionProp={question}
                    isLast={index === list.length-1}
                    index={index}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    checkForm={handleCheckForm}
                    handleUpdateAnswers={   handleUpdateAnswers}
                    handleSave={handleSave}
                    handleClose={() => handleDiscard()}
                />
            }

            { (question === null && index === -1) &&
                <FormFinished
                    selectedForm={form}
                    handleClose={handleDiscard}
                    handleConfirm={handleConfirm}
                />
            }
            
        </Dialog>
    )
}