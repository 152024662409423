import { useEffect } from 'react'
import { Button, Dialog, DialogContent } from '@mui/material';




import { useSnackbar, withSnackbar } from 'notistack';

import axios from 'axios';

import Cookies from 'universal-cookie';
import { ProjectConfig } from 'Global';
import SendIcon from '@mui/icons-material/Send';

const cookies = new Cookies();

const ResendInvitation = (props: any) => {
    
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */
    
    const {open, send, setOpen, setLoading, workstation_id} = props;
    
    useEffect(() => {
        setLoading(false);
    }, []);
    
    
    // SEND INVITATIONS TO WORKSTATIONS LIST
    const resendInvitation = () => {
        setLoading(true);
        axios.post(ProjectConfig.api_url, null,
            {
                headers:{
                    'LTKN': cookies.get('ltkn')
                },
        params: {
            request: 'resend_workstation_invitation',
            params:{
                workstation_id: workstation_id
            }
        } 
    })
    .then((response) => {
        console.log(response.data);
        enqueueSnackbar('Correo reenviado', {variant: 'success'});
            setOpen(false);
            setLoading(false);
            
        })
        .catch((err) => {
            console.log(err.data);
        })
    }
    
    return (
        <>



        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            
            <DialogContent  className="forced_box_editor">
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Invitación pendiente</h2>
                </div>

                <p>
                La empresa <b>Empresa de prueba</b> te ha enviado una invitación para que te unas a su equipo de trabajo en <b>SHOFI</b>.
                </p>

                <p>
                Por favor revisa tu correo electrónico y confirma tu registro, si no has recibido el correo electrónico, puedes solicitar nuevamente el envío de la invitación dando clic en el botón <b>Reenviar invitación</b>.
                </p>


                {/* BUTTONS */}
                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>

                    <Button 
                        variant="contained" 
                        onClick={
                            () => {
                                resendInvitation();
                            }
                        }
                        endIcon={<SendIcon />}
                        >
                        Reenviar invitación
                    </Button>
                </div>
            </DialogContent>

        </Dialog>

        
    </>
    );
}

export default withSnackbar(ResendInvitation);