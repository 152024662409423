import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import moment from "moment";
import AppAvatar from "elements/AppAvatar";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AlertDialog from "elements/AlertDialog";

export default function CompaniesList(props: any) {
  
  const {
    setLoading,
  } = props;
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setCompanyList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem("page") || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get("category");
  const [refreshList, setRefreshList] = useState(0);

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "companies_list",
          params: {
            page: page,
          },
        },
      })
      .then((response) => {
        console.log("res comp ", response.data);
        setCompanyList(response.data.list);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
      });
    localStorage.setItem("page", page.toString());
  }, [page, refreshList]);
  /*=========================================================*/

  console.log("ul ", list);

  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (company_id: number) => {
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "delete_company",
          company_id: company_id,
        },
      })

      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1);
        setDeleteCompanyId(null);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*=========================================================*/

  // call global snackbar

  return (
    <>
      <div id="UsersList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Empresas</h2>
              <span className="h2_subtitle">Listado</span>
            </div>

            <div className="boxContainerActions">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="add"
                startIcon={<AddIcon />}
              >
                Crear Empresa
              </Button>
            </div>
          </div>

          <div id="UsersListIn">
            <Box id="list" className="box100">
              <TableContainer>
                <Table  aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>LOGO</TableCell>
                      <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE / RAZÓN SOCIAL</TableCell>
                      <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>DOCUMENTO</TableCell>
                      <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((item, i) => (
                      <TableRow key={i} hover role="checkbox">
                        <TableCell>
                          <AppAvatar
                            name={item.company_name}
                            image={item.company_logo}
                          />
                        </TableCell>
                        <TableCell>{item.company_name}</TableCell>

                        <TableCell>{item.company_document}</TableCell>

                        <TableCell align="right">
                          <Button
                            component={Link}
                            to={`edit/${item.company_id}`}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: 16 }}
                          >
                            Editar
                          </Button>

                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              setDeleteCompanyId(item.company_id);
                              setOpenDeleteDialog(true);
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Pagination
                count={total_pages}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                color="primary"
                style={{ marginTop: "20px", alignSelf: "center" }}
              />
            </Box>
          </div>
        </div>
      </div>

      <AlertDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        subtitle="¿Desea eliminar esta empresa?"
        send={() => handleDelete(Number(deleteCompanyId))}
      />
    </>
  );
}
