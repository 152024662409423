import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import { List } from 'devextreme-react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyElement from './EmptyElement';

const cookies = new Cookies();

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function AutocompleteKits(props: any) {

  const company_id = cookies.get('company_id');

  const {value, onChange, error} = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpenList = () => {
  setOpen(true);
  };


  /*=================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProjectList {
      inputValue?: string,
      kit_id?: number,
      kit_name: string,
    };
    const filter = createFilterOptions<IProjectList>();

    //setClientList
    const [kitList, setKitList] = useState<any []>([]);
    const [kit, setKit] = useState<IProjectList | null>(null);
    
    useEffect(() => {
      console.log("useEffect");
      axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
          'COMPANYID': company_id
        },
        params: {
          request: 'kits_list',
          params: {
            full_list: true,
          }
        }
      })
        .then((response) => {
          console.log("list",response.data);
          setKitList(response.data.list);
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, []);
    /* ================================================= 
    ===================================================*/


    let item_name = "";
    if (kitList && (value && value!=0)) 
    {
      
      item_name = kitList.find(obj => {
        return obj.kit_id === value; 
      }).kit_name;
    } 
    
    
  return (
    <>

     {kitList.length > 0 ? 
     (
      <Autocomplete
        value={item_name}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setKit({
              kit_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setKit({
              kit_name: newValue.inputValue,
            });
          } else {
            setKit(newValue);
            if (newValue?.kit_id!=null)
            {
              onChange({
                  target: {
                      value: newValue.kit_id.toString(),
                      name: "kit_id",
                  }
              })
            }
            else {
              onChange({
                target: {
                    value: "",
                    name: "kit_id",
                }
              })
            }
            
          }
          
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        fullWidth
        id="free-solo-with-text-demo"
        options={kitList}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.kit_name;
        }}
        renderOption={(props, option) => <li className="flex_select" {...props}>
          <div className="flex_option_1">{option.kit_name}</div>
        </li>}
        freeSolo
        renderInput={(params) => (
          <TextField 
          {...params} 
          label="Nombre del kit" 
          error={error}
          
          placeholder='Nombre del kit'
          />
        )}
        
      /> 
      )
      :
      (
        <>
        <EmptyElement title="No hay kits" subtitle={<Button
          component={Link}
          to={"/kits"}
          variant="contained"
          color="primary"
          size='small'
          className="button"
          sx={{margin:'auto'}}
        >
          Crear kit
        </Button>}/>
        
        </>
      )}
    </>
  );
}
