import React, { useEffect, useState } from 'react';
import Box from './Box';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Launch } from '@mui/icons-material';
import Cookies from 'universal-cookie';

import 'assets/css/main.css';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import logosh  from 'icons/shofi-home.png';


interface IStep {
    index: number;
    key: string;
    title: string;
    subtitle: string;
    to: string;
    status: boolean;
    button_text: string;
}

const cookies = new Cookies();

export default function FirstSteps( props: any ) {

    const { openStepDialog, setOpenStepDialog } = props;

    useEffect(() => {
        const userSteps = cookies.get('user_steps');
        if (userSteps === 'null') {
            props.setIsIntour(false);
        }
    }, [openStepDialog]);
        

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate =  useNavigate();

    const [currentStep, setCurrentStep] = useState<number>(0);

    const [list, setList] = useState<IStep[]>([]);

    useEffect(() => {
        loadList();
    }, []);
    
    useEffect(() => {
        const userSteps = cookies.get<IStep[]>('user_steps');
        
        if (JSON.stringify(userSteps) !== JSON.stringify(list)) {
            if (Object.values(userSteps).every((step: IStep) => step.status === true) === true) {
                setTimeout(() => {
                    axios.post(ProjectConfig.api_url, null, {
                        headers: {
                            'LTKN': cookies.get('ltkn'),
                        },
                        params: {
                            request: 'finish_tour',
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                    });
                    cookies.set('user_steps', null);
                }, 2000);
            }
            loadList();
        }
    }, [cookies.get('user_steps')]);

    const loadList = () => {
        const userSteps = cookies.get('user_steps');
        if (userSteps === 'null') {
            setList([]);
            setCurrentStep(0);
            props.setIsIntour(false);
        } else {
            setList([...userSteps]);
            userSteps.find((step: IStep, index: number) => {
                if (step.status === false) {
                    setCurrentStep(index);
                    return true;
                }
                setCurrentStep(userSteps.length);
                return false;
            });
        }
    }

    return (
        <>
            
            <Dialog
                open={openStepDialog}
                onClose={() => setOpenStepDialog(false)}
                id='first_steps_dialog'
            >
                

                <DialogContent  className="forced_box_editor">
                    <div className="welcomeHeader">

                        
                        <img src={logosh} alt="Shofi" className="dialog_logo" style={{maxWidth: '350px'}} />

                        <h2 className='welcomeH2'>{(list.length > 0) ? '¡Bienvenido(a)!' : '¡Felicidades!'}</h2>
                        {(list.length > 0) ? (
                            <>
                            <p className='welcomeP'>Nos complace que estés con nosotros y seas uno más de la <b>comunidad SHOFI.</b> Comenzar a utilizar tu nueva mejor herramienta no será difícil. Permítenos guiarte para que en menos de 5 minutos te conviertas en todo un experto.</p>
                            </>
                        ) : (
                            <>
                                <p className='welcomeP'>¡Has completado todos los pasos!, ahora puedes utilizar la plataforma con lo aprendido.</p>
                            </>
                        )}
                    </div>

                        {list.length > 0 ? (
                        <Stepper
                            activeStep={currentStep}
                            orientation="vertical"
                            
                        >
                            {list.map((step, index) => (
                                <Step key={step.index}>
                                    <StepLabel style={{color: "#000"}}>
                                        {step.title}
                                    </StepLabel>
                                    <StepContent>
                                        <p>{step.subtitle}</p>
                                        <div className="">
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        console.log(step.to)
                                                        navigate({
                                                            pathname: step.to,
                                                            search: '?tour=' + true + '&type=' + step.key,
                                                        });
                                                        setOpenStepDialog(false);
                                                    }}
                                                    endIcon={<Launch />}
                                                >
                                                    {step.button_text}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        ) : (
                            <DialogContentText>
                                Continua utilizando la plataforma con normalidad.
                            </DialogContentText>
                        )}


                        <div className="buttons">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setOpenStepDialog(false)
                                    props.setIsIntour(false);
                                }}
                            >
                                Cerrar
                            </Button>
                            

                        </div>
  
                </DialogContent>
            </Dialog>
        </>
    );

}