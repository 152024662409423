/**
 * third-party dependencies
 */
import React, { useEffect, useState } from 'react';
import {
    createSearchParams,
    Link,
    useNavigate,
    useParams,
    useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Stack,
    Pagination,
    DialogContentText
} from '@mui/material';

/**
 * styles dependencies
 */
import './styles/forms.css';
import '../../assets/css/main.css';

/**
 * project dependencies
*/
import { ModeEditOutline, DeleteOutline, Add, ListAlt, Share } from '@mui/icons-material';
import { ProjectConfig } from 'Global';
import DialogForm from './Dialogs/DialogForm';
import DialogResults from './Dialogs/DialogResults';
import Box from 'elements/Box';
import EmptyElement from 'elements/EmptyElement';

/**
 * read-only variables
*/
const cookies = new Cookies();

// STYLES
const itemStylesX = {
    justifyContent: 'end',
    m: 1,
    p: 1,
    fontSize: '9pt',
}

export default function FormsList(props:any) {
    // PROPS INITIALIZATION
    const {
        setLoading,
        isAdmin = false,
    } = props;
    
    // HOOKS AND PARAMS INITIALIZATION
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let page = searchParams.get('page') || 1;

    const [openNew, setOpenNew] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openResults, setOpenResults] = useState(false);
    
    const [list, setList] = useState<any[]>([]);
    const [selectedForm, setSelectedForm] = useState<any>(null);
    const [total_pages, setTotal_pages] = useState(0);

    useEffect(() => {
        setLoading(true);
        loadList(); //AXIOS GET
    }, [page]);

    const loadList = () => {
        //HEADERS AND PARAMS
        const { headers, params } = getHeaderNParams();

        // AXIOS GET
        axios.get(ProjectConfig.api_url,
            {
              headers: {
                'LTKN': cookies.get('ltkn'),
                ...headers,
              },
              params: {
                request: 'get_forms_list',
                params: {
                    page: page,
                    ...params,
                }
              }
            }
        )
        .then((response) => {
            setList(response.data.list)
            setTotal_pages(response.data.total_pages)
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    // HANDLER FUNCTIONS
    const handleClickOpenNew = () => {
        setSelectedForm(null);
        setOpenNew(true);
    };
    
    const handleClickOpenEditMode = (form: any) => {
        setSelectedForm(form);
        setOpenNew(true);
    };

    const handleClickDelete = (form: any) => {
        setSelectedForm(form);
        setOpenDelete(true);
    };

    const handleDelete = () => {
        if (!selectedForm) {
            return;
        }
        //HEADERS AND PARAMS
        const { headers, params } = getHeaderNParams();

        // if(isAdmin){headers.COMPANYID = selectedForm.company_id}

        setLoading(true);
        
        // AXIOS POST
        axios
          .post(ProjectConfig.api_url, null, {
            headers: {
                'LTKN': cookies.get('ltkn'),
                ...headers,
            },
            params: {
                request: "delete_form",
                form_id: selectedForm.form_id,
                ...params,
            },
        })
        .then((response) => {
            console.log(response.data);
            loadList();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleClose = (reason: string, openSetter: CallableFunction) => {
        if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            openSetter(false)
        }
    };


    const handleNextPage = (value: number) => {
        navigate({
            pathname: '/forms',
            search: createSearchParams({
                page: value.toString(),
            }).toString(),
        });
    }

    const getHeaderNParams = () => {
        //HEADERS AND PARAMS
        const headers: any = {}, params: any = {};
        if (isAdmin){
            params.isAdmin = true;
        } else {
            headers.COMPANYID = cookies.get('company_id');
        }
        return {headers, params};
    };

    // RENDER
    return (
        <div id="FormsList" className="ModuleContainer" style={{maxWidth: '900px'}}>
            <div id="FormListWrapper" className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                        <h2 className="h2_title">Formularios</h2>
                        <span className="h2_subtitle">Listado</span>
                    </div>

                    <div className="boxContainerActions">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => handleClickOpenNew()}
                            >
                            Crear formulario
                        </Button>
                    </div>
                </div>

                <div id='FormsListIn'>
                    <Box id='list' className='box100'>
                    {list.length == 0 ? (
                    <EmptyElement title="No hay formularios" subtitle="Puedes crear uno nuevo" />
                    ) :
                    (
                        <Table aria-label="sticky table"
                            className='fixed-table'
                            >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>ID</TableCell>
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE</TableCell>
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}} align='right'>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((form) => (
                                    <TableRow
                                    key={form.form_id}
                                    hover
                                    >
                                        <TableCell>{form.form_id}</TableCell>
                                        <TableCell sx={{width:'180px'}} >

                                            <div className='row centered' style={{columnGap: 1}}>
                                                <span style={{whiteSpace: 'nowrap'}}>{form.form_name}</span>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => handleClickOpenEditMode(form)}
                                                    >
                                                    <ModeEditOutline sx={{width:'14px', height:'14px'}} color='primary' />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size='small'
                                                sx={{mr: 1}}
                                                onClick={() => navigate(`/forms/${form.form_id}/questions`)}
                                                startIcon={<ModeEditOutline />}
                                                >
                                                Preguntas
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size='small'
                                                sx={{mr: 1}}
                                                onClick={() => {
                                                    setSelectedForm(form);
                                                    setOpenResults(true);
                                                }}
                                                startIcon={<ListAlt />}
                                                >
                                                Resultados
                                            </Button>
                                            {/* {isAdmin && (
                                            <Button
                                                variant="contained"
                                                size='small'
                                                sx={{mr: 1, }}
                                                style={{ backgroundColor: '#3ea5b2'}}
                                                onClick={() => {
                                                }}
                                                startIcon={<Share />}
                                                >
                                                Compartir
                                            </Button>
                                            )} */}
                                            {/* <Button
                                                variant='contained'
                                                color='error'
                                                size='small'
                                                onClick={() => handleClickDelete(form)}
                                                startIcon={<DeleteOutline />}
                                                >
                                                Eliminar
                                            </Button> */}
                                            <IconButton
                                                size='small'
                                                onClick={() => handleClickDelete(form)}
                                                >
                                                <DeleteOutline />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                        {total_pages > 1 && (
                            <Pagination
                                count={total_pages}
                                page={Number(page)}
                                onChange={ (e, value) => handleNextPage(value) }
                                color="primary"
                                style={{"marginTop": "20px","alignSelf": "center"}}
                                />
                                )}
                    </Box>
                </div>

                {/** DIALOGS */}

                <DialogForm
                    open={openNew}
                    setOpen={setOpenNew}
                    selectedItem={selectedForm}
                    handleClose={handleClose}
                    setLoading={props.setLoading}
                    handleReload={loadList}
                    />

                <DialogResults
                    open={openResults}
                    setOpen={setOpenResults}
                    selectedItem={selectedForm}
                    handleClose={handleClose}
                    setLoading={props.setLoading}
                    />

                <Dialog
                    open={openDelete}
                    onClose={(evt: object, reason: string) => handleClose(reason,setOpenDelete)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogContent className="forced_box_editor">
                        <div className="dialog_header">
                            <h2 className='dialog_h2'>Confirmar</h2>
                            <DialogContentText>
                                {'¿Está seguro que desea eliminar el formulario?'}
                            </DialogContentText>
                        </div>

                        <div className="buttons">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setOpenDelete(false)}
                                >
                                Cancelar
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleDelete();
                                    setOpenDelete(false);
                                }}
                                autoFocus
                            >
                                Confirmar
                            </Button>
                        </div>
                    </DialogContent>

                </Dialog>
                
            </div>
        </div>
    );
}