import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AlertDialog from 'elements/AlertDialog';

export default function CategorieslList(props: any) {

  const {
    setLoading,
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  useEffect(() => {
    setLoading(false);
  }, [])

  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'categories_list',
          page: page,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {


    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_categories',
        id: id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1);
      setDeleteId(null);
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  

  
  return (
    <>
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Categorías</h2>
              <span className="h2_subtitle">Listado de categorías</span>
            </div>
            
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="add"
              startIcon={<AddIcon />}
              
              >
              Crear categoría
            </Button>

            {/* BORRAR CATEGORIAS:: PRUEBA DE COPILOT, no se si eliminarlo
            <Button 
            variant="contained"
            color="primary"
            onClick={() => {
              
            }
          }
          startIcon={<AccessAlarmIcon />}
          >
          Borrar categorías
        </Button>*/}





            </div>
            
          </div>

          <div id='UsersListIn'>
        
            
              <Box id='CategoriesList' className='box100'>
              <TableContainer> 
              <Table stickyHeader aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell>Categoría</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((list_item,i) => (
                      <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                      >
                        <TableCell >    
                          <div className='flexWImage'>
                            <img className='ListImage' src={list_item.image} />
                            <div className="flexWImage_right">
                            {list_item.name}
                            </div>
                          </div>
                          
                        </TableCell>

                      


                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/categories/edit/${list_item.category_id}`}
                            startIcon={<ModeEditIcon />}
                            >
                            Editar
                          </Button>

                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              setDeleteId(list_item.category_id);
                              setOpenDeleteDialog(true);
                            }}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                      </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            {total_pages > 1 && (
              <Pagination count={total_pages} page={page}  onChange={(event, value) => {
                setPage(value)
              }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            )}
            </Box>
  
          </div>
        </div>
      </div>

      <AlertDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        subtitle="¿Desea eliminar esta categoría?"
        send={() => handleDelete(Number(deleteId))}
      />
    </>
  );
};
