import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Link,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import Box from 'elements/Box';

export default function FormCredentialsSent( props: any ) {
    
    const {
        logo,
        setState,
        setEmail,
        setLoginData,
        loginDataDefault,
    } = props;

    return(
        <Box id='loginBox'>

          <Link
            component="button"
            variant="subtitle2"
            underline="none"
            onClick={() => {
                setEmail('');
                setLoginData(loginDataDefault);
                setState('login')
            }}
            sx={{display:'flex', alignItems:'center', fontSize: 'small', justifyContent: 'start', columnGap: '5px', padding: '0px 0px 10px 0px'}}
          >
            <ArrowBack sx={{ fontSize: 'small' }} />
            Volver
          </Link>
          
          <img className='loginLogo' src={logo}></img>

          <p>La información para recuperar el acceso fue enviada. Por favor, revisa el correo.</p>
        </Box>
    );
}