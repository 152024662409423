import React, { useEffect, useState } from 'react';
import { useSnackbar, withSnackbar } from 'notistack';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const defaultValuesQuestion = {
    question_id: null,
    question_type: null,
    question: null,
    answer: '',
};

interface IAnswer {
    answer: string,
    error: boolean,
}

const defaultValuesAnswer: IAnswer = {
    answer: '',
    error: false,
}

export default function FormQuestionText(props:any) {
    const {
        questionProp,
        isLast,
        index,
        handleNext,
        handleBack,
        checkForm,
        handleUpdateAnswers,
        handleSave,
        handleClose,
    } = props;

    const [question, setQuestion] = useState<any>({...defaultValuesQuestion});
    const [userAnswer, setUserAnswer] = useState<IAnswer>({...defaultValuesAnswer});

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */
    
    useEffect (() => {
        if(questionProp){
            setQuestion({...questionProp});
        } else {
            setQuestion({...defaultValuesQuestion});
        }
        setUserAnswer({...defaultValuesAnswer});
    }, [questionProp]);

    const handleClickNext = () => {
        if(!checkForm(question.question_type,userAnswer,setUserAnswer)){
            handleNext();
            handleUpdateAnswers({question_id: question.question_id, question_type: question.question_type, answer: userAnswer.answer})
            if(isLast){
                handleSave();
            }
        } else {
            enqueueSnackbar('Debes responder la pregunta', {variant: 'error'});
        }
    }

    return (
        <>
           <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <Close />
            </IconButton>

            
            <DialogContent
                className="forced_box_editor"
                sx={{ width: '100%', minWidth: '500px'}}
            >
                <div className="dialog_header" style={{paddingRight: '40px', marginBottom: '10px', }}>
                    <h2 className='dialog_h2'>{ question.question }</h2>
                    <DialogContentText>{ question.question_type }</DialogContentText>
                </div>

                <div className='formQuestionChoices'>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        m: 'auto',
                        p: '10px 0',
                        width: '100%',
                    }}
                    className='formContainer'
                >
                       <FormControl
                            sx={{width: '100%'}}
                            className='formItem'
                        >
                            <TextField
                                fullWidth
                                label='Respuesta'
                                className='formInput'
                                placeholder='Respuesta'
                                multiline
                                rows={2}
                                value={userAnswer.answer}
                                onChange={(e) => setUserAnswer({...userAnswer, answer: e.target.value})}
                            />
                        </FormControl>
                    </Box>
                </div>

                <div className='buttons'>
                    { index !== 0 &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleBack() }
                    >
                        Anterior
                    </Button>
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ () => handleClickNext() }
                        autoFocus
                    >
                        { !isLast ?
                        <>Siguiente</>
                        :
                        <>Finalizar</>
                        }
                    </Button>
                </div>
            </DialogContent>

        </>
    );
};