import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Badge, Button, Checkbox, Chip, CircularProgress, Drawer, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import AddWorkstation from '../Utils/AddWorkstation';
import Cookies from 'universal-cookie';
import AlertDialog from 'elements/AlertDialog';
import AddProductInvitation from '../Utils/AddProductInvitation';
import WorkstationsOrders from '../../Orders/WorkstationsOrders';
import ServiceList from '../../Orders/ServiceList';

import 'assets/css/main.css';

const cookies = new Cookies();

export default function AdminWorkstationList(props: any) {

  const {isAdmin, setLoading} = props;

  const company_id = cookies.get('company_id');
  

    useEffect(() => {
      setLoading(false);
    }, []);

  
  const [list, setList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [alertOpen, setAlertOpen] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [totals, setTotals] = useState<any>({});


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  //WHEN PAGE CHANGES 
  useEffect(() => {
      loadList()
    
  }, [page, company_id])

  //LOAD LIST 
  const loadList = () => {
    setLoading(true)
    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
          'COMPANYID': company_id
        },
        params: {
          request: 'workstations_list',
          params: {
            page: page,
            isAdmin: isAdmin,
          }
        }
      })
        .then((response) => {
          console.log("res",response.data);
          setLoading(false)
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setTotals(response.data.totals)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
  }

  /*=========================================================*/

  
  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const [itemToDelete, setItemToDelete] = useState(0);

  const deleteWorkstation = () => {
    const id = itemToDelete;

    axios.post(ProjectConfig.api_url, null, 
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'delete_workstation',
          id: id,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        loadList()
        
      })
      .catch((err) => {
        console.log(err.data);
      })
    }
  /*=========================================================*/

// call global snackbar
  
  const [openNewWorkstation, setOpenNewWorkstation] = useState(false);


  useEffect(() => {
    checkSelectedRows();
  }, [list]);

  const checkSelectedRows = () => {
    let selectedRows: any[] = [];
    list.forEach((item: any) => {
      if(item.checked){
        selectedRows.push(item);
      }
    });
    setCheckedList(selectedRows);
    console.log(selectedRows);
  }

  const [allChecked, setAllChecked] = useState(false);


  
  const SendWorkstationsInvitations = (workstationsList: any) => {
    setLoading(true);
    axios.post(ProjectConfig.api_url, null,
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
        'COMPANYID': company_id
      },
      params: {
        request: 'send_workstations_invitations',
        params:{
          workstationsList: workstationsList,
        }
      } 
    })
    .then((response) => {
      console.log(response.data);
      setOpenNewWorkstation(false);
      loadList();
      setLoading(false);
    }
    )
    .catch((err) => {
      console.log(err.data);
    }
    )
  }


  const SendProductInvitations = (invitation: any) => {
    
    setLoading(true);
    axios.post(ProjectConfig.api_url, null,
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
        'COMPANYID': company_id
      },
      params: {
        request: 'send_product_invitations',
        params:{
          invitation: invitation,
          checkedList: checkedList,
        },
      },
    })
    .then((response) => {
      console.log(response.data);
      setOpenNewProductInvitation(false);
      setLoading(false);
    }
    )
    .catch((err) => {
      console.log(err.data);
    }
    )



  }





  const [openNewProductInvitation, setOpenNewProductInvitation] = useState(false);


  //when close AddProductInvitation uncheck all rows
  useEffect(() => {
    if(!openNewProductInvitation){
      let newList = list;
      newList.forEach((item: any) => {
        item.checked = false;
      });
      setList(newList);
      setCheckedList([]);

      setAllChecked(false);
    }
  }, [openNewProductInvitation]);



  
  return (
    <>
      <AddWorkstation 
        open={openNewWorkstation} 
        setOpen={setOpenNewWorkstation} 
        setLoading={setLoading}
        send={SendWorkstationsInvitations}
      />

      <AddProductInvitation
        open={openNewProductInvitation}
        setOpen={setOpenNewProductInvitation}
        setLoading={setLoading}
        send={SendProductInvitations}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        list={list}
        setList={setList}
      />

      <div id='PuestosList' className='ModuleContainer' style={{paddingBottom: checkedList.length > 0 ? '100px' : '0px'}}>
        <div className="ModuleWrapper">

          <div id="userWorkstationsHeader" className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Puestos</h2>
              <span className="h2_subtitle">Lista de usuarios vinculados</span>
            </div>
          </div>



          <div id='PuestosListIn'>
            <div id='puestos_list_header'>
                <Button 
                  className={"puestos_list_header_item box"}
                  color='primary'
                  sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
                  onClick={() => {
                    
                  }}
                >
                  <h3>{totals.active}</h3>
                  <span className='PuestosListIndicator'>Puestos activos</span>
                </Button>

                <Button 
                  className={"puestos_list_header_item box"}
                  color='primary'
                  sx={{color: '#6b6b6b', backgroundColor: '#ffffff'}}
                  onClick={() => {
                    
                  }}
                >
                  <h3>{totals.pending}</h3>
                  <span className='PuestosListIndicator'>Invitaciones pendientes</span>
                </Button>
                
                {totals.approval_required > 0 &&(
                <Button 
                  className={"puestos_list_header_item box"}
                  color='error'
                  variant="outlined"
                  sx={{color: '#aa4e4e', backgroundColor: '#ffffff', bordercolor: '#000'}}
                  onClick={() => {
                    
                  }}
                >
                  <h3>{totals.approval_required}</h3>
                  <span className='PuestosListIndicator'>Requieren aprobación</span>
                </Button>
                )}
              </div>
            
              <div id="workstationList" className="listContainer">
                
                <Box id='workstation_list' title="" subtitle=""  className="box100">
                  <TableContainer>
                  <Table aria-label="sticky table"> 
                    <TableHead>
                      <TableRow>
                        <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>
                          <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e) => {
                              let newList = [...list];
                              newList.forEach((item: any) => {
                                item.checked = e.target.checked; 
                              });
                              setList(newList);
                              setAllChecked(e.target.checked);
                            }}
                            checked={allChecked}
                          />
                        </TableCell>
                        {isAdmin && ( <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>EMPRESA</TableCell> )}
                        <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE / EMAIL</TableCell>
                        <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>UBICACIÓN</TableCell>
                        <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>ESTADO</TableCell>
                        <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}} className='align_right'>PRODUCTOS</TableCell>
                        <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item,i) => (

                          <TableRow 
                            key={i}
                            hover
                            role="checkbox"
                          >
                            
                            <TableCell>
                              <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={item.checked}
                                onChange={(e) => {
                                  let newList = [...list];
                                  newList[i].checked = e.target.checked;
                                  setList(newList);
                                  setAllChecked(false);
                                  
                                }}
                              />
                            </TableCell>
                            {isAdmin && ( 
                            <TableCell>
                              <div className='tdwimg'>
                                <AppAvatar name={item.company_name} image={item.company_logo} />
                                <div className='flexTd'>
                                  <span className='flexTd_bold'>{item.company_name}</span>
                                </div>
                              </div>

                              
                              
                            </TableCell> )}
                            <TableCell>
                              <div className='tdwimg'>
                                
                                <div className='flexTd'>
                                  <span className='flexTd_bold'>{item.name}</span>
                                  <span className='flexTd_normal'>{item.email}</span>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell >
                              <div className='flexTd'>
                                <span className='flexTd_bold'>{item.address? item.address : "Dirección pendiente"}</span>
                                <span className='flexTd_normal'>{item.city_name? item.city_name : ""}</span>
                              </div>
                            </TableCell>

                            <TableCell>
                              {item.status == 0 && <Chip label="Sin registrar" color={'error'} variant='outlined' />}
                              {item.status == 1 &&
                              <Chip label="Registrado" color={'primary'} variant='outlined' />
                              }
                              {item.status == 2 &&
                              <Chip label="Activo" color={'primary'} variant='outlined' />
                              }
                              
                            </TableCell>

                            <TableCell>
                              <AvatarGroup max={3} spacing={"small"} sx={{alignItems: 'center'}}>
                                {item.products.list.map((product: any, i: any) => (
                                  <Tooltip title={product.product_name} key={i}>
                                      <Avatar alt={product.product_name} src={product.product_image} sizes='small' />
                                  </Tooltip>
                                ))}
                              </AvatarGroup>
                            </TableCell>


                          
                    

                            <TableCell className="actionsColumn align_right">

                              <Button component={Link} to={`/workstations/edit/${item.workstation_id}`} variant="contained">
                                Detalles
                              </Button>

                              <IconButton aria-label="delete"  color="primary" 
                                onClick={() => {
                                  setItemToDelete(item.workstation_id)
                                  setAlertOpen(true)
                                }
                                }>
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                          </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {total_pages > 0 && (
                  <Pagination shape="rounded" 
                  count={total_pages} page={page}  onChange={(event, value) => {
                    setPage(value)
                  }
                  } 
                  color="primary"
                  style={{"marginTop": "20px","alignSelf": "center"}}
                  />
                )}
                </Box>
      
              </div>
            </div>
            </div>
      </div>

      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={deleteWorkstation} 
        subtitle="¿Estás seguro de eliminar este puesto?"
      />

      <div id='masive_actions' className='bottom_fixed_box' style={{display: checkedList.length > 0 ? 'flex' : 'none'}}>
        {checkedList.length} puestos seleccionados 
        <Button variant="contained" color="primary" onClick={() => {setOpenNewProductInvitation(true)}}>
          Crear invitaciones
        </Button>
      </div>
    </>
  );
};

