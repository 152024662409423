import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import 'assets/css/main.css';

export default function AdminList(props: any) {

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'admin_list',
          page: page,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setUserList(response.data.user_list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (user_id:number) => {
    setActiveItem(user_id)
    setOpenAlert(true)
  }


  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_admin',
          user_id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
  
  return (
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Usuarios</h2>
              <span className="h2_subtitle">Lista de usuarios registrados</span>
            </div>
            
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="add"
              startIcon={<AddIcon />}
            >
              Crear Administrador
            </Button>

            </div>
            
          </div>

          <div id='UsersListIn'>
            
              <Box id='AdminList' className='box100'>
              <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE / EMAIL</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>TELÉFONO</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ESTADO</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {user_list.map((user,i) => (
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >
                        <TableCell>
                          <span className='flex_title'>{user.name}</span>
                          <span className='flex_subtitle'>{user.email}</span>
                        </TableCell>

                      
                        <TableCell>
                          {user.phone}
                        </TableCell>

                        <TableCell>
                          <Chip label={user.is_active ? "Activo" : "Inactivo"} color={user.is_active ? "primary" : "error"} variant="outlined" size='small' />
                        </TableCell>

                        <TableCell align="right">

                          <Button 
                            component={Link}
                            to={`edit/${user.user_id}`}
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{"marginRight": "10px"}}
                            startIcon={<ModeEditIcon />}
                          >
                            Editar
                          </Button>



                          <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(user.user_id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                      </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            </Box>
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este usuario?"
            send={deleteItem}
        />
    </div>
  );
};