import React, { useEffect, useState } from "react";
import {
    Button,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import Box from "elements/Box";
import App from "elements/HtmlEditor/App";
import { useSnackbar } from "notistack";
import Cookies from "universal-cookie";
import axios from "axios";
import { ProjectConfig } from "Global";
import { useNavigate } from "react-router-dom";
import { Send } from "@mui/icons-material";

const cookies = new Cookies();

const defaultNewMessage = {
    id: 'null',
    user_id: cookies.get('user_id'),
    owner: cookies.get('user_name'),
    date: new Date(),
    message: null,
};

export default function TicketForm( props: any ) {

    const {
        formValues,
        setFormValues,
        setLoading,
        setOpen = () => {},
        isAdmin = false,
    } = props;

    const initialContent = null;

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [newMessage, setNewMessage] = useState<any>({...defaultNewMessage});
    const [clear, setClear] = useState<boolean>(false);

    const handleCheckForm = () => {
        // CHECK FORMVALUES WITHOUT TICKETS
        let errors = false;
        if (formValues.subject === '') {
            errors = true;
            enqueueSnackbar('El asunto no puede estar vacío', { variant: 'error' });
        }

        return errors;
    }

    const handleCheckMessage = () => {
        let errors = false;
        if (!newMessage.message || !newMessage.message.root.direction) {
            errors = true;
            enqueueSnackbar('El mensaje no puede estar vacío', { variant: 'error' });
        }
        return errors;
    }

    const handleSave = () => {
        setLoading(true);

        const params = isAdmin ? {isAdmin: true} : {};
        const headers = isAdmin ? {'ISADMIN': true} : {};
        
        axios.post(ProjectConfig.api_url,
            // FORMVALUES
            {
                ticket_id: formValues.ticket_id,
                subject: formValues.subject,
                priority: formValues.priority,
                ticket: {
                    ...params,
                    message: newMessage.message,
                },
            },
            // HEADERS AND PARAMS
            {
                headers: {
                    ...headers,
                    'LTKN': cookies.get('ltkn'),
                },
                params: {
                    request: 'add_ticket_message',
                }
            }
        )
        .then((response) => {
            console.log("save_ticket response",response.data);
            
            setClear(true);

            if(formValues.ticket_id !== 'null'){
                enqueueSnackbar('Ticket guardado correctamente', { variant: 'success' });
            }
            else{
                setFormValues({...formValues, ticket_id: response.data.ticket_id});
                enqueueSnackbar("Ticket creado correctamente", { variant: "success" });
                //remove navigate history
                navigate(`/tickets/edit/${response.data.ticket_id}`, { replace: true });
            }
            setFormValues(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleEditorChange = (htmlContent: any, jsonContent: any) => {
        setNewMessage({...newMessage,message: jsonContent,});
    };

    return (
        <Box id="newMessageBox" className='box100' style={{padding: (formValues.ticket_id !== 'null' ? '15px 20px 20px' : '30px 20px 20px')}}>
            {formValues.ticket_id === 'null' &&
            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
                <TextField
                    id="subject"
                    label="Asunto"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formValues.subject}
                    onChange={(event) => setFormValues({...formValues, subject: event.target.value})}
                />

                <TextField
                    id="status"
                    label="Prioridad"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: '150px' }}
                    select
                    value={formValues.priority}
                    onChange={(event) => setFormValues({...formValues, priority: event.target.value})}
                    >
                    <MenuItem value={'low'}>Baja</MenuItem>
                    <MenuItem value={'medium'}>Media</MenuItem>
                    <MenuItem value={'high'}>Alta</MenuItem>
                </TextField>
            </div>
            }

            <div>
                <Typography
                    variant="body2"
                    sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                    >
                    Mensaje
                </Typography>

                <App
                    handleEditorChange={handleEditorChange}
                    content={initialContent}
                    clear={clear}
                    setClear={setClear}
                    placeholder=""
                    />
            </div>

            <div className="box_header" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <div className="boxContainerActions">
                    <Button
                        variant="outlined"
                        color="primary"
                        size='small'
                        onClick={() => {
                            if (formValues.ticket_id !== 'null') {
                                setOpen(false);
                                setNewMessage({...defaultNewMessage});
                                setClear(true);
                            } else {
                                navigate('/tickets', { replace: true });
                            }
                        }}
                        >
                        Cancelar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size='small'
                        startIcon={<Send />}
                        onClick={() => {
                            if (!handleCheckMessage()) {
                                if (formValues.ticket_id === 'null' && handleCheckForm()) {
                                    return;
                                }
                                handleSave();
                                setOpen(false);
                            }
                        }}
                        >
                        Enviar
                    </Button>
                </div>
            </div>
        </Box>
    )
}