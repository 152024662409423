import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton} from '@mui/material';
import logo from '../../assets/img/logo.png';
import Cookies from 'universal-cookie';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SignUp from './SignUp';
import { useSearchParams } from 'react-router-dom';
import FormLogin from './FormLogin';
import FormCredentialsSent from './FormCredentialsSent';
import FormChangePass from './FormChangePass';
import FormForgotPass from './FormForgotPass';
const cookies = new Cookies();

export default withSnackbar(
  function SignIn(props: any) {
    
    const { setLoading } = props;
    
    useEffect (() => {
      setLoading(false);
    }, []);

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const [loadingButton, setLoadingButton] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [state, setState] = useState('login');
    const [userEmail, setUserEmail] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [worstationCode, setWorstationCode] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();



  
    
  //IF TOKEN MOBILE APP EXISTS IN URL 
  const tokenApp = localStorage.getItem('tokenApp')? localStorage.getItem('tokenApp') : '';

  const invitation = localStorage.getItem('invitation')? localStorage.getItem('invitation') : '';

  useEffect (() => {
    const emailVar = localStorage.getItem('email')? localStorage.getItem('email') : '';
    setLoading(false);
    setEmail(emailVar || searchParams.get('email') || '');
    setCode(searchParams.get('code') || '');
    setWorstationCode(searchParams.get('workstation_code') || localStorage.getItem('workstation_code') ||'')
  // const workstation_code = localStorage.getItem('workstation_code')? localStorage.getItem('workstation_code') : '';
  }, []);

  useEffect (() => {
    if(invitation === "1" && email !== '' && worstationCode !== ''){
      setOpenRegister(true);
    }
  }, [worstationCode]);

  useEffect (() => {
    if (email && code) {
      setState('changePass');
    }
  }, [code]);

  const loginDataDefault = {
    email: email,
    password: '',
    tokenApp: tokenApp,
    invitation: invitation,
    workstation_code: worstationCode,
  }

  const [loginData, setLoginData] = useState(loginDataDefault);

  const sign_in = () => {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "sign_in",
            params: loginData
          },
        })
        .then((response) => {
          console.log(response.data);
          console.log('Tour',response.data.tour);
          if(response.data.status === "success"){
            
            enqueueSnackbar(response.data.message, { variant: 'success' });
            const user_data = response.data.user_data;
            console.log(user_data);

            /*================================================
            SET COOKIES
            ================================================*/
            cookies.set('ltkn', user_data.ltkn, { path: '/', maxAge: (86400 * 365) });
            cookies.set('user_id', user_data.user_id, { path: '/', maxAge: (86400 * 365) });
            cookies.set('user_name', user_data.user_name, { path: '/', maxAge: (86400 * 365) });
            cookies.set('role_id', user_data.role_id, { path: '/', maxAge: (86400 * 365) });
            if (user_data.tour) cookies.set('user_steps', user_data.tour);
            /*================================================
            ================================================*/

            localStorage.setItem('user_id', user_data.user_id);
            localStorage.setItem('user_name', user_data.user_name);
            localStorage.setItem('user_image', user_data.image);
            localStorage.setItem('role_id', user_data.role_id);


            window.location.reload();
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoadingButton(false);
          }
          
         
        })
        .catch((err) => {
          console.log(err.data);
        });

  }  

  return (
    <>
    <div className='loginContainer'>
      {state === 'login' && (
        <FormLogin
          logo={logo}
          setLoading={setLoading}
          setState={setState}
          loginData={loginData}
          setEmail={setEmail}
          setLoginData={setLoginData}
          loadingButton={loadingButton}
          setLoadingButton={setLoadingButton}
          setOpenRegister={setOpenRegister}
          sign_in={sign_in}
        />
      )}
      {state === 'changePass' && (
        <FormChangePass
          loadingButton={loadingButton}
          logo={logo}
          setLoading={setLoading}
          setState={setState}
          email={email}
          code={code}
        />
      )}
      {state === 'forgotPass' && (
        <FormForgotPass
          logo={logo}
          loadingButton={loadingButton}
          setLoading={setLoading}
          setState={setState}
          email={email}
          setEmail={setEmail}
        />
      )}
      {state === 'credentialsSent' && (
        <FormCredentialsSent
          logo={logo}
          setState={setState}
          setEmail={setEmail}
          setLoginData={setLoginData}
          loginDataDefault={loginDataDefault}
        />
      )}
    </div>

    <SignUp
      openRegister={openRegister}
      setOpenRegister={setOpenRegister}
      loginData={loginData}
      setLoginData={setLoginData}
      sign_in={sign_in}
      setLoading = {setLoading}
    />

    </>
  );
}
);