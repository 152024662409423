import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import App from "elements/HtmlEditor/App";
import {
  ArrowBack,
  Save,
} from "@mui/icons-material";

import 'assets/css/main.css';



export default function CategorieslEditor(props: any) {

  const {
    setLoading,
    is_child = false,
  } = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let {category_id } = useParams();
  /*=======================================
  =======================================*/


  const navigate = useNavigate();
  const defaultValues: any = {
    category_id: category_id? category_id : "null",
    image: "",
    name: "",
    descriptionHTML: "",
    descriptionJSON: null
  };
  
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  /*=======================================
    TEXT EDITOR
  =======================================*/
  const [initialContent, setInitialContent] = useState<any>(null);

  const handleEditorChange = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        descriptionHTML: htmlContent,
        descriptionJSON: jsonContent
      });
  }
  /*=======================================
  =======================================*/




  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (category_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "category_details",
            id: category_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data)
          setFormValues(response.data);
          setInitialContent(response.data.descriptionJSON);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  


  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);



  const handleSubmit = () => {
    console.log(formValues);
    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
    const formData = new FormData();
    formData.append("files", files);

    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );

        },
        params: {
          request: "add_edit_category",
          formValues: formValues,
        },
      })
      .then((response) => {
        console.log(response);
        navigate("/categories");
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
  /*====================================
  =====================================*/







  return (
    <>
      <div id="CategoriesList" className="ModuleContainer" style={{maxWidth: '689px'}}>
        <div className="ModuleWrapper">
        { !is_child &&
          (
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Categoria</h2>
              <span className="h2_subtitle">Editar</span>
            </div>

            <div className="boxContainerActions">
              <Button
                onClick={() =>
                  navigate("/categories", {replace: true})}
                variant="outlined"
                className="button_2"
                startIcon={<ArrowBack />}
              >
                Volver
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="button_1"
                startIcon={<Save />}
              >
                Guardar
              </Button>
            </div>
          </div>
          )}
      
      <div id="userForm">
        <div className="userForm_right">
          <Box id="project_editor" title={!is_child ? "" : 'Usuario'} subtitle={!is_child ? "" : 'Editar'} className={!is_child ? "" : 'box_editor'}>
            <div className='input_group'>
                <div className='input_group'>
                    <div className='input_row'>

                    {/* UPLOAD button with preview */}
                      <div className="drawerImagesContainer">
                            
                        <div className="drawerImagesContainerDiv"
                          onDrop={(e) => {
                              e.preventDefault();
                              // UPDATE FILE
                              const files = e.dataTransfer.files;
                              setFiles(files[0]);
                              const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                              setFormValues({
                                ...formValues,
                                image: fileArray[0],
                              });
                              e.currentTarget.classList.remove("dropzone_active");
                            }
                            } 
                            onDragOver={
                              (e) => {
                                e.preventDefault();
                                e.currentTarget.classList.add("dropzone_active");
                              }
                              
                            }
                            onDragLeave={
                              (e) => {
                                e.preventDefault();
                                e.currentTarget.classList.remove("dropzone_active");
                              }
                            }
                        
                          >
                          {formValues.image && (
                            <img src={formValues.image}  />
                          )}
                          
                          <label id='dropFileDiv' className={formValues.image && ('showOnlyOnHover')}>
                            <IconButton 
                              aria-label="delete"
                              size="small"
                              className="addFile_button"
                              component="span"
                            >
                              <CameraAltIcon/>
                              <input 
                                type="file"
                                multiple 
                                hidden 
                                onChange={(e) => {
                                  const files = e.target.files;
                                  if (files) {
                                      
                                      // UPDATE FILE
                                      setFiles(files[0]);
                                      const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                      setFormValues({
                                        ...formValues,
                                        image: fileArray[0],
                                      });

                                    }

                                }}
                              />
                              
                            </IconButton>
                          </label>
                          
                        </div>

                      </div>



                    <div className="inputColumn">
                      <TextField 
                        id="name"
                        name="name"
                        label="Nombre"
                        type="text"
                        required
                        fullWidth
                        value={formValues.name}
                        onChange={handleInputChange}
                      />

                     
                    </div>

                    

                  </div>

                  


                </div>




                <App 
                  handleEditorChange={handleEditorChange} content={initialContent} 
                />
                          

                
              

            </div>
            { is_child && (
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
            </div>
            )}
            </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
