import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import {
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
 } from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import ic_panels from 'icons/ic_panels.svg';
import ic_paint from 'icons/ic_paint.svg';
import ic_hardware from 'icons/ic_hardware.svg';
import ic_wood from 'icons/ic_wood.svg';
import ic_stone from 'icons/ic_stone.svg';
import LanIcon from '@mui/icons-material/Lan';
import AppAvatar from 'elements/AppAvatar';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AlertDialog from 'elements/AlertDialog';

const categories_filter_base = {
    panel: false,
    paint: false,
    hardware: false,
    wood: false,
    stone: false
}

const ProductList = (props: any) => {

  const {setLoading} = props;

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [product_list, setProductList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const [panel_value, setPanelValue] = useState(0);
  const [paint_value, setPaintValue] = useState(0);
  const [hardware_value, setHardwareValue] = useState(0);
  const [wood_value, setWoodValue] = useState(0);
  const [stone_value, setStoneValue] = useState(0);
  const [total_value, setTotalValue] = useState(0);
  
  const [categories_filter, setCategories] = useState(categories_filter_base);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");




  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);
  

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
      //convert categories_filter to string
      let categories_filter_string = "";
      for (const [key, value] of Object.entries(categories_filter)) {
        if(value){
          categories_filter_string += "'"+ key + "',";
        }
      }
      categories_filter_string = categories_filter_string.slice(0, -1);

      console.log(categories_filter_string);
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'product_list',
          params: {
            page: page,
            category: categories_filter_string,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setProductList(response.data.products)
          setTotalPages(response.data.total_pages)
          setLoading(false)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList, categories_filter])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (product_id:number) => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_product',
        product_id: product_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1);
      setDeleteItemId(null);
      
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/

// call global snackbar
  


  
  return (
    <>
    <div id='UsersList' className='ModuleContainer'>
      <div className="ModuleWrapper">
      <div className="boxContainerHeader">
        
        <div className="boxContainerTitle">
          <h2 className="h2_title">Productos</h2>
          <span className="h2_subtitle">Listado de productos</span>
        </div>
        
        <div className="boxContainerActions">
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="add"
          startIcon={<AddIcon />}
        >
          Crear producto
        </Button>

        </div>
        
      </div>

      <div id='UsersListIn'>
    
        
          <Box id='CategoriesList' className='box100'>
                <TableContainer>
                <Table aria-label="sticky table"> 
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>PRODUCTO</TableCell>
                      <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>STOCK</TableCell>
                      <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>PRECIO</TableCell>
                      <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {product_list.map((product,i) => {
                        const product_type = product.product_type;
                        
                        const link = (product_type === 'product') ?
                        <div style={{display:'flex', flexDirection: 'row', alignItems:'center',justifyContent:'start',columnGap:'25px', fontSize:'0.8125rem1pt'}}>
                          <AppAvatar name={product.product_name} image={product.image} />
                          {product.product_name}
                        </div> 
                        : 
                        <div style={{display:'flex', flexDirection: 'row', alignItems:'center',justifyContent:'start',columnGap:'25px', fontSize:'0.8125rem'}}>
                          {/* TIP */}
                          <Tooltip title="This is a group of products.">
                            <LanIcon />
                          </Tooltip>
                          {product.product_name}
                        </div>

                          
                        return(
                        <TableRow 
                          
                          key={i}
                          hover
                          role="checkbox"
                        >
                          <TableCell>
                            {link}
                          </TableCell>
                          <TableCell>{product.product_stock}</TableCell>
                          <TableCell>${product.unit_price.toLocaleString()}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              color="primary"
                              component={Link}
                              to={product_type === 'product' ? "/inventory/edit/"+ product.product_id : "/inventory/group/" + product.product_id}
                              startIcon={<ModeEditIcon />}
                            >
                              Editar
                            </Button>
                            
                            <IconButton aria-label="delete"  color="primary" onClick={() => {
                                setDeleteItemId(product.product_id); setOpenDeleteDialog(true)}}>
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </TableCell>
                        </TableRow>
                      )})}
                  </TableBody>
                </Table>
              </TableContainer>
              
              {total_pages > 1 && (
                <Pagination count={total_pages} page={page}  onChange={(event, value) => {
                  setPage(value)
                }
                } 
                color="primary"
                style={{"marginTop": "20px","alignSelf": "center"}}
                />
              )}
              </Box>
    
          </div>
        </div>
    </div>
    <AlertDialog
      open={openDeleteDialog}
      setOpen={setOpenDeleteDialog}
      subtitle="¿Estás seguro que deseas eliminar este producto?"
      send={() => handleDelete(Number(deleteItemId))}
    />
    </>
  );
};



export default ProductList;