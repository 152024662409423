import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton, SwitchProps, Switch, styled, FormControlLabel} from '@mui/material';
import logo from '../../assets/img/logo.png';
import Cookies from 'universal-cookie';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ISwitch from 'elements/ISwitch';
const cookies = new Cookies();





export default withSnackbar(
  function SignUp(props: any) {


    const invitation = localStorage.getItem('invitation')? localStorage.getItem('invitation') : '';
    const email = invitation == "1" && localStorage.getItem('email')? localStorage.getItem('email') : '';
    const workstation_code = invitation == "1" && localStorage.getItem('workstation_code')? localStorage.getItem('workstation_code') : '';
    

    const defaultValues: any = {
      name: '',
      lastname: '',
      email: email,
      phone: '',
      password: '',
      password_confirmation: '',
      invitation: invitation,
      workstation_code: workstation_code,
    };

    const {openRegister, setOpenRegister, setLoading, sign_in, loginData, setLoginData} = props;

    
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


  const [formValues, setFormValues] = React.useState(defaultValues);
  const [showPassword, setShowPassword] = React.useState(false);


  useEffect(() => {
    setFormValues(defaultValues);
  }, [openRegister]);


  const handleInputChange = (e: { target: { name: any; value: any; required: any}; }) => {

    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
    
    
  };



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const checkEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  
    

  /*================================================
  SIGN UP (SUBMIT)
  ================================================*/
  async function handleRegister(){

    if(formValues.name === '' || formValues.lastname === '' || formValues.email === '' || formValues.password === '' || formValues.password_confirmation === ''){
      //snackbar error
      enqueueSnackbar('Todos los campos son obligatorios', { variant: 'error' });
      return;
    }
      
    if(formValues.password !== '' && formValues.password !== formValues.password_confirmation){
      //snackbar error
      enqueueSnackbar('Las contraseñas no coinciden', { variant: 'error' });
      return;
    }

    if(!checkEmail(formValues.email)){
      //snackbar error
      enqueueSnackbar('El correo electrónico no es válido', { variant: 'error' });
      return;
    }

    if(formValues.is_company && formValues.company_name === ''){
      //snackbar error
      enqueueSnackbar('El nombre de la empresa es obligatorio', { variant: 'error' });
      return;
    }

    if(formValues.is_company && formValues.company_document === ''){
      //snackbar error
      enqueueSnackbar('El número de documento de la empresa es obligatorio', { variant: 'error' });
      return;
    }
    
    
  

    setLoading(true);

    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'sign_up',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log(response.data);

          if(response.data.status === "success"){
            enqueueSnackbar(response.data.message, { variant: 'success' });
            loginData.email = formValues.email;
            loginData.password = formValues.password;
            setLoginData(loginData);

            sign_in(formValues.email, formValues.password);
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoading(false);
          }

           
        })
        .catch((err) => {
          console.log(err.data);
        })

    


    return
  }

  //IF IS USER IS CHECKED, IS COMPANY IS UNCHECKED AND VICEVERSA
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.name === 'is_user'){
      setFormValues({
        ...formValues,
        is_user: event.target.checked,
        is_company: !event.target.checked,
      });
    }
    else{
      setFormValues({
        ...formValues,
        is_user: !event.target.checked,
        is_company: event.target.checked,
      });
    }

  };


  




  return (
    <>
      <Dialog open={openRegister} onClose={()=>{setOpenRegister(false)}}>
        <DialogContent>
          <h2 className='dialog_h2'>Registro de usuario</h2>
          <p className='subtitle'>Completa los siguientes datos para registrarte</p>
          <form className='form' id='registerForm'>
            <div className='input_row'>
              <TextField
                required
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                type="text"
                value={formValues.name}
                autoComplete="name"
                onChange={handleInputChange}
              />

              <TextField
                required
                fullWidth
                id="lastname"
                label="Apellido"
                name="lastname"
                type="text"
                autoComplete="lastname"
                onChange={handleInputChange}
                value={formValues.lastname}
              />
            </div>

            <TextField
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              type="text"
              value={formValues.email}
              autoComplete="email"
              onChange={handleInputChange}
              error={formValues.email !== '' && !checkEmail(formValues.email)}
              helperText={formValues.email !== '' && !checkEmail(formValues.email) ? 'Invalid email' : ''}
            />

            <TextField
              required
              fullWidth
              name="phone"
              label="Teléfono"
              type="text"
              id="phone"
              value={formValues.phone}
              autoComplete="phone"
              onChange={handleInputChange}
            />

            <div className='input_row'>
              <TextField
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={formValues.password}
                autoComplete="current-password"
                onChange={handleInputChange}
                // eye icon to show password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                
              />

              

              <TextField
                required
                fullWidth
                name="password_confirmation"
                label="Confirma contraseña"
                type={showPassword ? 'text' : 'password'}
                id="password_confirmation"
                value={formValues.password_confirmation}
                onChange={handleInputChange}
                error={formValues.password_confirmation !="" && formValues.password !== formValues.password_confirmation}
                helperText={formValues.password_confirmation !="" && formValues.password !== formValues.password_confirmation ? 'Las contraseñas no coinciden' : ''}
                // eye icon to show password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            
            {formValues.is_company && (
              <>
            <div className='form_company_header'>
              <h2 className='dialog_h2'>Empresa</h2>
              <p className='subtitle'>Información de la compañia</p>
            </div>
            <TextField
              required
              fullWidth
              id="company_name"
              label="Nombre de la empresa"
              name="company_name"
              type="text"
              value={formValues.company_name}
              autoComplete="company_name"
              onChange={handleInputChange}
            />

            <TextField
              required
              fullWidth
              id="company_document"
              label="NIT de la empresa"
              name="company_document"
              type="text"
              value={formValues.company_document}
              autoComplete="company_document"
              onChange={handleInputChange}
            />
            </>
            )}


            <Button
              fullWidth
              variant="contained"
              onClick={handleRegister}
            >
              Registrarme
            </Button>

            <Button
              fullWidth
              variant="outlined"
              onClick={()=>{setOpenRegister(false)}}
            >
              Ya tengo cuenta
            </Button>
            
          </form>

        </DialogContent>
      </Dialog>
    </>
  );
}
);