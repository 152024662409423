
import axios from 'axios';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import {ProjectConfig} from 'Global';
import Box from 'elements/Box';
import TotalUsers from './TotalUsers';
import Cookies from 'universal-cookie';
import MetricsRow from './MetricsRow';
import { ProductsInstalled } from './ProductsInstalled';
import { WorkstationsPerCity } from './WorkstationsPerCity';
import WorkstationsMap from './WorkstationsMap';
import { TopProducts } from './TopProducts';


const cookies = new Cookies();



export default function Home (props: any) {

  const {setLoading} = props;

  const company_id = cookies.get('company_id') || cookies.get('default_company_id') ;

  useEffect(() => {
    setLoading(false);
  }, [])

  const [monthUsers, setMonthUsers] = useState<any>({
    labels: [],
    active_workstations: [],
    onboardings: [],
    departures: [],
    employee_turnover: 0
  });


  const [standfordProductivity, setStandfordProductivity] = useState<any>(0);
  const [trafficSavings, setTrafficSavings] = useState<any>(0);
  const [co2Savings, setCo2Savings] = useState<any>(0);

  const [productsPerCategory, setProductsPerCategory] = useState<any>({});
  const [workstationsPerCity, setWorkstationsPerCity] = useState<any>({});

  const [workstationsCoordinates, setWorkstationsCoordinates] = useState<any>([]);
  const [topProducts, setTopProducts] = useState<any>([]);
  const [video, setVideo] = useState<any>([]);

  useEffect(() => {
    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
          'COMPANYID': company_id
        },
        params: {
          request: 'get_company_dashboard',
          params:{
            start_month: '2023-05',
            end_month: '2023-10',
            city_id: 1,
          }
        }
      })
        .then((response) => {
          console.log(response.data); 
          setMonthUsers(response.data.monthUsers);
          setStandfordProductivity(response.data.standfordProductivity);
          setTrafficSavings(response.data.trafficSavings);
          setCo2Savings(response.data.co2Savings);
          setProductsPerCategory(response.data.productsPerCategory);
          setWorkstationsPerCity(response.data.workstationsPerCity);
          setWorkstationsCoordinates(response.data.workstationsCoordinates);
          setTopProducts(response.data.topProducts);
          setVideo(response.data.video);
        })
        .catch((err) => {
          console.log(err.data);
        })
        
    
  }, []) 
  
  return (
    <div id='DashboardContainer' className='ModuleContainer'>
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Dashboard</h2>
            <span className="h2_subtitle">Conoce las métricas de tu empresa</span>
          </div>


        </div>
      </div>
      

      <div className='graph_row'>
        <Box id="financial_graph">
          <TotalUsers 
            setLoading={setLoading}
            monthUsers={monthUsers}
          />
        </Box>

        <div className="graph_column">
          <div className="graph_row">
            <Box className='graph_top_boxes'>
              <div className="graph_top_number">{monthUsers.active_workstations.slice(-1)[0]}</div>
              <div className="graph_top_text">Puestos activos</div>
            </Box>
            <Box className='graph_top_boxes'>
              <div className="graph_top_number">{monthUsers.pending_workstations}</div>
              <div className="graph_top_text">Invitaciones pendientes</div>
            </Box>
          </div>
          <div className="graph_row">
            <Box className='graph_top_boxes'>
              <div className="graph_top_number">{monthUsers.employee_turnover}%</div>
              <div className="graph_top_text">Tasa de rotación</div>
            </Box>
            <Box className='graph_top_boxes'>
              <div className="graph_top_number">-</div>
            </Box>
          </div>
        </div>
      </div>

      <WorkstationsMap
        workstationsCoordinates={workstationsCoordinates}
      />

      <MetricsRow 
        standfordProductivity={standfordProductivity} 
        trafficSavings={trafficSavings} 
        co2Savings={co2Savings} />


      <div className='graph_row equal_elements'>
        <ProductsInstalled productsPerCategory={productsPerCategory} />
        <WorkstationsPerCity workstationsPerCity={workstationsPerCity} />
        <TopProducts topProducts={topProducts} />
      </div>

      <div>
        {/* VIDEO youtube */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/84q4bEmzR10" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style={{border: 'none', width: '100%', height: '500px'}}
        ></iframe>
        
        
      </div>

      


    </div>
  );
};

