import React, { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Add, DeleteOutline, QuestionAnswer } from '@mui/icons-material';

import Box from 'elements/Box';
import AlertDialog from 'elements/AlertDialog';

import 'assets/css/main.css';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';

const cookies = new Cookies();

export default function TicketsList( props: any ) {
    const {
        isAdmin = false,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { props.setLoading(loading); }, [loading]);

    const navigate = useNavigate();

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState<number | null>(null);
    const [list, setList] = useState<any[]>([]);

    const [page, setPage] = useState(searchParams.get('page') ? searchParams.get('page') : "1" );
    const [totalPages, setTotalPages] = useState(1);

    useEffect (() => {
        loadData();
    }, []);

    useEffect (() => {
        loadData();
    }, [page]);

    const loadData = () => {
        setLoading(true);

        const params = isAdmin ? { isAdmin: true } : {};
        
        axios.get(ProjectConfig.api_url, {
            headers: {
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'get_tickets_list',
                params: {
                    ...params,
                    page: page,
                }
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
        <div id="ticketsList" className='ModuleContainer' style={{maxWidth: (isAdmin ? '1100px': '900px')}}>
            <div className='ModuleWrapper'>
                <div className='boxContainerHeader'>
                    <div className='boxContainerTitle'>
                        <h2 className='h2_title'>Tickets</h2>
                        <span className='h2_subtitle'>Listado</span>
                    </div>

                    <div className='boxContainerActions'>
                        {!isAdmin &&
                        <Button
                            variant='contained'
                            color='primary'
                            startIcon={<Add />}
                            onClick={()=>{
                                navigate('add');
                            }}
                        >
                            Nuevo Ticket
                        </Button>
                        }
                    </div>
                </div>

                <div id='ticketsListIn'>
                    <Box
                        id='list'
                        className='box100'
                    >
                        <Table
                            aria-label="sticky table"
                            className='fixed-table'
                        >
                            <TableHead>
                                <TableRow>
                                    {isAdmin && <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>COMPAÑIA</TableCell>}
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>ID / ASUNTO</TableCell>
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>ESTADO</TableCell>
                                    {isAdmin && <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>PRIORIDAD</TableCell> }
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>ULTIMA ACTUALIZACION</TableCell>
                                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}} align="right">ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {list.map((row) => (
                                <TableRow
                                    key={row.ticket_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    hover
                                >
                                    {isAdmin &&
                                    <TableCell>
                                        {row.company_id}
                                    </TableCell>
                                    }
                                    
                                    <TableCell sx={{width:'280px'}}>
                                        <Typography
                                            variant='caption'
                                            sx={{fontWeight: 'bold', color: '#4a4a4a'}}
                                        >
                                            #{row.ticket_id}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                        >
                                            {row.subject}
                                        </Typography>
                                    </TableCell>

                                    <TableCell>
                                        {(row.status === 'closed') ? (
                                            <Chip
                                                label={"Cerrado"}
                                                variant='outlined'
                                                color='error'
                                            />
                                        ) : (
                                            <Chip
                                                label={"Pendiente"}
                                                variant='outlined'
                                                color='success'
                                            />
                                        )}
                                    </TableCell>
                                    
                                    {isAdmin &&
                                    <TableCell >
                                        {(row.priority === 'low') && (
                                            <Chip
                                                label={"Baja"}
                                                variant='outlined'
                                                color='success'
                                            />
                                        )}
                                        {(row.priority === 'medium') && (
                                            <Chip
                                                label={"Media"}
                                                variant='outlined'
                                                color='info'
                                            />
                                        )}
                                        {(row.priority === 'high') && (
                                            <Chip
                                                label={"Alta"}
                                                variant='outlined'
                                                color='warning'
                                            />
                                        )}
                                    </TableCell>}

                                    <TableCell>{row.last_update}</TableCell>

                                    <TableCell align="right">
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            sx={{mr: 1}}
                                            onClick={()=>{
                                                navigate('edit/'+row.ticket_id);
                                            }}
                                            startIcon={<QuestionAnswer />}
                                        >
                                            Ver
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        
                        {totalPages > 1 && (
                            <Pagination
                                count={totalPages}
                                page={Number(page)}
                                onChange={(event, page) => {
                                    setPage(page.toString());
                                    navigate({
                                        pathname: '/tickets',
                                        search: '?page='+page,
                                    });
                                }}
                                color="primary"
                                sx={{mt: 2, alignSelf: 'center'}}
                            />
                        )}
                    </Box>
                </div>
            </div>
        </div>
        </>
    );
}