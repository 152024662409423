import {Wrapper} from '@googlemaps/react-wrapper';
import { useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import mapOptions from '../Workstations/Utils/map-options.json';


export default function WorkstationsMap(props: any) {

  const {} = props;

  const [workstationsCoordinates, setWorkstationsCoordinates] = useState<Array<any>>(props.workstationsCoordinates); // [{latitude: 0, longitude: 0}


  const [LoadingIn, setLoadingIn] = useState(true);

  return (
    <div className='MapContainer'>
      <div className={LoadingIn ? 'LoaderIn' : 'LoaderIn hidden'}>
        <CircularProgress />
      </div>
      
      <Wrapper apiKey={"AIzaSyCISPFtXVXEddeRH4XmosTAZD-eJCeGQfA"}>
        <GMap workstationsCoordinates={workstationsCoordinates} setLoadingIn={setLoadingIn}/>
      </Wrapper>
    </div>
  );
};




const GMap = ({workstationsCoordinates, setLoadingIn}: {workstationsCoordinates: Array<any>, setLoadingIn: any}) => {
  const ref = useRef(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  

  useEffect(() => {
    if (ref.current && !map) {
      
      setMap(
        new google.maps.Map(ref.current, {
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          center: {
            lat: 4.1156735,
            lng: -72.9301367,
          },
          zoom: 5,
          styles: mapOptions.styles
          
        })
      );
      setLoadingIn(false)
    }
  }, [ref, map, workstationsCoordinates]);


  //UPDATE MAP POSITION ON CENTER OF COORDINATES
  useEffect(() => {
    if (map && workstationsCoordinates.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      workstationsCoordinates.map((workstation) => {
        bounds.extend(workstation.coordinates);
      });
      map.fitBounds(bounds);
    }
  }, [workstationsCoordinates, map]);









  const [marker, setMarker] = useState<google.maps.Marker | null>(null);


    //MARKERS OF ALL COORDINATES
      
    useEffect(() => {
      if (map && !marker && workstationsCoordinates.length > 0) {
        workstationsCoordinates.map((workstation) => {
          map.setZoom(workstation.zoom);

          const marker = new google.maps.Marker({
            position: workstation.coordinates,
            map: map,
            title: workstation.name,
          });
          
          marker.addListener("click", () => {

            //close all info windows
            const infoWindows = document.querySelectorAll('.gm-style-iw-a');
            infoWindows.forEach((infoWindow) => {
              infoWindow.remove();
            });


            const infoWindow = new google.maps.InfoWindow({
              content: `
              <div class="mapInfo">
                <div class="mapInfo__title">${workstation.name}</div>
                <div class="mapInfo__address">${workstation.address}</div>
              </div>
              `,
            });
            infoWindow.open(map, marker);
          });
        });
      }
  }, [map, workstationsCoordinates]);



  

  return <div ref={ref} style={{height: '500px', width: '100%'}} />;
};
