import * as React from 'react';
import logo from '../../../assets/img/logo.png';
import Cookies from 'universal-cookie';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';

import FormLogin from './FormLogin';
import FormForgotPass from './FormForgotPass';
import { useSearchParams } from 'react-router-dom';
import FormCredentialsSent from './FormCredentialsSent';
import FormChangePass from './FormChangePass';

const cookies = new Cookies();

export default withSnackbar(
  function LoginAdmin(props: any) {

  const {setLoading} = props;

  const [state, setState] = useState('login');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect (() => {
    setLoading(false);
    setEmail(searchParams.get('email') || '');
    setCode(searchParams.get('code') || '');
  }, []);

  useEffect (() => {
    if (email && code) {
      setState('changePass');
    }
  }, [code]);

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <div className='loginContainer'>
      {state === 'login' && (
        <FormLogin
          logo={logo}
          setLoading={setLoading}
          setState={setState}
          email={email}
          setEmail={setEmail}
          loadingButton={loadingButton}
        />
      )}
      {state === 'forgotPass' && (
        <FormForgotPass
          logo={logo}
          loadingButton={loadingButton}
          setLoading={setLoading}
          setState={setState}
          email={email}
          setEmail={setEmail}
        />
      )}
      {state === 'credentialsSent' && (
        <FormCredentialsSent
          logo={logo}
          setState={setState}
          setEmail={setEmail}
        />
      )}
      {state === 'changePass' && (
        <FormChangePass
          loadingButton={loadingButton}
          logo={logo}
          setLoading={setLoading}
          setState={setState}
          email={email}
          code={code}
        />
      )}
    </div>
  );
}
);